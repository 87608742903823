<template>
  <div>
    <Layout>
      <template v-slot:default>
        <h1 class="text-6xl mt-32 text-center mb-4 font-semibold">Gallery</h1>
        <hr class="w-20 h-1 mx-auto my-4 -mt-3 mb-12 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />


        <div class="mx-4 sm:mx-8 md:mx-12 lg:mx-20 xl:mx-36 mb-16">
          <div class="flex flex-wrap justify-center space-x-4 mb-8">
            <button
              class="text-blue-700 hover:text-white border border-blue-600 bg-white hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:bg-gray-900 dark:focus:ring-blue-800"
              :class="{ 'bg-gray-600': selectedType === 'all' }" @click="filterImages('all')">
              All
            </button>
            <button
              class="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:text-white dark:focus:ring-gray-800"
              :class="{ 'bg-blue-600': selectedType === 'achievements' }" @click="filterImages('achievements')">
              Achievements
            </button>
            <button
              class="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:text-white dark:focus:ring-gray-800"
              :class="{ 'bg-green-600': selectedType === 'speciality' }" @click="filterImages('speciality')">
              Our Speciality
            </button>
            <button
              class="text-gray-900 border border-white hover:border-gray-200 dark:border-gray-900 dark:bg-gray-900 dark:hover:border-gray-700 bg-white focus:ring-4 focus:outline-none focus:ring-gray-300 rounded-full text-base font-medium px-5 py-2.5 text-center mr-3 mb-3 dark:text-white dark:focus:ring-gray-800"
              :class="{ 'bg-green-600': selectedType === 'trainings' }" @click="filterImages('trainings')">
              Trainings
            </button>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <div v-for="(image, index) in filteredImages" :key="index" :class="{ 'border-none': selectedType !== 'all' }">
              <img :src="image.src" :alt="image.alt"
                class="h-full max-w-full rounded-lg w-full object-cover transition-transform duration-300 transform hover:scale-105" />
            </div>
          </div>
        </div>


      </template>
    </Layout>
  </div>
</template>


<script>
import Layout from '../Layout/Layout.vue';
export default {
  name: 'GalleryPage',
  components: {
    Layout
  },
  data() {
    return {
      images: [
        // achievements
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679916429/7_u5gsd4.jpg", alt: "Dr. Manisha Sonawane's Achievements", type: "achievements" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679916429/5_q66acp.jpg", alt: "Dr. Manisha Sonawane's Achievements", type: "achievements" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679916429/3_qobehq.jpg", alt: "Dr. Manisha Sonawane's Achievements", type: "achievements" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679916428/4_nj3rbn.jpg", alt: "Dr. Manisha Sonawane's Achievements", type: "achievements" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679916428/6_vvrtae.jpg", alt: "Dr. Manisha Sonawane's Achievements", type: "achievements" },

        // speciality
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679916429/9_uyg1l0.jpg", alt: "Dr. Manisha Sonawane's Speciality", type: "speciality" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679916429/2_ay53sx.jpg", alt: "Dr. Manisha Sonawane's Speciality", type: "speciality" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679916428/1_ffoi5d.jpg", alt: "Dr. Manisha Sonawane's Speciality", type: "speciality" },

        //trainings
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925407/training9_hesvbf.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925407/training8_imdvxo.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925407/training5_ojy6ye.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925407/training6_qbemkg.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925407/training7_be6uwd.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925406/training4_b8sdiq.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925405/training1_c1lwsn.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925406/training2_mejpvn.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925408/training10_bxpqpn.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925409/teachingstudent_cywqcz.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },
        { src: "https://res.cloudinary.com/doqozlqzp/image/upload/v1679925411/teachingstudent2_qf0xvs.jpg", alt: "Dr. Manisha Sonawane's Trainings", type: "trainings" },

      ],
      selectedType: "all"
    };
  },
  computed: {
    filteredImages() {
      if (this.selectedType === "all") {
        return this.images;
      } else {
        return this.images.filter(image => image.type === this.selectedType);
      }
    }
  },
  methods: {
    filterImages(type) {
      this.selectedType = type;
    }
  },
  metaInfo() {
    return{
        title: "Gallery | Dr. Manisha's Yoga Institute",
        meta:[
          { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                { vmid: 'title', name: 'title', content: 'Dr. Manisha\'s Yoga Institute' },
                { vmid: 'description', name: 'description', content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...' },
                {
                    vmid: 'keywords',
                    name: 'keywords',
                    content: 'Dr. Manisha\'s Yoga Institute, Yoga education, Yogic Science,Yoga for sleep, Yoga for anxiety,Yoga for immunity,Yoga for heart health,Yoga for respiratory health,Yoga for menstrual health,Yoga for cancer patients, Cultural Synthesis,Yoga retreats for beginners, Yoga teacher training programs, Yoga for stress relief, Yoga for weight loss, Yoga for back pain, Yoga for flexibility, Yoga for mental health, Yoga for seniors, Yoga for athletes, Yoga for pregnancy, yoga postures, pranayama, meditation, healthy mind, healthy body, enlightenment, yoga retreats, yoga workshops, yoga philosophy, mindfulness, fitness, stress relief, holistic healing, yoga courses,oga, yoga institute, yoga teacher training, yoga teacher training in pune, yoga teacher training in india, yoga teacher training in maharashtra, yoga teacher training in thane'
                },
                {
                    vmid: 'og-title',
                    property: 'og:title',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'og-description',
                    property: 'og:description',
                    content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
                },
                {
                    vmid: 'og-image',
                    property: 'og:image',
                    content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683462908/main_kdohfk.png'
                },
                {
                    vmid: 'og-image-type',
                    property: 'og:image:type',
                    content: 'image/png'
                },
                {
                    vmid: 'og-image-width',
                    property: 'og:image:width',
                    content: '1200'
                },
                {
                    vmid: 'og-image-height',
                    property: 'og:image:height',
                    content: '630'
                },
                {
                    vmid: 'og-image-alt',
                    property: 'og:image:alt',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'og-url',
                    property: 'og:url',
                    content: 'https://drmanishasyogainstitute.com'
                },
                {
                    vmid: 'og-type',
                    property: 'og:type',
                    content: 'health care website'
                },
                {
                    vmid: 'og-site-name',
                    property: 'og:site_name',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-card',
                    name: 'twitter:card',
                    content: 'summary_large_image'
                },
                {
                    vmid: 'twitter-title',
                    name: 'twitter:title',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-description',
                    name: 'twitter:description',
                    content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
                },
                {
                    vmid: 'twitter-image',
                    name: 'twitter:image',
                    content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683462908/main_kdohfk.png'
                },
                {
                    vmid: 'twitter-image-alt',
                    name: 'twitter:image:alt',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-site',
                    name: 'twitter:site',
                    content: '@DrManishasYoga'
                },
                {
                    vmid: 'twitter-creator',
                    name: 'twitter:creator',
                    content: '@DrManishasYoga'
                },
                {
                    vmid: 'twitter-url',
                    name: 'twitter:url',
                    content: 'https://drmanishasyogainstitute.com'
                },
                {
                    vmid: 'twitter-domain',
                    name: 'twitter:domain',
                    content: 'drmanishasyogainstitute.com'
                },
        ]

    }
  }
}
</script>

<style scoped>
.image-wrapper {
  height: 350px;
  width: 450px;
}
</style>