<template>
    <div>
        <div class="flex mt-32 flex-wrap">
            <h3 class="text-3xl font-bold mb-4">Recent Blogs</h3>
            <div class="relative w-full overflow-x-auto shadow-md sm:rounded-lg">

            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <!-- <th class="py-2 px-4 bg-gray-200 border border-gray-300">Id</th> -->
                  <th  scope="col" class="px-6 py-3">Created Date</th>
                  <th  scope="col" class="px-6 py-3">Title</th>
                  <th  scope="col" class="px-6 py-3">Description</th>
                  <th  scope="col" class="px-6 py-3">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="post in postDetails" :key="post.id" class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <!-- <td class="py-2 px-4 border border-gray-300">{{post.id}}</td> -->
                  <td class="px-6 py-4">{{post.createdDate}}</td>
                  <td class="px-6 py-4">{{post.title}}</td>
                  <td class="px-6 py-4">{{post.seoDescription.slice(0,50) + '...'}}</td>
                  <td class="px-6 py-4">
                    <router-link :to="`/blogs/${post.id}`" class="bg-green-500 text-white py-1 px-2 rounded hover:bg-green-600 transition-all duration-300" title="View">
                        <i class="fas fa-eye"></i>
                    </router-link>
                    <router-link :to="`/admin/edit-post/${post.id}`" class="bg-yellow-500 text-white py-1 px-2 rounded hover:bg-yellow-600 transition-all duration-300 ml-2" title="Edit">
                        <i class="fas fa-edit"></i>
                    </router-link>
                    <button class="bg-red-500 text-white py-1 px-2 rounded hover:bg-red-600 transition-all duration-300 ml-2" @click="deletePost(post.id)" title="Delete">
                        <i class="fas fa-trash"></i>
                    </button>
                </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
          
    </div>
  </template>
  <script>
import axios from "axios";
import DOMPurify from "dompurify";
import swal from 'sweetalert2';

export default {
  name: "RecentBlog",
  data() {
    return {
      postDetails: [],
    };
  },
  mounted() {
    axios
      .get(`https://yogaproducts-16590-default-rtdb.firebaseio.com/post.json`)
      .then((response) => {
        this.getData(response.data);
      });
  },
  methods: {
    getData(postsData) {
      for (let key in postsData) {
        const post = postsData[key];
        this.postDetails.push({
          id: key,
          title: post.title,
          description: post.description,
          seoKeywords: post.seoKeywords,
          seoDescription: post.seoDescription,
          createdDate: post.createdDate,
        });
      }
    },
    purifyText(text) {
      const purified = DOMPurify.sanitize(text, { ALLOWED_TAGS: [
        "b",
        "i",
        "em",
        "strong",
        "a",
        "p",
        "br",
        "ul",
        "ol",
        "li",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "img",
        "iframe",
        "table",
        "tbody",
        "thead",
        "tr",
        "th",
        "td",
        "span",
        "div",
        "blockquote",
        "pre",
        "code",
        "hr",
        "sup",
        "sub",
        "u",
        "s",
        "del",
        "ins",
        "mark",
        "small",
        "big",
        "caption",
        "figure",
        "figcaption",
        "cite",
        "q",
        "dfn",
        "abbr",
        "time",
        "ruby",
        "rt",
        "rp",
        "bdi",
        "bdo",
        "wbr",
        "samp",
        "kbd",
        "var",
        "s",
        "strike",
        "summary",
        "details",
        "summary",
        "details",
        "address",
        "main",
        "section",
        "article",
        "aside",
        "nav",
        "header",
        "footer",
        "audio",
        "video",
        "source",
        "track",
        "canvas",
        "map",
        "area",
        "svg",
        "use",
        "g",
        "path",
        "circle",
        "rect",
        "line",
        "polyline",
        "polygon",
        "text",
        "tspan",
        "tref",
        "textPath",
        "clipPath",
        "mask",
        "pattern",
        "image",
        "foreignObject",
        "desc",
        "title",
      ] });
      return purified.replace(/(<([^>]+)>)/gi, "");
    },
    deletePost(id) {
      swal.fire({
        title: "Are you sure you want to delete this Post?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(
              `https://yogaproducts-16590-default-rtdb.firebaseio.com/post/${id}.json`
            )
            .then((response) => {
              swal.fire(
                "Deleted!",
                "Your Post has been deleted.",
                "success"
              );
              console.log(response);
              this.$router.go();
            });
        }

      })
    },
  },
};
</script>