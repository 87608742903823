<template>
    <div class="flex mt-32 flex-wrap">

        <h1
            class="text-6xl font-medium text-gray-700 dark:text-gray-200 mb-4 ml-4"
        >
            All Users
        </h1>
        <div class="relative w-full overflow-x-auto shadow-md sm:rounded-lg">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <!-- <th scope="col" class="px-6 py-3">
                            User ID
                        </th> -->
                        <th scope="col" class="px-6 py-3">
                            User Name
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Phone number
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Email ID
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Residential Address
                        </th>
                        <th scope="col" class="px-6 py-3">
                            Actions
                            <span class="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id"
                        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <!-- <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            {{ user._id }}
                        </th> -->
                        <td class="px-6 py-4">
                            {{ user.username }}
                        </td>
                        <td class="px-6 py-4">
                            {{ user.phonenumber }}
                        </td>
                        <td class="px-6 py-4">
                            {{ user.email }}
                        </td>
                        <td class="px-6 py-4">
                            {{ user.address }}
                        </td>
                        <td class="px-6 py-4 text-right">
                            <button class="
                                    inline-flex
                                    justify-center
                                    items-center
                                    w-6
                                    h-6
                                    mr-3
                                    text-red-500
                                    transition-colors
                                    duration-150
                                    bg-white
                                    rounded-full
                                    focus:outline-none
                                    hover:bg-red-100   
                                " @click="deleteUser(user._id)"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24">
                                    <path fill="currentColor"
                                        d="M7 21q-.825 0-1.413-.588T5 19V6H4V4h5V3h6v1h5v2h-1v13q0 .825-.588 1.413T17 21H7Zm2-4h2V8H9v9Zm4 0h2V8h-2v9Z" />
                                </svg></button>
                            <!-- <a href="#" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">Edit</a> -->
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>


    </div>
</template>
<script>
import Swal from 'sweetalert2';

import { API_URL } from '@/config'
export default {
    name: "DashboardPage",
    data() {
        return {
            users: []
        }
    },
    async created() {
        try {
            const response = await fetch(`${API_URL}/users`);
            const data = await response.json();
            // console.log(data);
            this.users = data;
        }
        catch (err) {
            console.log(err);
        }
    },
    methods: {
        async deleteUser(id) {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: `Are you sure you want to delete ${this.users.find(user => user._id === id).username}'s account' ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            })

            if (result.isConfirmed) {
                try {
                    await fetch(`${API_URL}/users/${id}`, {
                        method: 'DELETE'
                    });
                    this.users = this.users.filter(user => user._id !== id);
                    Swal.fire({
                        title: 'Deleted!',
                        text: 'User has been deleted.',
                        icon: 'success',
                        confirmButtonText: 'Ok'
                    });
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }

};
</script>
  