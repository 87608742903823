<template>
    <div class="w-full max-w-2xl bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">

        <img class="rounded-t-lg w-full" :src="product.thumbnail" alt="product image" />

        <div class="px-5  sm:p-5">

            <div class="flex items-center mt-2.5 mb-4 sm:mb-5">
                <h5 class="text-lg sm:text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                    {{ product.title}}
                </h5>
                <span
                    class="ml-5 bg-blue-100 text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800">
                    {{ product.rating }}/5</span><svg aria-hidden="true" class="w-5 h-5  text-yellow-300" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Fifth star</title><path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"></path></svg>

                    </div>
                    <p class="px-2 py-2 flex justify-center">{{ product.description }}</p>

            <div class="items-center justify-between">
                <p class="flex p-2">₹<span class="text-xl sm:text-3xl  text-gray-900 dark:text-white"> {{ product.price
                }}</span></p>
                
                <div class="mt-8 mr-6">
                    <div v-if="productTotal" class="flex justify-center items-center gap-3 mt-10">
                        <button class=" bg-main-orange hover:bg-main-orange text-white font-bold py-2 px-4 rounded"
                            @click="removeFromCart()">
                            <i class="pi pi-minus"></i>
                        </button>
                        <span class="text-lg">{{ productTotal }}</span>
                        <button class=" bg-main-orange hover:bg-main-orange text-white font-bold py-2 px-4 rounded"
                            @click="addToCart()">
                            <i class="pi pi-plus"></i>
                        </button>
                    </div>
                    <button v-else class="bg-main-orange hover:bg-main-orange text-white font-bold py-2 px-4 rounded"
                        @click="addToCart()">
                        <i class="pi pi-shopping-cart"></i>
                        Add To Cart
                    </button>
                </div>
            </div>
        </div>
    </div>

    
</template>
  
<script>
export default {
    props: ["product"],
    methods: {
        addToCart() {
            this.$store.dispatch('addProductToCart', {
                product: this.product
            })
        },
        removeFromCart() {
            this.$store.dispatch('removeProductFromCart', {
                product: this.product
            })
        }
    },
    computed: {
        productTotal() {
            const item = this.$store.state.cart.find(i => i.product.id === this.product.id)

            if (item) return item.quantity
            else return null
        }
    }
}
</script>