<template>
  <aside class="fixed top-0 left-0 w-64 h-full" aria-label="Sidenav">
    <div class="overflow-y-auto py-5 px-3 h-full bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <a href="#" class="flex mr-4">
        <img src="@/assets/images/logo.png" class="h-24 mr-3 sm:h-24" alt="Dr. Manisha's
          Yoga Institute" />
      </a>
      <span class="self-center text-2xl font-semibold whitespace-wrap dark:text-white">Dr. Manisha's Yoga Institute</span>
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">

      </ul>

      <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
        <li class="items-center">
          <router-link to="/user/dashboard">
            <a
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <svg aria-hidden="true"
                class="w-6 h-6 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
              <span class="ml-3">Dashboard</span>
            </a>
          </router-link>
        </li>
      </ul>

    </div>

  </aside>
</template>


<script>
export default {
  name: 'UserSidebar'
};
</script>
