<template>
    <div class="">
      <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div class="max-w-6xl mx-auto">
          <div class="relative h-96 rounded-lg overflow-hidden">
            <!-- <img class="absolute h-full w-full object-cover" src="@/assets/images/danceyoga.jpg" alt="Service 1"> -->
            <video class="absolute h-96 w-full object-cover opacity-75" autoplay muted loop preload="none">
              <source src="@/assets/videos/pranayam.mp4" type="video/mp4">
            </video>
            <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30"></div>
            <div class="relative h-full flex items-center justify-center p-8">
              <div class="text-center">
                <h1 class="text-4xl font-bold text-white uppercase mb-4">{{ services.title }}</h1>
                <p class="text-white text-lg mb-8">Meditation and Pranayam offer a transformative journey towards inner peace, spiritual growth, and physical and mental well-being.</p>
              </div>
            </div>
          </div>
          <div class="relative mt-8  text-justify">
            <article class="format-sm sm:format-base text-justify lg:format-lg format-blue">
              <h2 class="text-5xl font-medium">What is Meditation &amp; Pranayam?</h2>
              <p class="mt-4 lead leading-10">{{ services.paragraph1 }}</p>
              <p class="mt-6  text-lg leading-8">{{ services.paragraph2 }}</p>
              <div class="mt-8 mb-8 rounded-lg overflow-hidden relative">
                <img :src="services.bannerImage" alt="Dr. Manisha's Yoga Institute" class="w-full h-64 rounded-lg object-cover">
                <h1 class="absolute bottom-0 left-0 text-white font-medium text-4xl sm:text-5xl md:text-6xl   px-4 py-4">Meditation &amp; Pranayam</h1>
              </div>
              <p class="mt-6  text-lg leading-8">{{ services.paragraph3 }}</p>
              <p class="mt-6  text-lg leading-8">{{ services.paragraph4 }}</p>
              <p class="mt-6  text-lg leading-8">{{ services.paragraph5 }}</p>
  
  
  
            </article>
            <div class="mt-8 ">
              <h2 class="text-2xl font-bold">Related Services</h2>
              <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                <div v-for="service in relatedServices" :key="service.title" class="bg-white rounded-lg shadow-md">
                  <div class="overflow-hidden rounded-t-lg">
                    <img :src="service.image" alt="Dr. Manisha's Yoga Institute" class="w-full h-48 object-cover">
                  </div>
                  <div class="p-6">
                    <h3 class="text-lg font-bold mb-4">{{ service.title }}</h3>
                    <p class="text-gray-700">{{ service.description }}</p>
                    <router-link :to="service.to" class="text-main-orange font-bold text-base mt-8">Learn more →</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>

import axios from 'axios';
// import { API_URL } from '@/config'
  export default {
    name: 'MeditationPranayam',
    metaInfo(){
        return {
            title:"Meditation & Pranayam | Dr. Manisha's Yoga Institute",
            meta: [
              { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                { vmid: 'title', name: 'title', content: "Meditation & Pranayam | Dr. Manisha's Yoga Institute" },
                
        {
          vmid: 'description',
          name: 'description',
          content: "Meditation and Pranayam are ancient practices that offer numerous physical, mental and spiritual benefits. Meditation is a process of achieving inner peace and tranquility by focusing your mind on a particular object or thought. It helps to reduce stress, anxiety, and depression and improve concentration, focus and emotional balance."
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: "Dr. Manisha's Yoga Institute, Mindfulness, Concentration, Relaxation, Breathing, Awareness, Yoga, Stress reduction, Breath control, Inner peace, Self-discovery, Mental health, Breathing exercises, Breath awareness, Breathwork, Spiritual practice, Breath techniques, Calmness, Chakras, Breath regulation, Mental clarity, Breathing meditation, Prana, Prana flow, Pranayama techniques, Pranayama benefits"
        },
        {
            vmid: 'og-image',
            property: 'og:image',
            content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683477588/Screenshot_2023-05-07_220529_s1olvp.png'
        },
        {
            vmid: 'og-image-type',
            property: 'og:image:type',
            content: 'image/png'
        },
        {
            vmid: 'og-image-width',
            property: 'og:image:width',
            content: '1200'
        },
        {
            vmid: 'og-image-height',
            property: 'og:image:height',
            content: '630'
        },
        {
            vmid: 'og-image-alt',
            property: 'og:image:alt',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'og-url',
            property: 'og:url',
            content: 'https://drmanishasyogainstitute.com'
        },
        {
            vmid: 'og-type',
            property: 'og:type',
            content: 'health care website'
        },
        {
            vmid: 'og-site-name',
            property: 'og:site_name',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-card',
            name: 'twitter:card',
            content: 'summary_large_image'
        },
        {
            vmid: 'twitter-title',
            name: 'twitter:title',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-description',
            name: 'twitter:description',
            content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
        },
        {
            vmid: 'twitter-image',
            name: 'twitter:image',
            content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683477588/Screenshot_2023-05-07_220529_s1olvp.png'
        },
        {
            vmid: 'twitter-image-alt',
            name: 'twitter:image:alt',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-site',
            name: 'twitter:site',
            content: '@DrManishasYoga'
        },
        {
            vmid: 'twitter-creator',
            name: 'twitter:creator',
            content: '@DrManishasYoga'
        },
        {
            vmid: 'twitter-url',
            name: 'twitter:url',
            content: 'https://drmanishasyogainstitute.com'
        },
        {
            vmid: 'twitter-domain',
            name: 'twitter:domain',
            content: 'drmanishasyogainstitute.com'
        },
            ]
        }
    },
    data() {
    return {
      services: {},
      relatedServices: [
        {
          title: "Healing Therapy",
          image: "https://images.pexels.com/photos/7113299/pexels-photo-7113299.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Healing therapy promotes holistic wellness by addressing the root cause of physical and emotional issues, fostering balance and growth.",
          to: "/services/healing-therapy"
        },
        {
          title: "Yoga for allround Fitness",
          image: "https://images.pexels.com/photos/4056723/pexels-photo-4056723.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Yoga offers a complete approach to fitness, enhancing strength, flexibility, balance, and mindfulness for a healthier, more vibrant life.",
          to: "/services/yoga-for-allround-fitness"
        },
        {
          title: "Yoga for Kids",
          image: "https://images.pexels.com/photos/5063299/pexels-photo-5063299.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Yoga for kids cultivates a playful, non-competitive environment that promotes physical health, mental focus, and emotional regulation, setting the foundation for lifelong well-being.",
          to: "/services/yoga-for-kids"
        }
      ]
    }
  },
  async mounted(){
    const _id = '2';
    const response = await axios.get(`https://yogaservices-cc925-default-rtdb.asia-southeast1.firebasedatabase.app/services/${_id}.json`);
    this.services = response.data;
    // console.log(this.services);
  }
  }
  </script>
  