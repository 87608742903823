<template>
  <div class="">
    <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div class="max-w-6xl mx-auto">
        <div class="relative h-96 rounded-lg overflow-hidden">
          <!-- <img class="absolute h-full w-full object-cover" src="@/assets/images/danceyoga.jpg" alt="Service 1"> -->
          <video class="absolute h-96 w-full object-cover opacity-75" autoplay muted loop preload="none">
            <source src="@/assets/videos/kidsyoga.mp4" type="video/mp4">
          </video>
          <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30"></div>
          <div class="relative h-full flex items-center justify-center p-8">
            <div class="text-center">
              <h1 class="text-4xl font-bold text-white uppercase mb-4">{{ services.title }}</h1>
              <p class="text-white text-lg mb-8">Introducing yoga to children can plant the seeds of mindfulness, health,
                and self-awareness that can blossom into a lifetime of wellness.</p>
            </div>
          </div>
        </div>
        <div class="relative mt-8  text-justify">
          <article class="format-sm sm:format-base text-justify lg:format-lg format-blue">
            <h2 class="text-5xl font-medium">What is Yoga for Kids?</h2>
            <p class="mt-4 lead leading-10">{{ services.paragraph1 }}</p>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph2 }}</p>
            <div class="mt-8 mb-8 rounded-lg overflow-hidden relative">
              <img :src="services.bannerImage" alt="Dr. Manisha's Yoga Institute" class="w-full h-72 rounded-lg object-cover">
              <h1 class="absolute bottom-0 left-0 text-white font-medium text-4xl sm:text-5xl md:text-6xl  px-4 py-4">Yoga
                for Kids</h1>
            </div>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph3 }}</p>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph4 }}</p>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph5 }}</p>



          </article>
          <div class="mt-8 ">
            <h2 class="text-2xl font-bold">Related Services</h2>
            <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              <div v-for="service in relatedServices" :key="service.title" class="bg-white rounded-lg shadow-md">
                <div class="overflow-hidden rounded-t-lg">
                  <img :src="service.image" alt="Dr. Manisha's Yoga Institute" class="w-full h-48 object-cover">
                </div>
                <div class="p-6">
                  <h3 class="text-lg font-bold mb-4">{{ service.title }}</h3>
                  <p class="text-gray-700">{{ service.description }}</p>
                  <router-link :to="service.to" class="text-main-orange font-bold text-base mt-8">Learn more
                    →</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
// import { API_URL } from "@/config";
export default {
  name: 'YogaForKids',
  metaInfo() {
    return {
      title: "Yoga For Kids | Dr. Manisha's Yoga Institute",
      meta: [
        { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                { vmid: 'title', name: 'title', content: "Yoga For Kids | Dr. Manisha's Yoga Institute" },
                
        {
          vmid: 'description',
          name: 'description',
          content: "Yoga for Kids is a great way to introduce your child to a healthy lifestyle that will stay with them for the rest of their lives. Our specially designed yoga classes are catered to children of all ages and abilities."
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: "Dr. Manisha's Yoga Institute, Yoga, Kids, Children, Mindfulness, Breathing, Meditation, Relaxation, Flexibility, Strength, Balance, Concentration, Movement, Asanas, Postures, Health, Well-being, Exercise, Physical activity, Stress management, Self-awareness, Self-control, Body awareness, Emotional regulation, Play, Fun"
        },
        {
            vmid: 'og-image',
            property: 'og:image',
            content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683477588/Screenshot_2023-05-07_220335_labg1k.png'
        },
        {
            vmid: 'og-image-type',
            property: 'og:image:type',
            content: 'image/png'
        },
        {
            vmid: 'og-image-width',
            property: 'og:image:width',
            content: '1200'
        },
        {
            vmid: 'og-image-height',
            property: 'og:image:height',
            content: '630'
        },
        {
            vmid: 'og-image-alt',
            property: 'og:image:alt',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'og-url',
            property: 'og:url',
            content: 'https://drmanishasyogainstitute.com'
        },
        {
            vmid: 'og-type',
            property: 'og:type',
            content: 'health care website'
        },
        {
            vmid: 'og-site-name',
            property: 'og:site_name',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-card',
            name: 'twitter:card',
            content: 'summary_large_image'
        },
        {
            vmid: 'twitter-title',
            name: 'twitter:title',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-description',
            name: 'twitter:description',
            content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
        },
        {
            vmid: 'twitter-image',
            name: 'twitter:image',
            content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683477588/Screenshot_2023-05-07_220335_labg1k.png'
        },
        {
            vmid: 'twitter-image-alt',
            name: 'twitter:image:alt',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-site',
            name: 'twitter:site',
            content: '@DrManishasYoga'
        },
        {
            vmid: 'twitter-creator',
            name: 'twitter:creator',
            content: '@DrManishasYoga'
        },
        {
            vmid: 'twitter-url',
            name: 'twitter:url',
            content: 'https://drmanishasyogainstitute.com'
        },
        {
            vmid: 'twitter-domain',
            name: 'twitter:domain',
            content: 'drmanishasyogainstitute.com'
        },
      ]
    }
  },
  data() {
    return {
      services: {},
      relatedServices: [
        {
          title: "Yoga for Weight Loss",
          image: "https://images.pexels.com/photos/6551111/pexels-photo-6551111.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Yoga offers a sustainable, low-impact approach to weight loss that combines physical movement, mindful awareness, and stress reduction for long-term, holistic health.",
          to: "/services/yoga-for-weight-loss"
        },
        {
          title: "Laughter Yoga",
          image: "https://img.freepik.com/free-photo/close-up-mature-women-laughing_23-2148348780.jpg?w=1060&t=st=1679899760~exp=1679900360~hmac=685555a5d11942765698bec0da594099a22e86c6e6ce4482bb2e999b0353cce8",
          description: "Laughter yoga is a unique and fun approach to wellness that combines laughter, deep breathing, and mindfulness, boosting mood, reducing stress, and improving overall health and well-being.",
          to: "/services/laughter-yoga"
        },
        {
          title: "Dance Exercise",
          image: "https://images.pexels.com/photos/863977/pexels-photo-863977.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Dance exercise offers a joyful and energetic way to stay active and healthy, combining the benefits of cardiovascular exercise, muscular strength, and coordination with the joy and expression of dance.",
          to: "/services/dance-exercise"
        }
      ]
    }
  },
  async mounted() {
    const _id = '5';
    const response = await axios.get(`https://yogaservices-cc925-default-rtdb.asia-southeast1.firebasedatabase.app/services/${_id}.json`);
    this.services = response.data;
    // console.log(this.services);
  }
}
</script>
  