<template>
    <div class="mt-16">

        <div class="items-center justify-center text-center">
            <h2 class="text-6xl font-semibold text-main-text mb-5">Services</h2>
            <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />
        </div>
        <component :is="currentService" />


    </div>
</template>

<script>
import DanceExercise from '../DanceExercise/DanceExercise.vue';
import HealingTherapy from '../HealingTherapy/HealingTherapy.vue';
import LaughterYoga from '../LaughterYoga/LaughterYoga.vue';
import MeditationPranayam from '../MeditationPranayam/MeditationPranayam.vue';
import TraditionalYoga from '../TraditionalYoga/TraditionalYoga.vue';
import YogaForAllroundFitness from '../YogaForAllroundFitness/YogaForAllroundFitness.vue';
import YogaForKids from '../YogaForKids/YogaForKids.vue';
import YogaForWeightLoss from '../YogaForWeightLoss/YogaForWeightLoss.vue';

import ErrorPage from '@/components/pages/ErrorPage.vue';

export default {
    name: "MainServices",
    data() {
        return {};
    },
    components: {
        TraditionalYoga,
        DanceExercise,
        HealingTherapy,
        LaughterYoga,
        MeditationPranayam,
        YogaForAllroundFitness,
        YogaForKids,
        YogaForWeightLoss,
        ErrorPage
    },
    computed: {
        currentService() {
            const path = this.$route.path;
            switch (path) {
                case "/services/traditional-yoga":
                    return "TraditionalYoga";
                case "/services/dance-exercise":
                    return "DanceExercise";
                case "/services/healing-therapy":
                    return "HealingTherapy";
                case "/services/laughter-yoga":
                    return "LaughterYoga";
                case "/services/meditation-pranayam":
                    return "MeditationPranayam";
                case "/services/yoga-for-allround-fitness":
                    return "YogaForAllroundFitness";
                case "/services/yoga-for-kids":
                    return "YogaForKids";
                case "/services/yoga-for-weight-loss":
                    return "YogaForWeightLoss";
                default:
                    return "ErrorPage";
            }
        },
    },
}
</script>