<template>
  <div>
    <div v-if="isLoading" class="fixed top-0 left-0 w-screen h-screen flex items-center justify-center">
      <div class="loader-container">
        <img src="@/assets/images/logo.png" alt="Dr. Manisha's Yoga Institute logo" class="loader-logo">
      </div>
    </div>
    <div v-else>
      <router-view />
    </div>
  </div>
</template>

<script>
import '@/assets/css/main.css';
import { initFlowbite } from 'flowbite';

export default {
  name: 'App',
  mounted() {
    initFlowbite();
    // Simulate a delay to show the spinner
    setTimeout(() => {
      this.isLoading = false
    }, 1000)
  },
  data() {
    return {
      isLoading: true
    }
  },
}

</script>

<style>
  
.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  animation: fade-in-out 2s ease-in-out infinite;
}

.loader-logo {
  width: 100px;
  height: 100px;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
