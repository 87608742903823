import { render, staticRenderFns } from "./PranayamaCertificationCourse.vue?vue&type=template&id=59a17344&"
import script from "./PranayamaCertificationCourse.vue?vue&type=script&lang=js&"
export * from "./PranayamaCertificationCourse.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports