<template>
    <div>
        <Layout>
            <template v-slot:default>
                <div class="bg-white rounded-lg shadow-md p-6 md:mt-32">
                    <div class="flex justify-center items-center">
                        <div class="relative h-56 sm:w-80 md:w-96 lg:w-112 xl:w-128 rounded-lg overflow-hidden">
                            <div class="text-4xl text-center font-semibold">Affiliated By</div>
                            <img class="absolute h-full w-full object-contain" src="@/assets/images/s-vyasa.png"
                                alt="Service 1">
                            <div class="absolute top-0 left-0 w-full h-full bg-main-bg bg-opacity-10"></div>
                        </div>
                    </div>

                    <div class="text-center mt-8 md:mt-16">
                        <h1 class="text-3xl md:text-5xl font-medium text-main-text uppercase mb-4">Recognised by Ayush Gov.
                            Of India
                        </h1>
                        <hr
                            class="w-10 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:w-20 md:my-6 dark:bg-gray-700" />
                    </div>

                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                        <div class="bg-white shadow-lg rounded-lg overflow-hidden">
                            <img class="w-full h-64 object-cover"
                                src="https://res.cloudinary.com/doqozlqzp/image/upload/v1683471075/pexels-photo-3822718_dtcsor.jpg"
                                alt="Yoga Instructor Certification Course">
                            <div class="p-4">
                                <h3 class="font-semibold text-lg mb-2">Yoga Instructor Certification Course</h3>
                                <p class="text-gray-700 text-base mb-2">Government-Affiliated Yoga Instructor Training for
                                    Holistic Wellness and Accreditation.</p>
                                <div class="flex items-center">
                                    <div class="mr-2">
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-gray-300"></i>
                                    </div>
                                    <span class="text-gray-600 text-sm">(3,840)</span>
                                </div>
                                <div class="mt-4 flex justify-between items-center">
                                    <router-link
                                        class="bg-main-orange hover:bg-main-orange-dark text-white px-4 py-2 rounded-lg transition duration-300 ease-in-out"
                                        to="/courses/gov/yoga-instructor-certification-course">
                                        Enroll Now
                                    </router-link>
                                </div>
                            </div>
                        </div>

                        <div class="bg-white shadow-lg rounded-lg overflow-hidden">
                            <img class="w-full h-64 object-cover"
                                src="https://res.cloudinary.com/doqozlqzp/image/upload/v1683470813/pexels-photo-7055670_sshzzq.jpg"
                                alt="Pregnancy Yoga Certification Course">
                            <div class="p-4">
                                <h3 class="font-semibold text-lg mb-2">Pregnancy Yoga Certification Course</h3>
                                <p class="text-gray-700 text-base mb-2">Certify as a Pregnancy Yoga Instructor through
                                    Government Affiliated Course.</p>
                                <div class="flex items-center mb-2">
                                    <div class="mr-2">
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-gray-300"></i>
                                    </div>
                                    <span class="text-gray-600 text-sm">(3,360)</span>
                                </div>
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="bg-main-orange hover:bg-main-orange-dark text-white px-4 py-2 rounded-lg transition duration-300 ease-in-out"
                                        to="/courses/gov/pregnancy-yoga-certification-course">
                                        Enroll Now
                                    </router-link>

                                </div>
                            </div>
                        </div>

                    </div>



                    <div class="text-center mt-16">
                        <h1 class="text-5xl font-medium text-main-text uppercase mb-4">Personal Course</h1>
                        <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />

                    </div>
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 mt-8">
                        <div class="bg-white shadow-lg rounded-lg overflow-hidden">
                            <img class="w-full h-64 object-cover" src="@/assets/images/personalcerti1.jpeg"
                                alt="Pregnancy Yoga Certification Course">
                            <div class="p-4">
                                <h3 class="font-semibold text-lg mb-2">Pranayama Certification Course</h3>
                                <p class="text-gray-700 text-base mb-2">Unlock Inner Peace and Boost Your Health with Pranayama Certification Course - Learn Powerful Breathing Techniques Today! 🌬️✨</p>
                                <div class="flex items-center mb-2">
                                    <div class="mr-2">
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-gray-300"></i>
                                    </div>
                                    <span class="text-gray-600 text-sm">(2,110)</span>
                                </div>
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="bg-main-orange hover:bg-main-orange-dark text-white px-4 py-2 rounded-lg transition duration-300 ease-in-out"
                                        to="/courses/personal/pranayama-certification-course">
                                        Enroll Now
                                    </router-link>

                                </div>
                            </div>
                        </div>
                        <div class="bg-white shadow-lg rounded-lg overflow-hidden">
                            <img class="w-full h-64 object-cover" src="@/assets/images/personalcerti2.jpeg"
                                alt="Pregnancy Yoga Certification Course">
                            <div class="p-4">
                                <h3 class="font-semibold text-lg mb-2">Meditation Certification Course</h3>
                                <p class="text-gray-700 text-base mb-2">Unlock Inner Peace and Become a Certified Meditation Pro! Join Our Life-Changing Meditation Certification Course Today! 🧘✨ Boost your skills, achieve serenity, and change your life forever. Enroll now! </p>
                                <div class="flex items-center mb-2">
                                    <div class="mr-2">
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-yellow-300"></i>
                                        <i class="fas fa-star text-gray-300"></i>
                                    </div>
                                    <span class="text-gray-600 text-sm">(2,140)</span>
                                </div>
                                <div class="flex justify-between items-center">
                                    <router-link
                                        class="bg-main-orange hover:bg-main-orange-dark text-white px-4 py-2 rounded-lg transition duration-300 ease-in-out"
                                        to="/courses/personal/meditation-certification-course">
                                        Enroll Now
                                    </router-link>

                                </div>
                            </div>
                        </div>


                    </div>

                </div>
            </template>
        </Layout>
    </div>
</template>
<script>
import Layout from '../Layout/Layout.vue';
export default {
    name: 'CoursesPage',
    components: {
        Layout,
    },
    
    metaInfo() {
        
        return {
            title: 'Courses | Dr. Manishas Yoga Institute',
            meta: [
                { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                {
                    vmid: 'og-title',
                    property: 'og:title',
                    content: 'Courses | Dr. Manishas Yoga Institute',
                },
                {
                    vmid: 'og-description',
                    property: 'og:description',
                    content: 'Join our yoga courses and learn from experienced teachers. Check out our course options and book your spot now!',
                },
                {
                    vmid: 'og-description',
                    name: 'description',
                    content: 'Join our yoga courses and learn from experienced teachers. Check out our course options and book your spot now!',
                },
                {
                    vmid: 'og-image',
                    property: 'og:image',
                    content: `https://res.cloudinary.com/doqozlqzp/image/upload/v1683470813/pexels-photo-7055670_sshzzq.jpg`,
                },
                {
                    vmid: 'og-image-type',
                    property: 'og:image:type',
                    content: 'image/jpg'
                },
                {
                    vmid: 'og-image-width',
                    property: 'og:image:width',
                    content: '1200'
                },
                {
                    vmid: 'og-image-height',
                    property: 'og:image:height',
                    content: '630'
                },
                {
                    vmid: 'og-image-alt',
                    property: 'og:image:alt',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'og-type',
                    property: 'og:type',
                    content: 'health care website'
                },
                {
                    vmid: 'twitter-card',
                    name: 'twitter:card',
                    content: 'summary_large_image'
                },
                {
                    vmid: 'twitter-title',
                    name: 'twitter:title',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-description',
                    name: 'twitter:description',
                    content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
                },
                {
                    vmid: 'twitter-image',
                    name: 'twitter:image',
                    content: `https://res.cloudinary.com/doqozlqzp/image/upload/v1683470813/pexels-photo-7055670_sshzzq.jpg`,
                },
                {
                    vmid: 'twitter-image-alt',
                    name: 'twitter:image:alt',
                    content: 'Yoga Courses',
                },
                {
                    vmid: 'twitter-url',
                    name: 'twitter:url',
                    content: 'https://drmanishasyogainstitute.com'
                },
                {
                    vmid: 'twitter-domain',
                    name: 'twitter:domain',
                    content: 'drmanishasyogainstitute.com'
                },
            ],
        }
    }
}
</script>
