<template>
    <div>
        <footer class="bg-white rounded-lg shadow sm:flex sm:items-center sm:justify-between p-4 sm:p-6 xl:p-8 dark:bg-gray-800">
            <p class="mb-4 text-sm text-center text-gray-500 dark:text-gray-400 sm:mb-0">
                &copy; 2023 <a href="#" class="hover:underline" target="_blank">Dr.Manisha's Yoga Institute</a>. All rights reserved.
            </p>
            <div class="flex justify-center items-center space-x-1">
              
            </div>
          </footer>
    </div>
</template>
<script>
export default {
    name:'AdminFooter'
}
</script>