<template>
    <div>
        <Layout>
            <template v-slot:default>
                <ContactForm class="mt-32" />
            </template>
        </Layout>
    </div>
</template>
<script>
import ContactForm from '../components/Contact/ContactForm.vue';
import Layout from '../Layout/Layout.vue';
export default {
    name: 'ContactPage',
    components: {
        ContactForm,
        Layout
    },
    metaInfo() {
        return {
            title: "Contact | Dr. Manisha's Yoga Institute",
            meta: [
                {
                    name: 'description',
                    content: "Get in touch with Dr. Manisha's Yoga Institute to start your journey towards a healthy mind and body. Our experienced yoga teachers offer a range of courses, workshops, and retreats to suit your needs. Contact us today to learn more"
                },
                {
                    name: 'keywords',
                    content: "Dr. Manisha's Yoga Institute, Yoga education, Yogic Science, Cultural Synthesis, yoga courses, yoga teacher training, yoga retreats, yoga workshops, meditation, healthy mind, healthy body, stress relief, fitness, holistic healing, contact us."
                },
                {
                    name: 'robots',
                    content: 'index, follow'
                },
                {
                    name: 'author',
                    content: 'Dr. Manisha Sonawane'
                },
                {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1.0'
                },
                {
                    name: 'google',
                    content: 'notranslate'
                },
                {
                    name: 'googlebot',
                    content: 'index, follow'
                },
                {
                    name: 'google-site-verification',
                    content: 'google-site-verification=0'
                },
                {
                    name: 'msapplication-TileColor',
                    content: '#ffffff'
                },
                {
                    name: 'msapplication-TileImage',
                    content: '/ms-icon-144x144.png'
                },
                {
                    name: 'og:title',
                    content: "Contact | Dr. Manisha's Yoga Institute"
                },
                {
                    name: 'og:description',
                    content: "Get in touch with Dr. Manisha's Yoga Institute to start your journey towards a healthy mind and body. Our experienced yoga teachers offer a range of courses, workshops, and retreats to suit your needs. Contact us today to learn more"
                },
                {
                    name: 'og:image',
                    content: '@/assets/images/Dr. Manisha About Page Image.png'
                },
                {
                    name: 'og:url',
                    content: 'https://www.google.com/'
                },
                {
                    name: 'og:site_name',
                    content: "Dr. Manisha's Yoga Institute"
                },
                {
                    name: 'og:locale',
                    content: 'en_US'
                },
                {
                    name: 'og:type',
                    content: 'yoga website'
                },
                {
                    name: 'twitter:card',
                    content: "Get in touch with Dr. Manisha's Yoga Institute to start your journey towards a healthy mind and body. Our experienced yoga teachers offer a range of courses, workshops, and retreats to suit your needs. Contact us today to learn more"

                },
                {
                    name: 'twitter:title',
                    content: "Contact | Dr. Manisha's Yoga Institute"
                },
                {
                    name: 'twitter:description',
                    content: "Get in touch with Dr. Manisha's Yoga Institute to start your journey towards a healthy mind and body. Our experienced yoga teachers offer a range of courses, workshops, and retreats to suit your needs. Contact us today to learn more"

                },
                {
                    name: 'twitter:image',
                    content: '@/assets/images/Dr. Manisha About Page Image.png'
                },
                {
                    name: 'twitter:site',
                    content: '@google'
                },
                {
                    name: 'twitter:creator',
                    content: '@google'
                }
            ]
        }
    }
}
</script>