<template>
    <div class="container mt-20 mx-auto px-4 h-full">
        <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 px-4">
                <div
                    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                    <div class="rounded-t mb-0 px-6 py-6">
                        <div class="text-center mb-6">
                            <h6 class="text-main-text text-4xl font-medium">
                                Sign in
                            </h6>
                        </div>

                    </div>
                    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                        <!-- <div class="text-blueGray-400 text-center mb-3 font-bold">
                            <small>Or sign in with credentials</small>
                        </div> -->
                        <form @submit.prevent="login">
                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="email">
                                    Email
                                </label>
                                <input v-model="email" type="email" id="email" name="email" :disabled="submitDisabled"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Email" />
                            </div>

                            <div class="relative w-full mb-3">
                                <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2" htmlFor="password">
                                    Password
                                </label>
                                <input v-model="password" type="password" id="password" name="password"
                                    :disabled="submitDisabled"
                                    class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                    placeholder="Password" />
                            </div>

                            <div>
                                <label class="inline-flex items-center cursor-pointer">
                                    <input id="customCheckLogin" type="checkbox"
                                        class="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150" />
                                    <span class="ml-2 text-sm font-semibold text-blueGray-600">
                                        Remember me
                                    </span>
                                </label>
                            </div>

                            <div class="text-center mt-6">
                                <button :disabled="submitDisabled"
                                    class="bg-blueGray-800 text-main-text active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                    type="submit">
                                    Sign In
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="flex flex-wrap mt-6 relative">
                    <div class="w-1/2">
                        <a href="javascript:void(0)" class="text-blueGray-200">
                            <small>Forgot password?</small>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from '@/config'

export default {
    name: 'AdminLogin',
    data() {
        return {
            email: '',
            password: '',
            submitDisabled: false,
        };
    },
    methods: {
        async login(e) {
            e.preventDefault();
            try {
                const response = await fetch(`${API_URL}/api/auth/admin/login`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        email: this.email,
                        password: this.password,
                    }),
                });
                const json = await response.json();
                if (response.ok) {
                    localStorage.setItem('token', json.authtoken);
                    this.$router.push('/admin/dashboard');
                } else {
                    if (json.errors) {
                        json.errors.forEach(error => {
                            alert(error.msg)
                        });
                    } else {
                        alert(json.message);
                    }
                }
            } catch (error) {
                console.error(error);
                await Swal.fire({
                    icon: 'error',
                    title: 'Login failed',
                    text: 'Please check your credentials and try again.',
                });
            }
        },
    },

};
</script>   
  