<template>
    <div>
        <section class="mt-8 md:mt-40 pb-16 md:pb-40 relative bg-blueGray-100">
            <div class="mx-auto px-6 md:px-0 max-w-7xl">
                <div class="justify-center text-center flex flex-wrap mt-16 md:mt-24">
                    <div class="w-full md:w-6/12 px-6 md:px-12">
                        <h2 class="font-medium text-4xl md:text-6xl">Dr. Manisha's Yoga Institute</h2>
                        <hr
                            class="w-20 h-1 mx-auto my-4 mt-2 md:mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />
                    </div>
                </div>
            </div>
        </section>

        <section class="block relative z-1 bg-main-grey bg-opacity-80">
            <div class="container mx-auto">
                <div class="justify-center flex flex-wrap">
                    <div class="w-full lg:w-12/12 px-4 -mt-24">
                        <div class="flex flex-wrap">
                            <div class="w-full md:w-4/12 lg:w-4/12 px-4 mb-4 md:mb-0">
                                <h5 class="text-xl font-semibold pb-4 text-center">
                                </h5>

                                <div
                                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img alt="Dr. Manisha's Yoga Institute"
                                        class="align-middle border-none max-w-full h-auto rounded-lg object-cover object-center"
                                        src="https://res.cloudinary.com/doqozlqzp/image/upload/v1679916428/1_ffoi5d.jpg" />
                                </div>
                            </div>

                            <div class="w-full md:w-4/12 lg:w-4/12 px-4 mb-4 md:mb-0">
                                <h5 class="text-xl font-semibold pb-4 text-center">
                                </h5>

                                <div
                                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img alt="Dr. Manisha's Yoga Institute"
                                        class="align-middle border-none max-w-full h-auto rounded-lg object-cover object-center"
                                        src="https://res.cloudinary.com/doqozlqzp/image/upload/v1679916429/5_q66acp.jpg" />
                                </div>
                            </div>

                            <div class="w-full md:w-4/12 lg:w-4/12 px-4 mb-4 md:mb-0">
                                <h5 class="text-xl font-semibold pb-4 text-center">
                                </h5>

                                <div
                                    class="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                                    <img alt="Dr. Manisha's Yoga Institute"
                                        class="align-middle border-none max-w-full h-auto rounded-lg object-cover object-center"
                                        src="https://res.cloudinary.com/doqozlqzp/image/upload/v1679916429/2_ay53sx.jpg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="py-8 md:py-16 bg-gray-100">
            <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-wrap justify-center items-center">
                <div class="w-full md:w-1/2 lg:w-5/12 md:px-4 lg:px-8">
                    <h3 class="text-3xl md:text-4xl font-semibold text-gray-800 mb-4 md:mb-6">
                        About Manisha's Yoga Institute
                    </h3>
                    <p class="text-lg text-justify md:text-xl leading-relaxed text-gray-700 mb-8">
                        Discover the transformative power of yoga at Dr. Manisha Institute of Yogic Science and Cultural
                        Synthesis. Our unique approach to yoga education promotes physical, mental, and spiritual
                        well-being. Join us in promoting a healthy mind and
                        body through the ancient practice of yoga.
                    </p>
                </div>
                <div class="w-full md:w-1/2 lg:w-7/12 mb-10 md:px-4 lg:px-8">
                    <img src="@/assets/images/logo.png" alt="Manisha's Yoga Institute"
                        class="mx-auto max-w-xs md:max-w-md lg:max-w-lg" />
                </div>
            </div>
        </section>


        <section class="pb-16 bg-main-grey bg-opacity-80 relative pt-32">
            <div class="container mx-auto px-4 sm:px-6 lg:px-8">
                <div
                    class="flex flex-wrap mx-auto justify-center bg-main-orange shadow-xl rounded-lg -mt-64 py-16 px-12 relative z-10">
                    <div class="w-full lg:w-8/12 text-center">
                        <p class="text-7xl">
                            <span role="img" aria-label="love">🧘</span>
                        </p>
                        <h3 class="font-semibold text-3xl mt-4 mb-2">Book Our Free Demo Session Now!</h3>
                        <p class="text-blueGray-500 text-lg leading-relaxed mt-4 mb-4">
                            Experience the transformative power of yoga with a free demo session at Dr. Manisha's Yoga Institute. Fill out the form to book your spot and get a taste of our courses with our experienced yoga teachers. Start your journey towards a healthier mind and body today.
                        </p>
                        <div class="sm:block flex flex-col mt-10">
                            <router-link to="/book-free-demo-yoga-session"
                                class="bg-main-bg active:bg-emerald-600 uppercase text-sm font-bold text-main-text py-3 px-6 rounded-lg shadow hover:shadow-lg transition-all duration-150 ease-linear outline-none focus:outline-none mr-1 mb-2 hover:bg-main-bg hover:opacity-75">
                                Book Now
                            </router-link>
                        </div>
                        <div class="text-center mt-16"></div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
export default {
    name: "MainBlog",

};
</script>

<style scoped>.right-100 {
    right: -70%;
}</style>