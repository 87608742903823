<template>
    <div>
        <Layout>
            <template v-slot:default>

                <div class="bg-gray-100 mt-24 min-h-screen overflow-x-hidden">
                    <!-- Banner section -->
                    <div class="w-full">
                        <div class="w-screen h-96 relative">
                            <div class="bg-black opacity-25 absolute inset-0"></div>
                            <div class="w-full h-full">
                                <img :src="post.imageUrl" :alt="post.title" class="h-full w-full object-cover">
                                <div class="absolute inset-0 flex items-center justify-center">
                                </div>
                                <div class="absolute inset-0 flex text-center px-2 items-center justify-center">
                                    <h1 class="text-white  font-bold text-4xl">{{ post.title }}</h1>
                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Post content section -->
                    <div class="max-w-6xl mx-auto px-4 py-8">
                        <div class="bg-white rounded-lg shadow-md overflow-hidden">
                            <div class="p-4">
                                <div class="flex flex-col md:flex-row items-center justify-between mb-4 bg-white bg-opacity-50 shadow-md rounded-lg p-6 backdrop-filter backdrop-blur-lg backdrop-saturate-150"
                                    style="background-color: rgba(254, 228, 229, 0.5);">
                                    <div class="flex items-center mb-4 md:mb-0">
                                        <img src="@/assets/images/logo.png" alt="Author Avatar"
                                            class="h-16 w-16 rounded-full mr-4">
                                        <div>
                                            <div class="text-sm text-gray-500">{{ post.createdDate }}</div>
                                            <div class="text-xl font-bold text-gray-900">{{ post.title }}</div>
                                        </div>
                                    </div>
                                    <div class="flex flex-wrap items-center justify-center md:justify-start">
                                        <span class="mr-4 text-gray-500">Share:</span>
                                        <a :href="facebookUrl" target="_blank" rel="noopener noreferrer" class="mr-2">
                                            <i class="fab fa-facebook fa-lg text-gray-500 hover:text-blue-500"></i>
                                        </a>
                                        <a :href="twitterUrl" target="_blank" rel="noopener noreferrer" class="mr-2">
                                            <i class="fab fa-twitter fa-lg text-gray-500 hover:text-blue-400"></i>
                                        </a>
                                        <a :href="linkedinUrl" target="_blank" rel="noopener noreferrer" class="mr-2">
                                            <i class="fab fa-linkedin fa-lg text-gray-500 hover:text-blue-700"></i>
                                        </a>
                                        <a :href="whatsappUrl" target="_blank" rel="noopener noreferrer" class="mr-2">
                                            <i class="fab fa-whatsapp fa-lg text-gray-500 hover:text-green-500"></i>
                                        </a>
                                        <a :href="pinterestUrl" target="_blank" rel="noopener noreferrer" class="mr-2">
                                            <i class="fab fa-pinterest fa-lg text-gray-500 hover:text-red-500"></i>
                                        </a>
                                        <a :href="emailUrl" target="_blank" rel="noopener noreferrer">
                                            <i class="fas fa-envelope fa-lg text-gray-500 hover:text-gray-700"></i>
                                        </a>
                                    </div>
                                </div>


                                <div class="prose max-w-none text-justify">
                                    <div class="text-lg" v-html="post.description"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </template>
        </Layout>
    </div>
</template>

<script>
import Layout from '../Layout/Layout.vue';

import axios from 'axios';
export default {
    name: "SingleBlogPage",
    components: {
        Layout
    },
    data() {
        return {
            id: '',
            post: '',
            title: '',
            description: '',
            imageUrl: null,
            createdDate: '',

        }
    },
    computed: {
        facebookUrl() {
            const imageUrl = encodeURIComponent(this.post.imageUrl);
            const title = encodeURIComponent(this.post.title);
            const url = encodeURIComponent(window.location.href);
            return `https://www.facebook.com/sharer/sharer.php?u=${url}&picture=${imageUrl}&title=${title}`;
        },

        linkedinUrl() {
            return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(this.post.title)}&summary=${encodeURIComponent(this.post.description)}&source=${encodeURIComponent(this.post.imageUrl)}`;
        },

        twitterUrl() {
            return `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(this.post.title)}&media=${encodeURIComponent(this.post.imageUrl)}`;
        },

        whatsappUrl() {
            const text = `${this.post.title} ${window.location.href}`;
            const image = this.post.imageUrl;
            return `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}&amp&amp&amp&amp&amp&amp;media=${encodeURIComponent(image)}`;
        },

        pinterestUrl() {
            return `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}&media=${encodeURIComponent(this.post.imageUrl)}&description=${encodeURIComponent(this.post.title)}`;
        },
        emailUrl() {
            return `mailto:?subject=${encodeURIComponent(this.post.title)}&body=${encodeURIComponent(`${window.location.href}\n\n${this.post.imageUrl}`)}`;
        },
    },

    created() {
        this.id = this.$route.params.id;
        console.log(this.id);
        axios.get(`https://yogaproducts-16590-default-rtdb.firebaseio.com/post/${this.id}.json`)
            .then((response) => {
                this.post = response.data;
                console.log(this.post);
            })
    },
    metaInfo() {
        return {
            title:  this.post.title + " |  Dr. Manisha's Yoga Institute",
            meta: [
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.post.seoDescription
                },
                {
                    vmid: 'keywords',
                    name: 'keywords',
                    content: this.post.seoKeywords
                },
                {
                    vmid: 'og-title',
                    name: 'og:title',
                    content: this.post.title
                },
                {
                    vmid: 'og-description',
                    name: 'og:description',
                    content: this.post.seoDescription
                },
                {
                    vmid: 'og-image',
                    name: 'og:image',
                    content: this.post.imageUrl
                },
                {
                    vmid: 'og-url',
                    name: 'og:url',
                    content: `https://drmanishasyogainstitute.com/blog/${this.id}`
                },
                {
                    vmid: 'twitter-title',
                    name: 'twitter:title',
                    content: this.post.title
                },
                {
                    vmid: 'twitter-description',
                    name: 'twitter:description',
                    content: this.post.seoDescription
                },
                {
                    vmid: 'twitter-image',
                    name: 'twitter:image',
                    content: this.post.imageUrl
                },
                {
                    vmid: 'twitter-card',
                    name: 'twitter:card',
                    content: 'summary_large_image'
                },
            ]
        }
    }


}
</script>

<style scoped>
/* CSS code */
a:hover .fab.fa-facebook {
    color: #3b5998;
    /* Facebook's blue color */
}

a:hover .fab.fa-twitter {
    color: #1da1f2;
    /* Twitter's blue color */
}

a:hover .fab.fa-linkedin {
    color: #0e76a8;
    /* LinkedIn's blue color */
}

a:hover .fab.fa-whatsapp {
    color: #25D366;
    /* WhatsApp's green color */
}

a:hover .fab.fa-pinterest {
    color: #bd081c;
    /* Pinterest's red color */
}

a:hover .fas.fa-envelope {
    color: #d44638;
    /* Email's red color */
}
</style>