<template>
    <div>
        <Layout>
            <template v-slot:default>
                <MainServices class="mt-32"/>
            </template>
        </Layout>
    </div>
</template>

<script>
import MainServices from '../components/Services/AllServices/MainServices.vue';
import Layout from '../Layout/Layout.vue';
export default {
    name: 'ServicesPage',
    components: {
        Layout,
        MainServices
    }
}
</script>