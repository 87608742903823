<template>
    <div>
        <div class="flex mt-32 flex-wrap">

            <form @submit.prevent="onCreatePost()"
                class="max-w-full mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
                <h1 class="block text-gray-700 font-bold mb-2 text-xl text-center">Create Blog Post</h1>
                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="title">
                        Title
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="title" type="text" v-model="title">
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="description">
                        Description
                    </label>
                    <editor ref="myTextEditor" api-key="nho6z4l4y4h9qoxhmi1gcitneruobar1gxycib1m96myifd7" :init="editorInit"
                        v-model="description" class="w-full"></editor>
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="seoKeywords">
                        SEO Tags
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="seoKeywords" type="text" v-model="seoKeywords" placeholder="Enter ( , ) seperated values">
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="seoDescription">
                        SEO Description
                    </label>
                    <input
                        class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="seoDescription" type="text" v-model="seoDescription">
                </div>
                <div class="mb-4">
                    <label class="block text-gray-700 font-bold mb-2" for="seoDescription">
                        Upload Image
                    </label>
                    <button type="button"
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        @click="openUploadWidget">Upload Image</button>
                    <p v-if="!imageUrl">No image uploaded</p>
                    <p v-if="imageUrl">
                        Uploaded image URL: {{ imageUrl }}
                    </p>

                </div>
                <div class="flex items-center justify-between">
                    <button
                        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit">
                        Create Post
                    </button>
                </div>
            </form>

        </div>
    </div>
</template>
  
<script>
import axios from "axios";
import Editor from '@tinymce/tinymce-vue';
import DOMPurify from 'dompurify';

export default {
    name: "CreateBlog",
    components: {
        'editor': Editor
    },
    data() {
        return {
            title: "",
            description: "",
            seoKeywords: "",
            seoDescription: "",
            imageUrl: null,
            createdDate: new Date().toISOString().slice(0, 10),

        };
    },
    computed: {
        editorInit() {
            return {
                plugins:
                    'anchor imagetools autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount code',
                toolbar:
                    'imagetools | undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | code',
                menubar: true,
                branding: false,
                statusbar: false,
                format:'html'
            };
        }
    },
    methods: {
        onCreatePost() {
            const purifyConfig = {
                ALLOWED_TAGS: [
                    "h1",
                    "h2",
                    "h3",
                    "h4",
                    "h5",
                    "h6",
                    "blockquote",
                    "p",
                    "a",
                    "ul",
                    "ol",
                    "nl",
                    "li",
                    "b",
                    "i",
                    "strong",
                    "em",
                    "strike",
                    "code",
                    "hr",
                    "br",
                    "div",
                    "table",
                    "thead",
                    "caption",
                    "tbody",
                    "tr",
                    "th",
                    "td",
                    "pre",
                    "iframe",
                    "img",
                ],
            };
            const plainDescription = DOMPurify.sanitize(this.description, purifyConfig, { RETURN_DOM: true });
            const plainSeoDescription = DOMPurify.sanitize(this.seoDescription, purifyConfig, { RETURN_DOM: false });
            axios
                .post(
                    `https://yogaproducts-16590-default-rtdb.firebaseio.com/post.json`,
                    {
                        title: this.title,
                        description: plainDescription,
                        seoKeywords: this.seoKeywords,
                        seoDescription: plainSeoDescription,
                        imageUrl: this.imageUrl,
                        createdDate: this.createdDate,
                    }
                )
                .then((response) => {
                    console.log(response);
                    // go to admin/recent-blog
                    this.$router.push("/admin/recent-blogs");
                });
            this.onClearForm();
        },

        onClearForm() {
            this.title = "";
            this.description = "";
            this.seoKeywords = "";
            this.seoDescription = "";
            this.imageUrl = null;
        },

        openUploadWidget() {
            window.cloudinary.openUploadWidget({
                cloud_name: "doqozlqzp",
                upload_preset: "manishasyoga",
                sources: ["local"],
                showAdvancedOptions: false,
                cropping: true,
                multiple: false,
                defaultSource: "local",
                styles: {
                    palette: {
                        window: "#FFFFFF",
                        windowBorder: "#90A0B3",
                        tabIcon: "#0078FF",
                        menuIcons: "#5A616A",
                        textDark: "#000000",
                        textLight: "#FFFFFF",
                        link: "#0078FF",
                        action: "#FF620C",
                        inactiveTabIcon: "#0E2F5A",
                        error: "#F44235",
                        inProgress: "#0078FF",
                        complete: "#20B832",
                        sourceBg: "#E4EBF1"
                    },
                }
            },
                (err, res) => {
                    if (!err && res && res.event === "success") {
                        this.imageUrl = res.info.secure_url;
                        console.log("Done! Here is the image info: ", res.info);
                    }
                }

            )
        },
    },
};
</script>
  