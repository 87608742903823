import HomePage from "./components/pages/HomePage.vue";
import AboutPage from "./components/pages/AboutPage.vue";
import ErrorPage from "./components/pages/ErrorPage.vue";
import ContactPage from "./components/pages/ContactPage.vue";
import DemoSessionForm from "./components/pages/DemoSessionForm.vue";
import EnrollNowForm from "./components/pages/EnrollNowForm.vue";
import ServicesPage from "./components/pages/ServicesPage.vue";
// import PricingPage from './components/pages/PricingPage.vue';
import ProductsPage from "./components/pages/ProductsPage.vue";
import CheckoutPage from "./components/pages/CheckoutPage.vue";
import GalleryPage from "./components/pages/GalleryPage.vue";
import BlogPage from "./components/pages/BlogPage.vue";
import CoursesPage from "./components/pages/CoursesPage.vue";
import SpecialServicePage from "./components/pages/SpecialServicePage.vue";
import PregnancyYoga from "./components/pages/PregnancyYoga.vue";
import YogaInstructor from "./components/pages/YogaInstructor.vue";
import UserSigninPage from "./components/pages/UserSigninPage.vue";
import SingleBlogPage from "./components/pages/SingleBlogPage.vue";
import PrivacyPolicy from "./components/pages/PrivacyPolicy.vue";
import PranayamaCertificationCourse from "./components/pages/PranayamaCertificationCourse.vue";
import MeditationCertificationCourse from "./components/pages/MeditationCertificationCourse.vue";



/*Admin */
import Admin from "@/components/Admin/Layout/Admin.vue";
import AdminLogin from "@/components/Admin/Login/AdminLogin.vue";
import AdminDashboard from "@/components/Admin/Dashboard/AdminDashboard.vue";
import AllUsers from "@/components/Admin/Users/AllUsers.vue";
import AllOrders from "@/components/Admin/AllOrders/AllOrders.vue";
import CreateBlog from "@/components/Admin/CreateBlog/CreateBlog.vue";
import RecentBlogs from "@/components/Admin/RecentBlogs/RecentBlogs.vue";
import EditBlog from "@/components/Admin/EditBlog/EditBlog.vue";
// import CreateAdmin from "@/components/Admin/CreateAdmin/CreateAdmin.vue";
import PregnancyCourse from "@/components/Admin/PregnancyCourse/PregnancyCourse.vue";
import YogaInstructorCourse from "@/components/Admin/YogaInstructorCourse/YogaInstructorCourse.vue";
import PranayamaCourse from "@/components/Admin/PranayamaCourse/PranayamaCourse.vue";
import MeditationCourse from "@/components/Admin/MeditationCourse/MeditationCourse.vue";
import EnrollNow from "@/components/Admin/EnrollNowForm/EnrollNowForm.vue";
import DemoFormReg from "@/components/Admin/DemoForm/DemoFormReg.vue";

/* User */
import User from "@/components/Users/Layout/User.vue";
import UserDashboard from "@/components/Users/Dashboard/UserDashboard.vue";

import TraditionalYoga from "./components/components/Services/TraditionalYoga/TraditionalYoga.vue";
import DanceExercise from "./components/components/Services/DanceExercise/DanceExercise.vue";
import HealingTherapy from "./components/components/Services/HealingTherapy/HealingTherapy.vue";
import LaughterYoga from "./components/components/Services/LaughterYoga/LaughterYoga.vue";
import MeditationPranayam from "./components/components/Services/MeditationPranayam/MeditationPranayam.vue";
// import TailorMadeYogaForDiseases from "./components/components/Services/TailorMadeYogaForDiseases/TailorMadeYogaForDiseases.vue";
import YogaForAllroundFitness from "./components/components/Services/YogaForAllroundFitness/YogaForAllroundFitness.vue";
import YogaForKids from "./components/components/Services/YogaForKids/YogaForKids.vue";
import YogaForWeightLoss from "./components/components/Services/YogaForWeightLoss/YogaForWeightLoss.vue";

const routes = [
  {
    path: "*",
    name: "error",
    component: ErrorPage,
  },
  {
    path: "/",
    name: "home",
    component: HomePage,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyPolicy,
  },
  {
    path: "/about",
    name: "about",
    component: AboutPage,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactPage,
  },
  {
    path: "/book-free-demo-yoga-session",
    name: "book-free-demo-yoga-session",
    component: DemoSessionForm,
  },
  {
    path:'/enroll-now',
    name:'enroll-now',
    component: EnrollNowForm
  },
  {
    path: "/gallery",
    name: "gallery",
    component: GalleryPage,
  },
  {
    path: "/products",
    name: "products",
    component: ProductsPage,
    
  },
  {
    path: "/services",
    name: "services",
    component: ServicesPage,
    children: [
      {
        path: "traditional-yoga",
        name: "traditional-yoga",
        component: TraditionalYoga,
      },
      {
        path: "meditation-pranayam",
        name: "meditation-pranayam",
        component: MeditationPranayam,
      },
      {
        path: "healing-therapy",
        name: "healing-therapy",
        component: HealingTherapy,
      },
      {
        path: "yoga-for-allround-fitness",
        name: "yoga-for-allround-fitness",
        component: YogaForAllroundFitness,
      },
      {
        path: "yoga-for-kids",
        name: "yoga-for-kids",
        component: YogaForKids,
      },
      {
        path: "yoga-for-weight-loss",
        name: "yoga-for-weight-loss",
        component: YogaForWeightLoss,
      },
      {
        path: "laughter-yoga",
        name: "laughter-yoga",
        component: LaughterYoga,
      },
      {
        path: "dance-exercise",
        name: "dance-exercise",
        component: DanceExercise,
      },
    ],
  },
  {
    path: "/special/services/disease-wise-yoga",
    name: "disease-wise-yoga",
    component: SpecialServicePage,
  },
  {
    path: "/checkout",
    name: "checkout",
    component: CheckoutPage,
   
  },
  // {
  //   path: "/admin/register",
  //   name: "register",
  //   component: CreateAdmin
  // },
  {
    path: "/admin/login",
    name: "login",
    component: AdminLogin
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        name: "adminDashboard",
        component: AdminDashboard,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/all-users",
        name: "allUsers",
        component: AllUsers,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/blogs",
        name: "blogs",
        component: CreateBlog,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/recent-blogs",
        name: "recentBlogs",
        component: RecentBlogs,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/edit-post/:id",
        name: "editBlogs",
        component: EditBlog,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/all-orders",
        name: "allOrders",
        component: AllOrders,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/pregnancy-course-registrations",
        name: "pregnancyCourseRegistrations",
        component: PregnancyCourse,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/yoga-instructor-course-registrations",
        name: "yogaInstructorCourseRegistrations",
        component: YogaInstructorCourse,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/pranayama-certification-course",
        name: "pranayamaCourseRegistrations",
        component: PranayamaCourse,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/meditation-certification-course",
        name: "pranayamaCourseRegistrations",
        component: MeditationCourse,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/enrollments",
        name: "enrollments",
        component: EnrollNow,
        meta: {
          requiresAuth: true
        },
      },
      {
        path: "/admin/freedemo-registrations",
        name: "freedemo-registrations",
        component: DemoFormReg,
        meta: {
          requiresAuth: true
        },
      }
    ]
  },
  {
    path: "/user",
    name: "user",
    component: User,
    children: [
      {
        path: '/user/dashboard/:userId/orders/:orderId',
        name: 'user-dashboard',
        component: UserDashboard,
        props: true
      }
    ],
  },
  {
    path : "/blogs",
    name : "blogs",
    component : BlogPage,
  },
  {
    path: "/blogs/:id",
    name: "blog",
    component: SingleBlogPage
  },
  {
    path: '/courses',
    name: 'courses',
    component: CoursesPage,

  },
  {
    path: '/courses/gov/pregnancy-yoga-certification-course',
    name: 'pregnancy-yoga-certification-course',
    component: PregnancyYoga
  },
  {
    path: '/courses/gov/yoga-instructor-certification-course',
    name: 'yoga-instructor-certification-course',
    component: YogaInstructor
  },
  {
    path: '/courses/personal/pranayama-certification-course',
    name: 'pranayama-certification-course',
    component: PranayamaCertificationCourse
  },
  {
    path: '/courses/personal/meditation-certification-course',
    name: 'meditation-certification-course',
    component: MeditationCertificationCourse
  },
  {
    path: '/signup',
    name: 'signup',
    component: UserSigninPage
  }
];


export default routes;
