<template>
    <div>
        <Layout>
            <template v-slot:default>
                <div class="mt-32 mb-32">
                    <div class="items-center justify-center text-center">
                        <h2 class="text-6xl font-semibold text-main-text mb-5">About Us</h2>
                        <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />
                    </div>
                    <MainAbout />
                </div>
            </template>
        </Layout>

    </div>
</template>

<script>
import MainAbout from '../components/About/MainAbout.vue';
import Layout from '../Layout/Layout.vue';
export default {
    name: 'AboutPage',
    components: {
        Layout,
        MainAbout
    },
    metaInfo() {
        return {
            title: "About | Dr. Manisha's Yoga Institute",
            meta: [
                { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                
                {
                    vmid: 'description',
                    name: 'description',
                    content: 'Welcome to Dr. Manisha Institute of Yogic Science and Cultural Synthesis! Our institute offers yoga education to men and women from all walks of life, helping them attain a healthy mind and body through yoga practices such as asanas, pranayama, and dhyana. Join us and transcend yourself to attain enlightenment.'
                },
                {
                    vmid: 'keywords',
                    name: 'keywords',
                    content: 'Dr. Manisha Sonawane, Yoga teacher, Yoga instructor, Yoga philosophy, Yoga teacher training, Yoga courses, Yoga retreats, Yoga workshops, Meditation, Mindfulness, Health and wellness, Fitness, Stress relief, Holistic healing, Cultural fusion, Yoga education, Yogic Science, Cultural Synthesis, asanas, pranayama, dhyana, healthy mind, healthy body, enlightenment, Dr. Manisha Sonawane'
                },
                {
                    name: 'og:title',
                    content: "About | Dr. Manisha's Yoga Institute"
                },
                {
                    name: 'og:description',
                    content: 'Welcome to Dr. Manisha Institute of Yogic Science and Cultural Synthesis! Our institute offers yoga education to men and women from all walks of life, helping them attain a healthy mind and body through yoga practices such as asanas, pranayama, and dhyana. Join us and transcend yourself to attain enlightenment.'
                },
                {
                    vmid: 'og-image',
                    name: 'og:image',
                    content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683471532/Dr._Manisha_About_Page_Image_gmqvu6.png'
                },
                {
                    vmid: 'og-image-type',
                    property: 'og:image:type',
                    content: 'image/png'
                },
                {
                    vmid: 'og-image-width',
                    property: 'og:image:width',
                    content: '1200'
                },
                {
                    vmid: 'og-image-height',
                    property: 'og:image:height',
                    content: '630'
                },
                {
                    vmid: 'og-image-alt',
                    property: 'og:image:alt',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'og-url',
                    property: 'og:url',
                    content: 'https://drmanishasyogainstitute.com'
                },
                {
                    vmid: 'og-type',
                    property: 'og:type',
                    content: 'health care website'
                },
                {
                    vmid: 'og-site-name',
                    property: 'og:site_name',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-card',
                    name: 'twitter:card',
                    content: 'summary_large_image'
                },
                {
                    vmid: 'twitter-title',
                    name: 'twitter:title',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-description',
                    name: 'twitter:description',
                    content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
                },
                {
                    name: 'og:site_name',
                    content: "Dr. Manisha's Yoga Institute"
                },
                {
                    vmid: 'twitter-image',
                    name: 'twitter:image',
                    content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683471532/Dr._Manisha_About_Page_Image_gmqvu6.png'
                },
                {
                    vmid: 'twitter-image-alt',
                    name: 'twitter:image:alt',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-site',
                    name: 'twitter:site',
                    content: '@DrManishasYoga'
                },
                {
                    vmid: 'twitter-creator',
                    name: 'twitter:creator',
                    content: '@DrManishasYoga'
                },
                {
                    vmid: 'twitter-url',
                    name: 'twitter:url',
                    content: 'https://drmanishasyogainstitute.com'
                },
                {
                    vmid: 'twitter-domain',
                    name: 'twitter:domain',
                    content: 'drmanishasyogainstitute.com'
                },
            ]
        }
    }
}

</script>