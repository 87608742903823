<template>
    <div class="mt-24">
        <div class="items-center justify-center text-center">
            <h2 class="text-6xl font-medium text-main-text mb-5">Our Services</h2>
            <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />
            <p class="text-xl p-10 font-light text-main-text dark:text-gray-400">
                We offer a wide range of yoga classes for all levels of experience. Our classes are designed to help you
                achieve your goals, whether you are looking to improve your health, reduce stress, or simply relax.
            </p>
        </div>

        <div class="max-w-full border-b border-gray-200 dark:border-gray-700">
            <ul class="flex flex-wrap justify-center items-center text-lg font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li v-for="tab in tabElements" :key="tab.id" class="my-2 md:my-0 md:mr-8" role="presentation">
                <button class="inline-block px-4 py-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-main-orange dark:hover:text-gray-300" :id="`${tab.id}-tab`" type="button" :aria-controls="tab.id" :aria-selected="tab.id === options.defaultTabId ? 'true' : 'false'" @click="activateTab(tab)">
                  {{ tab.label }}
                </button>
              </li>
            </ul>
          </div>
          

        <div id="myTabContent">
            <div v-for="(tab, index) in tabElements" :key="tab.id" :id="tab.id"
                class="hidden p-10 bg-main-bg dark:bg-gray-800" :role="'tabpanel'"
                :aria-labelledby="`${tab.id}-tab`">

                <section class="bg-white rounded-2xl dark:bg-gray-900">
                    <div
                        class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">

                        <div
                            :class="{ 'grid grid-cols-2 gap-4 mt-8 lg:mt-0': true, 'lg:col-start-2': index % 2 == 0, 'lg:col-start-1': index % 2 == 1 }">
                            <img class="w-full rounded-lg" :src="tab.img1" :alt="tab.label">
                            <img class="mt-4 w-full lg:mt-10 rounded-lg" :src="tab.img2" :alt="tab.label">
                        </div>

                        <div :class="{'text-justify': true,  'lg:-mt-96 sm:adjustHeight  lg:text-justify sm:mt-24': index % 2 == 0, 'lg:text-justify': index % 2 == 1 }"
                            class="  font-light text-gray-500 sm:text-lg dark:text-gray-400">
                            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
                                {{ tab.label }}</h2>
                            <p class="mb-4">{{ tab.content }}</p>
                        </div>

                    </div>
                </section>

            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: "MainService",
    data() {
        return {
            tabElements: [
                {
                    id: "traditional-yoga",
                    img1: 'https://images.pexels.com/photos/4723106/pexels-photo-4723106.jpeg?auto=compress&cs=tinysrgb&w=600',
                    img2: 'https://images.pexels.com/photos/8711163/pexels-photo-8711163.jpeg?auto=compress&cs=tinysrgb&w=600',
                    label: "Traditional Yoga",
                    content: 'It is an old system that includes most yoga styles (Hatha Yoga), the practice of asanas (yoga postures) and pranayama (breathing exercises), which help bring peace to the mind and body, preparing the body for deeper spiritual practices such as meditation. It’s a way to discover the sense of oneness with ourselves and nature.',
                },
                {
                    id: "meditation-pranayam",
                    img1: 'https://images.pexels.com/photos/8846108/pexels-photo-8846108.jpeg?auto=compress&cs=tinysrgb&w=600',
                    img2: 'https://images.pexels.com/photos/7992184/pexels-photo-7992184.jpeg?auto=compress&cs=tinysrgb&w=600',
                    label: "Weight Loss",
                    content: 'Meditation is a powerful tool for spiritual growth, and is essentially a process to take one beyond the limitations of body and mind. Meditation, an ancient kriya, helps to stable the mind and to experience higher stages of consciousness. The practitioners shall experience greater emotional balance, concentration, focus, stability and better health with regular practice of the meditation.  It helps in better functioning of lungs, heart, kidneys. All body organs get more oxygen. Toxins are removed from body by its regular practice. ',
                },
                {
                    id: "abs-yoga",
                    img1: 'https://images.pexels.com/photos/6740747/pexels-photo-6740747.jpeg?auto=compress&cs=tinysrgb&w=600',
                    img2: 'https://images.pexels.com/photos/14175839/pexels-photo-14175839.jpeg?auto=compress&cs=tinysrgb&w=600',
                    label: "Abs Yoga",
                    content: 'Abs yoga is a practice that targets the core muscles of the body, which includes the abs, obliques, and lower back. This practice not only helps to tone and strengthen these muscles, but it also has many other benefits. Abs yoga can improve posture, reduce back pain, and increase flexibility and balance. By engaging the core muscles, abs yoga also helps to support the spine and can reduce the risk of injury during physical activities. ',
                },
                {
                    id: "weight-loss",
                    img1: 'https://images.pexels.com/photos/8613321/pexels-photo-8613321.jpeg?auto=compress&cs=tinysrgb&w=600',
                    img2: 'https://images.pexels.com/photos/6951791/pexels-photo-6951791.jpeg?auto=compress&cs=tinysrgb&w=600',
                    label: "Yoga for Kids",
                    content: 'Kids’ Yoga offers a unique possibility for every child to experience a joyful blossoming of their natural potential. Yoga celebrates the natural gifts within every child including wonder and a sense of oneness with life. Kids’ Yoga consists of an introduction to yoga practices including a few simple life enhancing asanas and kriyas. The content is presented through fun games and plays, so that children experience a sense of belonging and unity with life and live in optimal health and inner peace. Kids’ yoga enhances concentration and memory, more focus, and improved mind-body coordination.',
                },
            ],
            options: {
                defaultTabId: "traditional-yoga",
            },
        };
    },
    methods: {
        activateTab(tab) {
            this.tabElements.forEach((t) => {
                const isActive = t.id === tab.id;
                t.triggerEl.classList.toggle("border-main-red", isActive);
                t.triggerEl.classList.toggle("bg-main-bg", isActive);
                t.triggerEl.classList.toggle("text-main-text", isActive);
                t.triggerEl.classList.toggle("hover:text-gray-600", !isActive);
                t.triggerEl.classList.toggle("hover:border-main-red", !isActive);
                t.contentEl.classList.toggle("hidden", !isActive);
            });
        }
    },
    mounted() {
        this.tabElements.forEach((tab) => {
            tab.triggerEl = document.querySelector(`#${tab.id}-tab`);
            tab.contentEl = document.querySelector(`#${tab.id}`);
        });
        this.activateTab(this.tabElements.find((t) => t.id === this.options.defaultTabId));
    },
};
</script>

<style scoped> 
.adjustHeight{
    margin-top: -100%;
}

</style>