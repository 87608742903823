<template>
    <div>
        <Sidebar />
        <div class="relative md:ml-64 bg-blueGray-100">
            <AdminNavbar />
            <div class="px-4 md:px-10 mx-auto w-full -m-24">
                <router-view />
                <AdminFooter />
            </div>
        </div>

    </div>
</template>

<script>
import Sidebar from "../components/Sidebar/AdminSidebar.vue";
import AdminNavbar from "../components/Navbar/AdminNavbar.vue";
import AdminFooter from "../components/Footer/AdminFooter.vue";

export default {
    name: "dashboard-page",
    components: {
        Sidebar,
        AdminNavbar,
        AdminFooter
    },
    metaInfo() {
        return {
            title: "Dr. Manisha's Yoga Institute"
        };
    
    }
}

</script>