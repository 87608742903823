<template>
    <div class="flex mt-32 flex-wrap ">
        <div class="w-full border-black border-2 rounded-lg xl:w-8/12 mb-12 xl:mb-0 px-4">
            <h2 class="text-3xl font-bold mb-4">Data</h2>
            <p class="text-lg leading-7 mb-6">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla interdum sapien in magna lacinia, ac faucibus
                magna lacinia. Vestibulum sed justo ultrices, eleifend quam eget, tempus tortor. Suspendisse potenti. In
                dapibus sapien eu libero fermentum, vel elementum augue commodo. Donec fringilla mauris mauris, quis
                suscipit nisi commodo vel. Fusce ullamcorper leo vitae lectus auctor iaculis.
            </p>
        </div>
        <div class="w-full border-black border-2 rounded-lg xl:w-4/12 px-4">
            <h3 class="text-xl font-bold mb-4">Data</h3>
            <ul class="list-disc pl-6 mb-6">
                <li>Lorem ipsum dolor sit amet</li>
                <li>Consectetur adipiscing elit</li>
                <li>Nulla interdum sapien in magna lacinia</li>
                <li>Vestibulum sed justo ultrices, eleifend quam eget, tempus tortor</li>
            </ul>
            <p class="text-lg leading-7 mb-6">
                Fusce ullamcorper leo vitae lectus auctor iaculis. Donec placerat, purus eget feugiat viverra, tortor nisi
                ullamcorper nibh, a posuere justo turpis in lectus. Vivamus tristique, sapien sit amet efficitur viverra,
                velit mauris consectetur erat, vel tincidunt eros elit vel magna.
            </p>
        </div>
    </div>
</template>
<script>
export default {
    name: "dashboard-page",
    components: {
    },
};
</script>
  