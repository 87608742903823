<template>
    <div>
        <UserSidebar />
        <div class="relative md:ml-64 bg-blueGray-100">
            <UserNavbar />
            <div class="px-4 md:px-10 mx-auto w-full -m-24">
                <router-view />
                <UserFooter />
            </div>
        </div>
    </div>
</template>

<script>
import UserSidebar from "../components/Sidebar/UserSidebar";
import UserNavbar from "../components/Navbar/UserNavbar";
import UserFooter from "../components/Footer/UserFooter";
export default {
    name: "user-dashboard-page",
    components: {
        UserSidebar,
        UserNavbar,
        UserFooter
    },
}
</script>