<template>
    <div>
        <div class="
                        justify-center
                        bg-white
                        shadow-md
                        border-b-main-text
                        dark:bg-gray-900
                        text-main-text
                        dark:text-white
                        max-w-md
                        p-6
                        mx-auto
                        rounded-lg
                        overflow-hidden
                        md:max-w-2xl
                        mb-12
                        mt-6
                    ">
            <h1 class="
                            text-6xl
                            font-semibold
                            text-center
                            mb-4
                            dark:text-white 
                    ">Enrollment Form</h1>
            <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />

            <img class="w-full h-full rounded-lg object-cover"
                src="https://res.cloudinary.com/doqozlqzp/image/upload/v1687500716/My-project-2022-01-31T164733.158-e1650424348561_sesqn3.jpg"
                alt="Dr. Manisha's Yoga Institute">
            <form @submit.prevent="handleSubmit" method="POST" class="mx-auto mt-10  space-y-8">
                <div>
                    <div class="relative">
                        <input v-model="name" type="text" name="name" id="name"
                            class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-main-text appearance-none dark:text-white dark:border-main-text dark:focus:border-main-text focus:outline-none focus:ring-0 focus:border-main-text peer"
                            placeholder=" " required />
                        <label for="name"
                            class="absolute text-sm text-main-text dark:text-main-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter
                            Name</label>
                        <span v-if="errors.name" class="text-sm text-red-500">{{ errors.name }}</span>
                    </div>
                </div>
                <div>
                    <div class="relative">
                        <input v-model="email" type="email" name="email" id="email"
                            class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-main-text appearance-none dark:text-white dark:border-main-text dark:focus:border-main-text focus:outline-none focus:ring-0 focus:border-main-text peer"
                            placeholder=" " required />
                        <label for="email"
                            class="absolute text-sm text-main-text dark:text-main-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter
                            Email</label>
                        <span v-if="errors.email" class="text-sm text-red-500">{{ errors.email }}</span>
                    </div>
                </div>
                <div>
                    <div class="relative">
                        <input v-model="contact" type="tel" name="contact" id="contact"
                            class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-main-text appearance-none dark:text-white dark:border-main-text dark:focus:border-main-text focus:outline-none focus:ring-0 focus:border-main-text peer"
                            placeholder=" " required />
                        <label for="contact"
                            class="absolute text-sm text-main-text dark:text-main-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter
                            Contact Number</label>
                        <span v-if="errors.contact" class="text-sm text-red-500">{{ errors.contact }}</span>
                    </div>
                </div>
                <div>
                    <div class="relative">
                        <label class="block text-sm font-medium text-gray-700">
                            Session Type
                        </label>
                        <div class="mt-1">
                            <select v-model="sessionType" required
                                class="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-main-blue focus:border-main-blue sm:text-sm">
                                <option value="" disabled selected>Select Session Type</option>
                                <option value="Online">Online</option>
                                <option value="Offline">Offline</option>
                            </select>
                        </div>
                        <p v-if="errors.sessionType" class="mt-2 text-sm text-red-600" id="email-error">{{
                            errors.sessionType }}
                        </p>
                    </div>
                </div>
                <div>
                    <div class="relative">
                        <label class="block text-sm font-medium text-gray-700">
                            Message
                        </label>
                        <div class="mt-1">
                            <textarea v-model="message" required
                                class="block w-full px-3 py-2 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-main-blue focus:border-main-blue sm:text-sm"
                                rows="3"></textarea>
                        </div>
                        <p v-if="errors.message" class="mt-2 text-sm text-red-600" id="email-error">{{ errors.message }}
                        </p>
                    </div>
                </div>
                <div>
                    <button type="submit"
                        class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-main-orange hover:bg-main-blue-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-blue">
                        Enroll Now
                    </button>
                </div>

            </form>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import Swal from 'sweetalert2';
import { API_URL } from '@/config';

export default {
    name: "EnrollNow",
    data() {
        return {
            name: '',
            email: '',
            contact: '',
            sessionType: '',
            message: '',
            errors: {},
        };
    },
    methods: {
        async submitForm() {
            try {
                // Show "Submitting" message using SweetAlert2
                await Swal.fire({
                    title: 'Submitting',
                    text: 'Please wait while your form is being submitted...',
                    allowOutsideClick: false,
                    onBeforeOpen: () => {
                        Swal.showLoading();
                    }
                });

                await axios.post('https://formspree.io/f/xknlwwpv', {
                    name: this.name,
                    email: this.email,
                    contact: this.contact,
                    sessionType: this.sessionType,
                    message: this.message,
                });
                
                // Save form data to MongoDB
                await axios.post(`${API_URL}/api/enrollnowform`, {
                    name: this.name,
                    email: this.email,
                    contact: this.contact,
                    sessionType: this.sessionType,
                    message: this.message,
                });
                
                this.formSubmitted = true;

                // Close the "Submitting" message
                Swal.close();

                await Swal.fire({
                    icon: 'success',
                    title: 'Enrolled Successfully',
                    text: `Thank you ${this.name}, for your interest. We will contact you shortly.`,
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // Reset form fields
                        this.name = '';
                        this.email = '';
                        this.contact = '';
                        this.sessionType = '';
                        this.message = '';
                        this.errors = {};
                    }
                });
            } catch (error) {
                console.log(error);
                // Close the "Submitting" message
                Swal.close();
                
                // Show error message using SweetAlert2
                await Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'There was an error submitting your Enrollment form. Please try again later.',
                });
            }
        },

        handleSubmit(){
            this.errors = {};
            if (this.name === '') {
                this.errors.name = 'Name is required';
            }
            if (this.email === '') {
                this.errors.email = 'Email is required';
            }
            if (this.message === '') {
                this.errors.message = 'Message is required';
            }
            if (this.contact === '') {
                this.errors.contact = 'Contact is required';
            }
            if (this.sessionType === '') {
                this.errors.sessionType = 'Session Type is required';
            }
            if (Object.keys(this.errors).length === 0) {
                this.submitForm();
                this.messageSent = true;
            }
        }
    },
}
</script>