<template>
    <div>

        <header>
            <div class="">
                <nav class="flex items-center justify-between h-24  shadow-md border-b bg-main-bg border-gray-200 fixed w-full z-20 top-0 left-0 dark:border-gray-600 dark:bg-gray-900">
                    <router-link to="/" class="flex items-center">
                        <img src="@/assets/images/logo.png" class="logo-img h-24 ml-3 sm:h-24" alt="Dr. Manisha's
                                        Yoga Institute" />
                        <div class="flex flex-col">
                            <span class="text-2xl font-bold text-gray-800">Dr. Manisha's Yoga Institute</span>
                        </div>
                    </router-link>

                    <ul class="nav_menu" :class="{ active: menuActive }">
                        <li class="nav_list">
                            <router-link to="/"
                                class="nav_link block py-4 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-main-orange md:p-0 dark:text-gray-400 md:dark:hover:text-main-orange dark:hover:bg-gray-700 dark:hover:text-main-orange md:dark:hover:bg-transparent dark:border-gray-700">
                                <span>Home</span>
                            </router-link>
                        </li>
                        <li class="nav_list ">
                            <router-link to="/about"
                                class="nav_link block py-4 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-main-orange md:p-0 dark:text-gray-400 md:dark:hover:text-main-orange dark:hover:bg-gray-700 dark:hover:text-main-orange md:dark:hover:bg-transparent dark:border-gray-700">
                                <span>About</span>
                            </router-link>
                        </li>
                        <li class="nav_list nav_list_menu">
                            <a class="nav_link ">
                                <span>Services</span>
                                <span class="mt-1">
                                    <i class="fa fa-chevron-down"></i>
                                </span>
                            </a>
                            <div class="dropdown">
                                <div class="dropdown-inner">
                                    <div class="dropdown-item">
                                        <div class="item-list">

                                            <ul class="mt-0 space-y-4 md:mb-0 md:block" >
                                                <li>
                                                    <router-link   to="/services/traditional-yoga"
                                                        class=" hover:text-main-orange dark:hover:text-main-orange">
                                                        Traditional Yoga
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link   to="/services/meditation-pranayam"
                                                        class=" hover:text-main-orange dark:hover:text-main-orange">
                                                        Meditation & Pranayam
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link   to="/services/healing-therapy"
                                                        class=" hover:text-main-orange dark:hover:text-main-orange">
                                                        Healing Therapy
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>

                                    <div class="dropdown-item">
                                        <div class="item-list">
                                            <ul class="mt-0 space-y-4 md:mb-0 md:block" >
                                                <li>
                                                    <router-link   to="/services/yoga-for-allround-fitness"
                                                        class=" hover:text-main-orange dark:hover:text-main-orange">
                                                        Yoga for allround Fitness
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link   to="/services/yoga-for-kids"
                                                        class=" hover:text-main-orange dark:hover:text-main-orange">
                                                        Yoga for Kids
                                                    </router-link>
                                                </li>
                                                <li>
                                                    <router-link   to="/services/yoga-for-weight-loss"
                                                        class=" hover:text-main-orange dark:hover:text-main-orange">
                                                        Yoga for Weight Loss
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="dropdown-item">
                                        <div class="item-list">
                                            <ul class="mt-0 space-y-4 md:mb-0 md:block" >
                                                <li>
                                                    <router-link   to="/services/laughter-yoga"
                                                        class=" hover:text-main-orange dark:hover:text-main-orange">
                                                        Laughter Yoga
                                                    </router-link>
                                                </li>
                        
                                                <li>
                                                    <router-link   to="/services/dance-exercise"
                                                        class=" hover:text-main-orange dark:hover:text-main-orange">
                                                        Dance Exercise
                                                    </router-link>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div class="dropdown-item">
                                        <div class="item-list">
                                            <router-link to="/special/services/disease-wise-yoga"
                                                class="p-8 text-left bg-local bg-gray-500 bg-center bg-no-repeat bg-cover rounded-lg bg-blend-multiply hover:bg-blend-soft-light dark:hover:bg-blend-darken"
                                                style="background-image: url(https://images.unsplash.com/photo-1544367567-0f2fcb009e0b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8Mnx8fGVufDB8fHx8&w=1000&q=80)">
                                                <p class="max-w-xl mb-5 font-extrabold leading-tight tracking-tight text-white">
                                                    Checkout Our Special Service
                                                </p>
                                                <button type="button"
                                                    class="inline-flex items-center px-2.5 py-1.5 text-xs font-medium text-center text-white border border-white rounded-lg hover:bg-white hover:text-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-700">
                                                    Get started
                                                    <svg class="w-4 h-4 ml-1 -mr-1" fill="currentColor" viewBox="0 0 20 20"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                                            clip-rule="evenodd"></path>
                                                    </svg>
                                                </button>
                                            </router-link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="nav_list">
                            <router-link to="/courses"
                                class="nav_link block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-main-orange md:p-0 dark:text-gray-400 md:dark:hover:text-main-orange dark:hover:bg-gray-700 dark:hover:text-main-orange md:dark:hover:bg-transparent dark:border-gray-700">
                                <span>Courses</span>
                            </router-link>
                        </li>
                        <li class="nav_list">
                            <router-link to="/blogs"
                                class="nav_link block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-main-orange md:p-0 dark:text-gray-400 md:dark:hover:text-main-orange dark:hover:bg-gray-700 dark:hover:text-main-orange md:dark:hover:bg-transparent dark:border-gray-700">
                                <span>Blog</span>
                            </router-link>
                        </li>
                        <li  class="nav_list">
                            <router-link to="/gallery"
                                class="nav_link block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-main-orange md:p-0 dark:text-gray-400 md:dark:hover:text-main-orange dark:hover:bg-gray-700 dark:hover:text-main-orange md:dark:hover:bg-transparent dark:border-gray-700">
                                <span>Gallery</span>
                            </router-link>
                        </li>
                        <li  class="nav_list">
                            <router-link to="/products"
                                class="nav_link block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-main-orange md:p-0 dark:text-gray-400 md:dark:hover:text-main-orange dark:hover:bg-gray-700 dark:hover:text-main-orange md:dark:hover:bg-transparent dark:border-gray-700">
                                <span>Products</span>
                            </router-link>
                        </li>
                        <li  class="nav_list">
                            <router-link to="/contact"
                                class="nav_link block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-main-orange md:p-0 dark:text-gray-400 md:dark:hover:text-main-orange dark:hover:bg-gray-700 dark:hover:text-main-orange md:dark:hover:bg-transparent dark:border-gray-700">
                                <span>Contact</span>
                            </router-link>
                        </li>
                    </ul>
                    <div class="nav_action">
                        <div class="nav_toggle" @click="toggleMenu">
                            <i :class="[menuIcon, 'fa', 'text-lg', 'sm:text-xl', 'md:text-2xl', 'lg:text-3xl']"></i>
                        </div>
                    </div>
                </nav>
            </div>
        </header>

    </div>
</template>

<script>

export default {
    name: 'MainHeader',
    data() {
        return {
            menuActive: false
        }
    },
    computed: {
        menuIcon() {
            return this.menuActive ? 'fa-times' : 'fa-bars';
        }
    },
    methods: {
        toggleMenu() {
            this.menuActive = !this.menuActive;
        }
    }


}
</script>

<style scoped>
.navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 7rem;
    position: relative;
    background: #FEE4E5;
    z-index: 999;
    box-shadow:
        0 0.5rem 1rem rgba(0, 0, 0, 0.05),
    ;
}

.nav_list {
    display: inline-flex;
    gap: 25px;
    align-items: center;
    margin: 0 1.5rem;
}

.nav_menu {
    display: flex;
    align-items: right;
    justify-content: right;
    gap: 1rem;
    margin: 0 1.5rem;
}

.nav_action {
    display: flex;
    align-items: right;
    gap: 1rem;
    margin: 0 1.5rem;
}

.nav_link {
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-weight: 500;
}


.nav_toggle {
    cursor: pointer;
    display: none;
}

.nav_toggle ion-icon {
    font-size: 3.5rem;
}

.dropdown {
    position: absolute;
    top: 5rem;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0rem 0.2rem 0.5rem rgba(32, 7, 65, 0.14);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: all 0.5s ease-in;
}

.dropdown-inner {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: 1rem;
    padding: 2rem;
}

.nav_list_menu:hover ion-icon {
    transition: all 0.5s ease-in;
    transform: rotate(180deg);
}

.nav_list_menu:hover .dropdown {
    clip-path: polygon(0 0, 100% 0, 100% 102%, 0 102%);
}

.item-list {
    display: flex;
    align-items: center;
    gap: 1rem;
}



@media (max-width:1325px) {
    .nav_toggle {
        display: block;
    }

    .nav_menu {
        position: absolute;
        top: 6.5rem;
        left: 0;
        width: 90%;
        background: #fff;
        box-shadow: 0rem 0.2rem 0.5rem rgba(32, 7, 65, 0.14);
        display: none;
    }

    .nav_menu.active {
        display: block;
    }

    .nav_list {
        display: block;
        margin: 2rem 0;
        text-align: center;
    }

    .nav_link {
        padding: 0 2rem;
        display: flex;
        justify-content: space-between;
    }


    .dropdown {
        top: 0;
        position: relative;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        box-shadow: none;
        height: 0;
        text-align: start;
        transition: all 0.5s ease-in;
    }

    .nav_list_menu:hover .dropdown {
        height: 100%;
        transition: all 0.5s ease-in;
    }
}

@media (max-width:365px) {
    .logo-img {
        width: 10rem;
    }
}
</style>