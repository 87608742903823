<template>
    <div class="">
      <div class="items-center justify-center text-center">
        <h2 class="text-6xl font-semibold text-main-text mb-5">Our Speciality</h2>
        <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />
    </div>
      <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div class="max-w-6xl mx-auto">
          <div class="relative h-96 rounded-lg overflow-hidden">
            <img class="absolute h-full w-full object-cover" src="@/assets/images/diseasewise9.jpeg" alt="Dr. Manisha's Yoga Institute">
            <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30"></div>
            <div class="relative h-full flex items-center justify-center p-8">
              <div class="text-center">
                <h1 class="text-4xl font-bold text-white uppercase mb-4">{{ services.title }}</h1>
                <p class="text-white text-lg mb-8">"Disease Wise Yoga" is a personalized approach to managing common health conditions through the integration of yoga practices that are designed to suit individual needs and improve overall well-being.</p>
              </div>
            </div>
          </div>
          <div class="relative mt-8  text-justify">
            <article class="format-sm sm:format-base text-justify lg:format-lg format-blue">
              <h2 class="text-5xl font-medium">What is Disease Wise Yoga?</h2>
              <p class="mt-4 lead leading-10">{{ services.paragraph1 }}</p>
              <p class="mt-6  text-lg leading-8">{{ services.paragraph2 }}</p>

              <ImageSlider/>

              <p class="mt-6  text-lg leading-8">{{ services.paragraph3 }}</p>
              <p class="mt-6  text-lg leading-8">{{ services.paragraph4 }}</p>
              <p class="mt-6  text-lg leading-8">{{ services.paragraph5 }}</p>
  
  
  
            </article>
            <!-- <div class="mt-8 ">
              <h2 class="text-2xl font-bold">Related Services</h2>
              <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                <div v-for="service in relatedServices" :key="service.title" class="bg-white rounded-lg shadow-md">
                  <div class="overflow-hidden rounded-t-lg">
                    <img :src="service.image" alt="Dr. Manisha's Yoga Institute" class="w-full h-48 object-cover">
                  </div>
                  <div class="p-6">
                    <h3 class="text-lg font-bold mb-4">{{ service.title }}</h3>
                    <p class="text-gray-700">{{ service.description }}</p>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
//   import { API_URL } from '@/config'
import ImageSlider from '../ImageSlider/ImageSlider.vue';

// console.log(API_URL);

  export default {
    name: "DiseaseWiseYoga",
    metaInfo() {
        return {
            title: "Tailor made Yoga for Diseases | Dr. Manisha's Yoga Institute",
            meta: [
                { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                { vmid: 'title', name: 'title', content: "Tailor made Yoga for Diseases | Dr. Manisha's Yoga Institute" },
                
                {
                  vmid: 'description',
                  name: 'description',
                  content: "Yoga is a spiritual science that aims at the holistic development of our physical, mental, and moral-spiritual aspects of being. Tailor-made Yoga is a specific branch of yoga that focuses on the yogic management of common diseases."
                },
                {
                  vmid: 'keywords',
                  name: 'keywords',
                  content: "Dr. Manisha's Yoga Institute,Dr. Manisha's Yoga Institute special Services,Special services, Disease management, Yoga therapy, Health and wellness, Physical therapy, Rehabilitation, Mind-body connection, Chronic illness, Stress management, Pain management, Alternative medicine, Holistic approach, Mental health, Respiratory diseases, Cardiovascular diseases, Neurological disorders, Musculoskeletal conditions, Cancer care, Digestive disorders, Autoimmune diseases, Endocrine disorders, Geriatric care, Pediatric care, Women's health, Men's health"
                },
            ]
        };
    },
    data() {
        return {
            services: {},
            relatedServices: [
                {
                    title: "Service 2",
                    image: "https://img.freepik.com/free-photo/sporty-young-woman-meditating-mountains_651396-2676.jpg?w=1380&t=st=1679465317~exp=1679465917~hmac=2557024e181ed9ed288281102ac6d15ac18eb78146262ed0ed68dfa7409e56a0",
                    description: "This is a description of Service 2."
                },
                {
                    title: "Service 3",
                    image: "https://img.freepik.com/free-photo/sporty-young-woman-meditating-mountains_651396-2676.jpg?w=1380&t=st=1679465317~exp=1679465917~hmac=2557024e181ed9ed288281102ac6d15ac18eb78146262ed0ed68dfa7409e56a0",
                    description: "This is a description of Service 3."
                },
                {
                    title: "Service 4",
                    image: "https://img.freepik.com/free-photo/sporty-young-woman-meditating-mountains_651396-2676.jpg?w=1380&t=st=1679465317~exp=1679465917~hmac=2557024e181ed9ed288281102ac6d15ac18eb78146262ed0ed68dfa7409e56a0",
                    description: "This is a description of Service 4."
                }
            ]
        };
    },
    async mounted() {
        const _id = "7";
        const response = await axios.get(`https://yogaservices-cc925-default-rtdb.asia-southeast1.firebasedatabase.app/services/${_id}.json`);
        this.services = response.data;
        // console.log(this.services);
    },
    components: { ImageSlider }
}
  </script>
  