<template>
    <div>
        <Layout>
            <template v-slot:default>
                <DiseaseWiseYoga class="mt-32"/>
            </template>
        </Layout>
    </div>
</template>

<script>
import Layout from '../Layout/Layout.vue';
import DiseaseWiseYoga from '../components/SpecialService/DiseaseWiseYoga/DiseaseWiseYoga.vue';
export default {
    name: 'ServicesPage',
    components: {
        Layout,
        DiseaseWiseYoga
    }
}
</script>