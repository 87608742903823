<template>
    <div>
        <Layout>
            <template v-slot:default>
                <MainHero class="mt-32"/>
                <MainAbout />
                <WhyChoose />
                <MainServices />
                <!-- <MainPricing /> -->
                <MainCourses /> 
                <MainTestimonial />
                <MainBlog />
                <!-- <FreeDemo /> -->
            </template>

        </Layout>

    </div>
</template>

<script>
import Layout from '../Layout/Layout.vue';
import MainAbout from '../components/LandingPage/About/MainAbout.vue';
import MainHero from '../components/LandingPage/Hero/MainHero.vue';
// import MainStats from '../components/LandingPage/Stats/MainStats.vue';
import WhyChoose from '../components/LandingPage/WhyChoose/WhyChoose.vue';
// import FreeDemo from '../components/LandingPage/Demo/FreeDemo.vue';
import MainServices from '../components/LandingPage/Services/MainServices.vue';
import MainBlog from '../components/LandingPage/Blogs/MainBlog.vue';
import MainCourses from '../components/LandingPage/Courses/MainCourses.vue';
import MainTestimonial from '../components/LandingPage/Testimonials/MainTestimonial.vue';
// import MainPricing from '../components/LandingPage/Pricing/MainPricing.vue';
export default {
    name: 'HomePage',
    
    metaInfo(){
        return {
            title:"Dr. Manisha's Yoga Institute",
            meta :[
                { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                { vmid: 'title', name: 'title', content: 'Dr. Manisha\'s Yoga Institute' },
                { vmid: 'description', name: 'description', content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...' },
                {
                    vmid: 'keywords',
                    name: 'keywords',
                    content: 'Dr. Manisha\'s Yoga Institute, Yoga education, Free Demo, Free Yoga Session,  Yogic Science,Yoga for sleep, Yoga for anxiety,Yoga for immunity,Yoga for heart health,Yoga for respiratory health,Yoga for menstrual health,Yoga for cancer patients, Cultural Synthesis,Yoga retreats for beginners, Yoga teacher training programs, Yoga for stress relief, Yoga for weight loss, Yoga for back pain, Yoga for flexibility, Yoga for mental health, Yoga for seniors, Yoga for athletes, Yoga for pregnancy, yoga postures, pranayama, meditation, healthy mind, healthy body, enlightenment, yoga retreats, yoga workshops, yoga philosophy, mindfulness, fitness, stress relief, holistic healing, yoga courses,oga, yoga institute, yoga teacher training, yoga teacher training in pune, yoga teacher training in india, yoga teacher training in maharashtra, yoga teacher training in thane'
                },
                {
                    vmid: 'og-title',
                    property: 'og:title',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'og-description',
                    property: 'og:description',
                    content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
                },
                {
                    vmid: 'og-image',
                    property: 'og:image',
                    content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683462908/main_kdohfk.png'
                },
                {
                    vmid: 'og-image-type',
                    property: 'og:image:type',
                    content: 'image/png'
                },
                {
                    vmid: 'og-image-width',
                    property: 'og:image:width',
                    content: '1200'
                },
                {
                    vmid: 'og-image-height',
                    property: 'og:image:height',
                    content: '630'
                },
                {
                    vmid: 'og-image-alt',
                    property: 'og:image:alt',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'og-url',
                    property: 'og:url',
                    content: 'https://drmanishasyogainstitute.com'
                },
                {
                    vmid: 'og-type',
                    property: 'og:type',
                    content: 'health care website'
                },
                {
                    vmid: 'og-site-name',
                    property: 'og:site_name',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-card',
                    name: 'twitter:card',
                    content: 'summary_large_image'
                },
                {
                    vmid: 'twitter-title',
                    name: 'twitter:title',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-description',
                    name: 'twitter:description',
                    content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
                },
                {
                    vmid: 'twitter-image',
                    name: 'twitter:image',
                    content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683462908/main_kdohfk.png'
                },
                {
                    vmid: 'twitter-image-alt',
                    name: 'twitter:image:alt',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-site',
                    name: 'twitter:site',
                    content: '@DrManishasYoga'
                },
                {
                    vmid: 'twitter-creator',
                    name: 'twitter:creator',
                    content: '@DrManishasYoga'
                },
                {
                    vmid: 'twitter-url',
                    name: 'twitter:url',
                    content: 'https://drmanishasyogainstitute.com'
                },
                {
                    vmid: 'twitter-domain',
                    name: 'twitter:domain',
                    content: 'drmanishasyogainstitute.com'
                },
            ]
        }
    },
    components: {
    Layout,
    MainHero,
    // MainStats,
    MainAbout,
    WhyChoose,
    // FreeDemo,
    MainServices,
    MainBlog,
    MainCourses,
    MainTestimonial,
},
}

</script>