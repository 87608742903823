<template>
    <div>
        <section id="about" class="about section-bg bg-gray-900 bg-skew-3 py-16">
            <div class="relative z-10 mx-auto">

                <div class="flex flex-col lg:flex-row">
                    <div class="flex items-center justify-center lg:w-1/2 sm:-ml-4 p-6">
                        <div class="mt-5  text-justify lg:text-justify  py-30 px-0 lg:px-28 text-main-orange">

                            <h2 class="font-medium text-5xl mb-8 ">Dr. Manisha's Yoga Institute</h2>
                            <p class="text-gray-300 mb-7 leading-relaxed">
                                Welcome to our healing center, where we offer a variety of therapies under one roof, including Ayurveda, yoga, meditation & pranayama, nutrition, chiropractic care, acupuncture, massage, and more. Our experienced practitioners collaborate to create personalized treatment plans tailored to your unique needs and goals. Whether you are seeking to relieve pain, manage stress, or improve your overall well-being, our holistic approach can help you achieve optimal health. Visit us today and experience the transformative power of these healing therapies!
                            </p>
                            <router-link to="/about"
                                class="about-btn inline-block mt-4 px-6 py-3 border-2 border-main-orange rounded-full text-main-orange hover:bg-main-orange hover:text-gray-900 transition duration-300 ease-in-out"><span>About
                                    us</span>
                                <Icon icon="bx:chevron-right" class="text-2xl" />
                            </router-link>
                        </div>
                    </div>
                    <div class="flex flex-col text-justify  lg:w-1/2 p-6">
                        <div class="grid  grid-cols-1 gap-6 sm:grid-cols-2">
                            <div class="p-6">
                                <Icon icon="bx:leaf" class="text-4xl text-main-orange mb-4" />
                                <h4 class="text-xl text-main-orange  mb-2">Ayurveda</h4>
                                <p class="text-gray-300 leading-relaxed">Ayurveda is an ancient system of natural healing that offers personalized, holistic care for the body, mind, and spirit, restoring balance and promoting optimal health and well-being.</p>
                            </div>
                            <div class="p-6">
                                <Icon icon="bx:heart-circle" class="text-4xl text-main-orange mb-4" />
                                <h4 class="text-xl text-main-orange  mb-2">Yoga</h4>
                                <p class="text-gray-300 leading-relaxed">Yoga is a transformative practice that combines physical movement, breathwork, and mindfulness to promote holistic health, inner peace, and spiritual growth.</p>
                            </div>
                            <div class="p-6">
                                <Icon icon="bx:radio-circle-marked" class="text-4xl text-main-orange mb-4" />
                                <h4 class="text-xl text-main-orange  mb-2">Meditation &amp; Pranayama</h4>
                                <p class="text-gray-300 leading-relaxed">Meditation and pranayama are powerful techniques that cultivate inner stillness, deepen self-awareness, and promote physical and mental well-being, leading to a more harmonious and fulfilling life.</p>
                            </div>
                            <div class="p-6">
                                <Icon icon="bx:shield" class="text-4xl text-main-orange mb-4" />
                                <h4 class="text-xl text-main-orange  mb-2">Nutrition</h4>
                                <p class="text-gray-300 leading-relaxed">Nutrition is the foundation of health, providing the body with the essential nutrients it needs to function optimally, fueling vitality, and promoting longevity.</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>
    </div>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default {
    name: 'MainAbout',
    components: {
        Icon
    }
}
</script>

<style scoped>
section {
    padding: 60px 0;
    overflow: hidden;
    position: relative;
}

.about .about-btn {
    padding: 8px 30px 9px 30px;
    border-radius: 50px;
    transition: 0.3s;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    border: 2px solid #FDA038;
}

.about .about-btn i {
    font-size: 16px;
    padding-left: 5px;
}



@media (max-width: 1200px) {
    .about .content {
        padding-right: 0;
    }
}

@media (max-width: 768px) {
    .about {
        text-align: center;
    }
}</style>