<template>
  <div class="">
    <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div class="max-w-6xl mx-auto">
        <div class="relative h-96 rounded-lg overflow-hidden">
          <!-- <img class="absolute h-full w-full object-cover" src="@/assets/images/danceyoga.jpg" alt="Service 1"> -->
          <video class="absolute h-96 w-full object-cover opacity-75" autoplay muted loop preload="none">
            <source src="@/assets/videos/laughteryoga.mp4" type="video/mp4">
          </video>
          <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30"></div>
          <div class="relative h-full flex items-center justify-center p-8">
            <div class="text-center">
              <h1 class="text-4xl font-bold text-white uppercase mb-4">{{ services.title }}</h1>
              <p class="text-white text-lg mb-8">Laugh your way to health with the practice of Laughter Yoga.</p>
            </div>
          </div>
        </div>
        <div class="relative mt-8  text-justify">
          <article class="format-sm sm:format-base text-justify lg:format-lg format-blue">
            <h2 class="text-5xl font-medium">What is Laughter Yoga?</h2>
            <p class="mt-4 lead leading-10">{{ services.paragraph1 }}</p>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph2 }}</p>
            <div class="mt-8 mb-8 rounded-lg overflow-hidden relative">
              <img :src="services.bannerImage" alt="Dr. Manisha's Yoga Institute" class="w-full h-72 rounded-lg object-cover">
              <h1 class="absolute bottom-0 left-0 text-white font-medium text-4xl sm:text-5xl md:text-6xl  px-4 py-4">
                Laughter Yoga</h1>
            </div>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph3 }}</p>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph4 }}</p>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph5 }}</p>



          </article>
          <div class="mt-8 ">
            <h2 class="text-2xl font-bold">Related Services</h2>
            <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              <div v-for="service in relatedServices" :key="service.title" class="bg-white rounded-lg shadow-md">
                <div class="overflow-hidden rounded-t-lg">
                  <img :src="service.image" alt="Dr. Manisha's Yoga Institute" class="w-full h-48 object-cover">
                </div>
                <div class="p-6">
                  <h3 class="text-lg font-bold mb-4">{{ service.title }}</h3>
                  <p class="text-gray-700">{{ service.description }}</p>
                  <router-link :to="service.to" class="text-main-orange font-bold text-base mt-8">Learn more
                    →</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
// import { API_URL } from '@/config';
export default {
  name: 'LaughterYoga',
  metaInfo() {
    return {
      title: "Laughter Yoga | Dr. Manisha's Yoga Institute",
      meta: [
        { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                { vmid: 'title', name: 'title', content: "Laughter Yoga | Dr. Manisha's Yoga Institute" },
                
        {
          vmid: 'description',
          name: 'description',
          content: "Laughter yoga is a unique and playful form of exercise that combines the healing power of laughter with deep breathing and simple movements. It is a fun and effective way to reduce stress, increase happiness, and improve overall physical and mental health."
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: "Dr. Manisha's Yoga Institute, Laughter Yoga, Benefits, Exercise, Laughter therapy, Laughter clubs, Positive psychology, Health, Wellness, Stress relief, Mental health, Laughter sessions, Breathing exercises, Laughter meditation, Laughter as medicine, Laughter wellness, Laughter as therapy, Laughter techniques, Laughter exercises, Laughter movement, Laughter as a tool, Fun, Laughter classes, Laughter for healing, Laughter for joy"
        },
        {
            vmid: 'og-image',
            property: 'og:image',
            content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683477588/Screenshot_2023-05-07_220157_plj0sg.png'
        },
        {
            vmid: 'og-image-type',
            property: 'og:image:type',
            content: 'image/png'
        },
        {
            vmid: 'og-image-width',
            property: 'og:image:width',
            content: '1200'
        },
        {
            vmid: 'og-image-height',
            property: 'og:image:height',
            content: '630'
        },
        {
            vmid: 'og-image-alt',
            property: 'og:image:alt',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'og-url',
            property: 'og:url',
            content: 'https://drmanishasyogainstitute.com'
        },
        {
            vmid: 'og-type',
            property: 'og:type',
            content: 'health care website'
        },
        {
            vmid: 'og-site-name',
            property: 'og:site_name',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-card',
            name: 'twitter:card',
            content: 'summary_large_image'
        },
        {
            vmid: 'twitter-title',
            name: 'twitter:title',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-description',
            name: 'twitter:description',
            content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
        },
        {
            vmid: 'twitter-image',
            name: 'twitter:image',
            content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683477588/Screenshot_2023-05-07_220157_plj0sg.png'
        },
        {
            vmid: 'twitter-image-alt',
            name: 'twitter:image:alt',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-site',
            name: 'twitter:site',
            content: '@DrManishasYoga'
        },
        {
            vmid: 'twitter-creator',
            name: 'twitter:creator',
            content: '@DrManishasYoga'
        },
        {
            vmid: 'twitter-url',
            name: 'twitter:url',
            content: 'https://drmanishasyogainstitute.com'
        },
        {
            vmid: 'twitter-domain',
            name: 'twitter:domain',
            content: 'drmanishasyogainstitute.com'
        },
      ]
    }
  },
  data() {
    return {
      services: {},
      relatedServices: [
        {
          title: "Dance Exercise",
          image: "https://images.pexels.com/photos/863977/pexels-photo-863977.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Dance exercise offers a joyful and energetic way to stay active and healthy, combining the benefits of cardiovascular exercise, muscular strength, and coordination with the joy and expression of dance.",
          to: "/services/dance-exercise"
        },
        {
          title: "Traditional Yoga",
          image: "https://images.pexels.com/photos/6914832/pexels-photo-6914832.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Traditional yoga is a comprehensive system of physical, mental, and spiritual practices that fosters health, inner peace, and spiritual growth, bringing balance and harmony to body, mind, and spirit.",
          to: "/services/traditional-yoga"
        },
        {
          title: "Meditation & Pranayam",
          image: "https://images.pexels.com/photos/3820380/pexels-photo-3820380.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Meditation and pranayama bring balance to the mind and body, promoting relaxation, focus, and well-being.",
          to: "/services/meditation-pranayam"
        }
      ]
    }
  },
  async mounted() {
    const _id = '8';
    const response = await axios.get(`https://yogaservices-cc925-default-rtdb.asia-southeast1.firebasedatabase.app/services/${_id}.json`);
    this.services = response.data;
    // console.log(this.services);
  }
}
</script>
  