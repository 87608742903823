<template>
    <div>
        <Layout>
            <template v-slot:default>
                <div class="max-w-7xl mt-32 mx-auto px-4 sm:px-6 lg:px-8">
                    <div class="relative  h-96 rounded-lg overflow-hidden">
                        <video class="absolute h-96 w-full object-cover opacity-75" autoplay muted loop preload="none">
                          <source src="@/assets/videos/blogbanner.mp4" type="video/mp4">
                        </video>
                        <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30"></div>
                        <div class="relative h-full flex items-center justify-center p-8">
                          <div class="text-center">
                            <h1 class="text-4xl font-bold text-white uppercase mb-4">Dr. Manisha's Yoga Institute Blogs</h1>
                            <p class="text-xl text-gray-200">Yoga is the journey of the self, through the self, to the self.</p>
                          </div>
                        </div>
                      </div>
                    <div class="py-8">
                        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-8" >
                            <div class="bg-white shadow overflow-hidden rounded-lg" v-for="post in postDetails" :key="post.id">
                              <div class="w-full h-64 bg-cover bg-center relative"
                              :style="{ 'background-image': 'url(' + post.imageUrl + ')' }">
                                <div class="absolute inset-0 bg-black opacity-25"></div>
                                <div class="absolute inset-0 p-6 flex flex-col justify-end">
                                  <div class="text-base text-gray-300 mb-2">{{ post.createdDate }}</div>
                                  <div class="text-xl font-bold text-white mb-2">{{ post.title }}</div>
                                </div>
                              </div>
                              <div class="p-4">
                                <p class="text-gray-700 leading-snug">{{ post.seoDescription.slice(0,100) + '...' }}</p>
                                <div class="mt-4">
                                    <router-link :to="`/blogs/${post.id}`"  class="text-sm font-semibold text-main-orange hover:text-main-orange">Read more →</router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                    </div>
                </div>
            </template>
        </Layout>
    </div>
</template>
<script>
import Layout from '../Layout/Layout.vue';
import axios from "axios";
export default {
    name: 'MainBlog',
    components: {
        Layout
    },
    data() {
        return {
            postDetails: []
        }
    },
    mounted() {
        //mounted means?
        // router.go(1)
        //use axios
        axios.get(`https://yogaproducts-16590-default-rtdb.firebaseio.com/post.json`)
            .then((response) => {
                this.getData(response.data)

            })
    },
    methods: {
        getData(postsData) {
            for (let key in postsData) {
                this.postDetails.push({
                    ...postsData[key], id: key
                })
            }
        }
    },
    metaInfo() {
        return {
            title: "Blogs | Dr. Manisha's Yoga Institute",
            meta: [
                { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                {
                    vmid: 'description',
                    name: 'description',
                    content: "Discover the transformative power of yoga at Dr. Manisha's Yoga Institute. Our yoga courses, workshops, and retreats are designed to help you achieve a healthy mind and body through the practice of yoga postures, breathing techniques, and meditation. Join us and transcend yourself to attain enlightenment."
                },
                {
                    vmid: 'keywords',
                    name: 'keywords',
                    content: "Dr. Manisha's Yoga Institute, Yoga education, Yogic Science, Cultural Synthesis, yoga postures, pranayama, meditation, healthy mind, healthy body, enlightenment, yoga retreats, yoga workshops, yoga philosophy, mindfulness, fitness, stress relief, holistic healing,yoga, yoga institute, yoga classes, yoga teacher training, yoga teacher training in pune, yoga teacher training in maharashtra, yoga teacher training in india, yoga teacher training in mumbai, yoga teacher training in bangalore, yoga teacher training in delhi, yoga teacher training in hyderabad, yoga teacher training in chennai, yoga teacher training in kolkata, yoga teacher training in ahmedabad, yoga teacher training in jaipur, yoga teacher training in surat, yoga teacher training in pune, yoga teacher training in lucknow, yoga teacher training in kanpur, yoga teacher training in nagpur, yoga teacher training in indore, yoga teacher training in thane, yoga teacher training in bhopal, yoga teacher training in pune"
                },
                {
                    vmid: 'og-title',
                    name: 'og:title',
                    content: "Blogs | Dr. Manisha's Yoga Institute"
                },
                {
                    vmid: 'og-description',
                    name: 'og:description',
                    content: "Discover the transformative power of yoga at Dr. Manisha's Yoga Institute. Our yoga courses, workshops, and retreats are designed to help you achieve a healthy mind and body through the practice of yoga postures, breathing techniques, and meditation. Join us and transcend yourself to attain enlightenment."
                },
                {
                    vmid: 'og-image',
                    name: 'og:image',
                    content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683469999/Dr._Manisha_Blog_Page_Image_siwo5i.png'
                },
                {
                    vmid: 'og-image-type',
                    property: 'og:image:type',
                    content: 'image/png'
                },
                {
                    vmid: 'og-image-width',
                    property: 'og:image:width',
                    content: '1200'
                },
                {
                    vmid: 'og-image-height',
                    property: 'og:image:height',
                    content: '630'
                },
                {
                    vmid: 'og-image-alt',
                    property: 'og:image:alt',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'og-url',
                    property: 'og:url',
                    content: 'https://drmanishasyogainstitute.com/blogs'
                },
                {
                    vmid: 'og-site-name',
                    name: 'og:site_name',
                    content: "Blogs | Dr. Manisha's Yoga Institute"
                },
                {
                    vmid: 'og-type',
                    property: 'og:type',
                    content: 'health care website'
                },
                {
                    vmid: 'twitter-card',
                    name: 'twitter:card',
                    content: 'summary_large_image'
                },
                {
                    vmid: 'twitter-title',
                    name: 'twitter:title',
                    content: "Blogs | Dr. Manisha's Yoga Institute"
                },
                {
                    vmid: 'twitter-description',
                    name: 'twitter:description',
                    content: "Discover the transformative power of yoga at Dr. Manisha's Yoga Institute. Our yoga courses, workshops, and retreats are designed to help you achieve a healthy mind and body through the practice of yoga postures, breathing techniques, and meditation. Join us and transcend yourself to attain enlightenment."
                },
                {
                    vmid: 'twitter-image',
                    name: 'twitter:image',
                    content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683469999/Dr._Manisha_Blog_Page_Image_siwo5i.png'
                },
                {
                    vmid: 'twitter-image-alt',
                    name: 'twitter:image:alt',
                    content: 'Dr. Manisha\'s Yoga Institute'
                },
                {
                    vmid: 'twitter-site',
                    name: 'twitter:site',
                    content: '@DrManishasYoga'
                },
                {
                    vmid: 'twitter-creator',
                    name: 'twitter:creator',
                    content: '@DrManishasYoga'
                },
                {
                    vmid: 'twitter-url',
                    name: 'twitter:url',
                    content: 'https://drmanishasyogainstitute.com'
                },
                {
                    vmid: 'twitter-domain',
                    name: 'twitter:domain',
                    content: 'drmanishasyogainstitute.com'
                },
            ]



        }
    }
}
</script>
  
<style>
.rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}</style>
  