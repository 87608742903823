<template>
    <div>
      
      <div class="mx-auto md:mx-16 rounded-lg bg-gray-100 p-8 shadow-2xl">
        <p class="text-center text-xl font-medium my-11">योगस्थ: कुरु कर्माणि सङ्गं त्यक्ता धनञ्जय | <br/>सिध्यसिध्यो: समो भूत्वा समङ्गं योग उच्च्यते ||</p>
        <div class="flex justify-center items-center my-8">
          <div class="w-48 h-48 sm:w-64 sm:h-64 rounded-full overflow-hidden border-4 shadow-2xl  border-white">
            <img src="@/assets/images/Dr.Manisha.jpeg" alt="insert image alt text here" class="w-full h-full object-cover">
          </div>
        </div>
        <h2 class="text-center text-4xl font-medium my-5">Dr. Manisha Sonawane</h2>
        <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />
        <p class="text-center text-lg italic my-8">‘Steadfast in Yoga, perform your duty, renouncing attachment to fruits of action, equanimous in success and failure. Having gained equanimity of mind, being same in all status is called ‘Yoga’</p>
        <p class="text-lg text-justify leading-relaxed mb-8">Yoga is an invaluable gift of India's ancient tradition. Man is a physical, mental and spiritual being and yoga helps promote a balanced development of all the three. The art of practicing yoga controls an individual's mind, body and soul. The true essence of yoga revolves around elevating the life force. At the physical level, the methods comprise various yoga postures or 'asanas' that aim to keep the body healthy. The mental techniques include breathing exercises or ‘pranayama’ and meditation or ‘dhyana’ to discipline the mind.</p>
        <p class="text-lg text-justify leading-relaxed mb-8">The distressed life style due to emergent electronically enabled environment, calls for right kind of attitude through the yogic science for significant ultimatum for healthy mind and healthy body. Dr. Manisha Institute of Yogic Science and Cultural Synthesis, looks at yoga education differently. This institute aims at training men and women in all walks of life and bringing about the desire changes in the lives of the people. Our yoga institute is intended to broaden your horizons and cultivate new understandings. Our ultimate goal is to help the individual to transcend the self and attain enlightenment. We want to give you the depth and the strength which is yours... the original you.</p>
        <p class="text-lg text-justify leading-relaxed mb-8">It is indeed a privilege for me to present you various courses and programmes in Yogic Science and cultural fusion. Hence, I take this opportunity to welcome and invite our esteemed organization for yoga edification. I firmly believe that this is an invitation for an enriching and mutually rewarding relationship. </p>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div class="aspect-w-3 aspect-h-2">
            <img src="@/assets/images/teachingstudent.jpg" alt="insert image alt text here" class="shadow-md rounded-lg object-cover w-full h-full">
          </div>
          <div class="aspect-w-3 aspect-h-2">
            <img src="@/assets/images/teachingstudent2.jpg" alt="insert image alt text here" class="shadow-md rounded-lg object-cover w-full h-full">
          </div>
          <div class="aspect-w-3 aspect-h-2">
            <img src="@/assets/images/training7.jpg" alt="insert image alt text here" class="shadow-md rounded-lg object-cover w-full h-full">
          </div>
          <div class="aspect-w-3 aspect-h-2">
            <img src="@/assets/images/training9.jpg" alt="insert image alt text here" class="shadow-md rounded-lg object-cover w-full h-full">
          </div>
          <div class="aspect-w-3 aspect-h-2">
            <img src="@/assets/images/training6.jpg" alt="insert image alt text here" class="shadow-md rounded-lg object-cover w-full h-full">
          </div>
          <div class="aspect-w-3 aspect-h-2">
            <img src="@/assets/images/training10.jpg" alt="insert image alt text here" class="shadow-md object-cover w-full h-full">
          </div>
        </div>
        
        
        
      </div>
    </div>
  </template>
<script>
export default {
    name:'MainAbout'
}
</script>