<template>
    <div>
        <MainHeader />
        <slot />
        <MainFooter />

    </div>
</template>

<script>
import MainFooter from './Footer/MainFooter.vue';
import MainHeader from './Header/MainHeader.vue';

export default {
    name: 'LayoutComponent',
    components:{
        MainHeader,
        MainFooter
    }
}
</script>