<template>
  <div class="flex mt-32 flex-wrap">
    <div class="mx-auto mt-10 px-4 sm:px-6 lg:px-8">
      <h3 class="text-lg leading-6 font-medium text-gray-900">Edit Post</h3>
      <div class="mt-5">
        <form @submit.prevent="savePost" class="space-y-8 divide-y divide-gray-200">
          <div class="space-y-8 divide-y divide-gray-200">
            <div class="py-6">
              <label for="title" class="block text-sm font-medium text-gray-700">Title</label>
              <div class="mt-1">
                <input type="text" id="title" v-model="post.title"
                  class="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md">
              </div>
            </div>
            <div class="py-6">
              <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
              <div class="mt-1">
                <editor ref="myTextEditor" api-key="nho6z4l4y4h9qoxhmi1gcitneruobar1gxycib1m96myifd7" :init="editorInit"
                  id="description" v-model="post.description"
                  class="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  v-html="this.post.description"></editor>
              </div>
            </div>
          </div>
          <div class="py-6">
            <label for="seoKeywords" class="block text-sm font-medium text-gray-700">SEO Keywords</label>
            <div class="mt-1">
              <input type="text" id="seoKeywords" v-model="post.seoKeywords"
                class="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
          </div>
          <div class="py-6">
            <label for="seoDescription" class="block text-sm font-medium text-gray-700">SEO Description</label>
            <div class="mt-1">
              <input type="text" id="seoDescription" v-model="post.seoDescription"
                class="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
          </div>
          <div class="py-6">
            <label for="imageUrl" class="block text-sm font-medium text-gray-700">Image URL</label>
            <div class="mt-1">
              <input type="text" id="imageUrl" v-model="post.imageUrl"
                class="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md"
                disabled>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <button type="submit"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                Save
              </button>
              <router-link :to="`/blogs/${post.id}`"
                class="ml-3 inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500">
                Cancel
              </router-link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from "axios";
import DOMPurify from "dompurify";
import Editor from '@tinymce/tinymce-vue';

export default {
  name: "EditPost",
  components: {
    'editor': Editor
  },
  data() {
    return {
      post: {
        id: "",
        title: "",
        description: "",
        seoKeywords: "",
        seoDescription: "",
        createdDate: "",
        imageUrl: null
      },
    };
  },
  computed: {
    editorInit() {
      return {
        plugins:
          'anchor autolink charmap codesample emoticons image imagetools link lists media searchreplace table visualblocks wordcount code',
        toolbar:
          'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat | imagetools | code',
        menubar: true,
        branding: false,
        statusbar: false,
        format:'html'
      };
    }
  },
  mounted() {
    const postId = this.$route.params.id;
    axios
      .get(`https://yogaproducts-16590-default-rtdb.firebaseio.com/post/${postId}.json`)
      .then((response) => {
        const post = response.data;
        this.post = {
          id: postId,
          title: post.title,
          description: post.description,
          seoKeywords: post.seoKeywords,
          seoDescription: post.seoDescription,
          createdDate: post.createdDate,
          imageUrl: post.imageUrl
        };
      });
  },
  methods: {
    savePost() {
      // const purifiedText = this.purifyText(this.post.description);
      axios
        .put(`https://yogaproducts-16590-default-rtdb.firebaseio.com/post/${this.post.id}.json`, {
          title: this.post.title,
          description: this.post.description,
          seoKeywords: this.post.seoKeywords,
          seoDescription: this.post.seoDescription,
          createdDate: this.post.createdDate,
          imageUrl: this.post.imageUrl
        })
        .then(() => {
          this.$router.push(`/blogs/${this.post.id}`);
        });
    },
    purifyText(text) {
      const purified = DOMPurify.sanitize(text, {
        ALLOWED_TAGS: [],
      });
      return purified.replace(/(<([^>]+)>)/gi, "");
    },
  },
};
</script>
  