<template>
  <div>
    <!-- <SilentAudio/> -->
    <section class="bg-white  py-8 px-4 mx-auto max-w-screen-xl  lg:py-16 lg:px-12 dark:bg-gray-900" >
      <div class="text-center">
        <router-link to="/courses" class=" inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full dark:bg-gray-800 dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700" role="alert">
          <span class="text-xs bg-main-orange rounded-full text-white px-4 py-1.5 mr-3">New</span> <span class="text-sm font-medium">Checkout Government Affiliated Courses</span> 
   <!-- animate-ping -->       <svg class="ml-2 w-5 h-5 " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
        </router-link>
      </div>
      <div class="relative -m-10 grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
        <div class="mr-auto place-self-center lg:col-span-7">
        
          <h1
            class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
            Dr. Manisha's Yoga Institute</h1>
          <p class="max-w-2xl mb-6 font-light text-main-text lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
            Find peace in every breath, strength in every posture, and stillness in every movement through the practice of yoga.  
          </p>
          <router-link to="/enroll-now"
            class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-main-orange hover:bg-main-bg hover:text-black focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
            Enroll Now
            <svg class="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
            </svg>
          </router-link>
          <a href="https://wa.me/918308315494?text=*Dr.%20Manisha's%20Yoga%20Institute*%0A%0AHello%2C%20I%20have%20a%20question%20about%20your%20service.%20Can%20you%20please%20help%20me%3F%0A%60%60%60Came%20across%20you%20website%3A%20%60%60%60%0Ahttps%3A%2F%2Fwww.drmanishasyogainstitute.com%2F"
            target="_blank"  
          class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
            Speak to Trainer
          </a>
        </div>
        <div class="hidden lg:mt-16 lg:col-span-5 lg:flex w-full  hero-yoga" 
          data-aos="slide-left"
          data-aos-ease="ease"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <img src="@/assets/images/hero_yoga.png" class="h-full" alt="Yoga image" >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import SilentAudio from '../Music/SilentAudio.vue';

export default {
    name: "MainHero",
    components: { 
      
      // SilentAudio 
    }
}

</script>

<style scoped>
.hero-yoga{
  z-index: 5;
  animation:move 4s infinite ease-in-out;
  animation-delay: 0s;
}
  @keyframes move{
    0% {
      transform: scale(1);
	}

	70% {
    transform: scale(0.95);
  }
  
	100% {
    transform: scale(1);
	}
  
}
</style>