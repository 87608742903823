<template>
  <div class="">
    <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div class="max-w-6xl mx-auto">
        <div class="relative h-96 rounded-lg overflow-hidden">
          <video class="absolute h-96 w-full object-cover opacity-75" autoplay muted loop preload="none">
            <source src="@/assets/videos/danceyoga.mp4" type="video/mp4">
          </video>
          <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30"></div>
          <div class="relative h-full flex items-center justify-center p-8">
            <div class="text-center">
              <h1 class="text-4xl font-bold text-white uppercase mb-4">{{ services.title }}</h1>
              <p class="text-white text-lg mb-8">Dance Exercise offers a fun and inspiring way to stay fit, express yourself, and enjoy the many benefits of movement and music.</p>
            </div>
          </div>
        </div>
        <div class="relative mt-8  text-justify">
          <article class="format-sm sm:format-base text-justify lg:format-lg format-blue">
            <h2 class="text-5xl font-medium">What is Dance Exercise?</h2>
            <p class="mt-4 lead leading-10">{{ services.paragraph1 }}</p>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph2 }}</p>
            <div class="mt-8 mb-8 rounded-lg overflow-hidden relative">
              <img :src="services.bannerImage" alt="Dr. Manisha's Yoga Institute" class="w-full h-72 rounded-lg object-cover">
              <h1 class="absolute bottom-0 left-0 text-white font-medium text-4xl sm:text-5xl md:text-6xl  px-4 py-4">Dance Exercise</h1>
            </div>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph3 }}</p>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph4 }}</p>
            <p class="mt-6  text-lg leading-8">{{ services.paragraph5 }}</p>



          </article>
          <div class="mt-8 ">
            <h2 class="text-2xl font-bold">Related Services</h2>
            <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              <div v-for="service in relatedServices" :key="service.title" class="bg-white rounded-lg shadow-md">
                <div class="overflow-hidden rounded-t-lg">
                  <img :src="service.image" alt="Dr. Manisha's Yoga Institute" class="w-full h-48 object-cover">
                </div>
                <div class="p-6">
                  <h3 class="text-lg font-bold mb-4">{{ service.title }}</h3>
                  <p class="text-gray-700">{{ service.description }}</p>
                  <router-link :to="service.to" class="text-main-orange font-bold text-base mt-8">Learn more →</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
// import { API_URL } from '@/config';
export default {
  name: 'DanceExercise',
  metaInfo() {
    return {
      title: "Dance Exercise | Dr. Manisha's Yoga Institute",
      meta: [
        { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                { vmid: 'title', name: 'title', content: "Dance Exercise | Dr. Manisha's Yoga Institute" },
                
        {
          vmid: 'description',
          name: 'description',
          content: "Dance Exercise is a fun and energizing way to get in shape and improve your physical fitness. Our classes are designed to help you strengthen and tone your body, while releasing stress and expressing your emotions through dance. Whether you're a seasoned dancer or just looking to try something new, we offer a wide variety of classes to suit every age, level, and ability."
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: "Dr. Manisha's Yoga Institute, Dance fitness, Exercise through dance, Physical fitness, Body strengthening, Stress release, Emotional expression, Wide variety of classes, All ages, All levels, Lifelong fitness, Confidence boost, Self-esteem improvement, Personal expression, Discipline and focus, Lifelong friendships, Bond building, Flexibility, Creativity, Imagination, Posture improvement, Poise enhancement, Joyful exercise, Pleasure in movement, Yoga, Dr. Manisha's Yoga Institute, hatha yoga, vinyasa yoga, power yoga, restorative yoga, yoga for beginners, yoga for seniors, yoga for stress relief, yoga for relaxation, yoga for mindfulness, yoga for flexibility, yoga for balance, yoga for strength, yoga for weight loss, yoga for wellness, yoga for mental health, yoga for physical health."
        },
        {
            vmid: 'og-image',
            property: 'og:image',
            content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683477588/Screenshot_2023-05-07_220103_dkdiyr.png'
        },
        {
            vmid: 'og-image-type',
            property: 'og:image:type',
            content: 'image/png'
        },
        {
            vmid: 'og-image-width',
            property: 'og:image:width',
            content: '1200'
        },
        {
            vmid: 'og-image-height',
            property: 'og:image:height',
            content: '630'
        },
        {
            vmid: 'og-image-alt',
            property: 'og:image:alt',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'og-url',
            property: 'og:url',
            content: 'https://drmanishasyogainstitute.com'
        },
        {
            vmid: 'og-type',
            property: 'og:type',
            content: 'health care website'
        },
        {
            vmid: 'og-site-name',
            property: 'og:site_name',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-card',
            name: 'twitter:card',
            content: 'summary_large_image'
        },
        {
            vmid: 'twitter-title',
            name: 'twitter:title',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-description',
            name: 'twitter:description',
            content: 'Welcome to Dr. Manisha\'s Yoga Institute, where we offer a holistic approach to yoga education. Our courses, workshops, and retreats are designed to promote a healthy m...'
        },
        {
            vmid: 'twitter-image',
            name: 'twitter:image',
            content: 'https://res.cloudinary.com/doqozlqzp/image/upload/v1683477588/Screenshot_2023-05-07_220103_dkdiyr.png'
        },
        {
            vmid: 'twitter-image-alt',
            name: 'twitter:image:alt',
            content: 'Dr. Manisha\'s Yoga Institute'
        },
        {
            vmid: 'twitter-site',
            name: 'twitter:site',
            content: '@DrManishasYoga'
        },
        {
            vmid: 'twitter-creator',
            name: 'twitter:creator',
            content: '@DrManishasYoga'
        },
        {
            vmid: 'twitter-url',
            name: 'twitter:url',
            content: 'https://drmanishasyogainstitute.com'
        },
        {
            vmid: 'twitter-domain',
            name: 'twitter:domain',
            content: 'drmanishasyogainstitute.com'
        },
      ]
    }
  },
  
  data() {
    return {
      services: {},
      relatedServices: [
        {
          title: "Traditional Yoga",
          image: "https://images.pexels.com/photos/6914832/pexels-photo-6914832.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Traditional yoga is a comprehensive system of physical, mental, and spiritual practices that fosters health, inner peace, and spiritual growth, bringing balance and harmony to body, mind, and spirit.",
          to: "/services/traditional-yoga"
        },
        {
          title: "Meditation & Pranayam",
          image: "https://images.pexels.com/photos/3820380/pexels-photo-3820380.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Meditation and pranayama bring balance to the mind and body, promoting relaxation, focus, and well-being.",
          to: "/services/meditation-pranayam"
        },
        {
          title: "Healing Therapy",
          image: "https://images.pexels.com/photos/7113299/pexels-photo-7113299.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Healing therapy promotes holistic wellness by addressing the root cause of physical and emotional issues, fostering balance and growth.",
          to: "/services/healing-therapy"
        }
      ]
    }
  },
  async mounted(){
    const _id = '1';
    const response = await axios.get(`https://yogaservices-cc925-default-rtdb.asia-southeast1.firebasedatabase.app/services/${_id}.json`);
    this.services = response.data;
    // console.log(this.services);
  }
}
</script>