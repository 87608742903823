<template>
    <div class="mt-24">
        <div class="items-center justify-center text-center">
            <h2 class="text-6xl font-semibold text-main-text mb-5">Checkout</h2>
            <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />

        </div>



        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 p-8">
            <div class="bg-white border border-gray-200 rounded-lg shadow-lg p-8">
                <h2 class="text-lg font-semibold mb-4">Your Cart</h2>
                <ul class="divide-y divide-gray-200">
                    <li v-for="item in items" :key="item.id" class="flex justify-between py-4">
                        <div class="flex items-center space-x-4">
                            <img :src="item.product.thumbnail" alt="product image"
                                class="w-16 h-16 object-cover rounded-lg">
                            <div>
                                <h3 class="text-base font-semibold">{{ item.product.title }}</h3>
                                <p class="text-gray-600">{{ item.quantity }} x {{ item.product.price }}</p>
                            </div>
                        </div>
                        <p class="text-gray-800 font-semibold">₹{{ item.quantity * item.product.price }}</p>
                    </li>
                </ul>
            </div>



            <div class="bg-white border border-gray-200 rounded-lg shadow-lg p-8">
                <h2 class="text-lg font-semibold mb-4">Hello, {{ username }}!</h2>
                <h2 class="text-base font-medium mb-4">Your Email: {{ email }}</h2>

                <h2 class="text-lg font-semibold mb-4">Total</h2>
                <p class="text-2xl font-semibold">₹{{ total }}</p>

                <div class="flex justify-end">
                    <button
                        class="bg-main-orange hover:bg-main-orange hover:bg-opacity-70 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button" id="checkout-button" @click="handlePayment">
                        Pay: ₹{{ total }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
  
  
<script>
import Swal from 'sweetalert2';
import axios from 'axios';
import { API_URL } from '@/config';
import emailjs from 'emailjs-com';

export default {
    name: "CheckoutPage",
    computed: {
        items() {
            return JSON.parse(this.$route.query.items);
        },
        total() {
            return this.$route.query.total;
        },
        username() {
            return this.$route.query.user_name;
        },
        email() {
            return this.$route.query.user_email;
        }
    },
    methods: {
        getOrderDetailsHTML() {
            
            let plainText = 'Your Order Details:\n\n';
            this.items.forEach(item => {
                plainText += `${item.product.title}: ${item.quantity} x ₹${item.product.price} = ₹${item.quantity * item.product.price}\n`;
            });
            plainText += `\nTotal: ₹${this.total}`;
            return {
                plainText: plainText
            };
        },
        async handlePayment() {
            await Swal.fire({
                title: `Are you sure?`,
                text: `You will have to pay ₹${this.total}/- on delivery`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Complete my Order!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const timestamp = new Date().toISOString();
                        const response = await axios.post(`${API_URL}/api/orders/checkout`, {
                            items: this.items,
                            totalPrice: this.total,
                            username: this.username,
                            email: this.email,
                            date: timestamp
                        });
                        console.log(response);
                        this.pay();
                    }
                    catch (err) {
                        console.log(err);
                    }
                }
            })


        },
        async pay() {
            const emailParams = {
                to_email: this.email,
                from_name: "Dr. Manisha's Yoga Institute",
                from_email: 'drmanishas777@gmail.com',
                subject: "Dr. Manisha's Yoga Institute | Your Shopping Cart Summary",
                username: this.username,
                message_text: this.getOrderDetailsHTML().plainText
            };
            const emailResponse = await emailjs.send(
                'service_n76hg9n',
                'template_9nerje9',
                emailParams,
                '0Zj2J8AHRXcrBwgLU'
            );
            console.log(emailResponse);
            await Swal.fire({
                title: 'Ordered Successfully!',
                text: "Thank you for shopping with us! We will get back to you soon",
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Continue Shopping'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.clearCart();
                }
            })


        },
        async clearCart() {
            // Clear cart from local storage
            localStorage.removeItem('cart');

            // Reset cart items to 0
            this.$emit('reset-cart');

            // Clear cart items from store
            await this.$store.dispatch('clearCartItems');

            // Redirect to home page
            this.$router.push('/products');
        }
    }



}
</script>
  
<style scoped>
@media (min-width: 768px) {
    .grid {
        grid-template-columns: 1fr 2fr;
    }
}
</style>
  