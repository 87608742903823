<template>
    <div class="mt-12 py-16">
        <h2 class="text-4xl md:text-6xl font-medium text-center mb-8">What our Clients Say</h2>
        <hr class="w-16 md:w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />

        <div
            class="relative flex flex-col px-6 mx-4 md:px-32 md:mx-20 md:flex-row md:items-center bg-white rounded-lg overflow-hidden">


            <div class="md:w-2/5">
                <div class="h-full">
                    <img :src="testimonials[currentIndex].image" alt="Testimonial Image"
                        class="object-contain h-52 md:h-72 lg:h-96 rounded-lg">
                </div>
            </div>
            <div class="md:w-3/5 p-4 md:p-8">
                <div class="mb-8">
                    <p class="text-lg text-justify md:text-2xl font-medium text-gray-800">&ldquo;{{ testimonials[currentIndex].quote
                    }}&rdquo;</p>
                </div>
                <div>
                    <p class="italic text-gray-400 text-sm md:text-base">-{{ testimonials[currentIndex].author }}</p>
                </div>
            </div>
            <br/><br/>
            <div class="testimonial-nav absolute  bottom-0 left-0 right-0 flex justify-center space-x-2 mt-8">
                <button v-for="(testimonial, index) in testimonials" :key="index"
                    class="testimonial-nav-item h-2 w-2 rounded-full bg-gray-500 focus:outline-none"
                    :class="{ 'bg-gray-900': index === currentIndex }" @click="setCurrentIndex(index)"></button>
            </div>
        </div>
    </div>
</template>


  
  
  
  
<script>
export default {
    data() {
        return {
            currentIndex: 0,
            testimonials: [
                {
                    quote: "Hi, I'm Dina Dhiresh Shah, a youthful 16-year-old with 44 years of experience, thanks to my regular practice of yoga at Manisha's Yoga Institute for the last 20 years. Manisha's dedication, personal attention, and passion makes me coming back., and I've tried other yoga studios before. She updates her knowledge and keeps things fresh, making her my favorite teacher. Wishing her all the best for the future.",
                    author: "Dina Dhiresh Shah",
                    image: "https://res.cloudinary.com/doqozlqzp/image/upload/v1681665236/samples/testi1_hcf3ee.jpg"
                },
                {
                    quote: "Yoga is more than just asanas; it's a holistic practice that unites the body, mind, and soul. Dr. Manisha's yoga classes transformed my physical health and mental wellbeing. I was feeling depressed and unhealthy before joining her classes, but her breathing exercises helped reduce my anxiety, and I became more self-aware by practicing pranayama, meditation, and asanas. Thanks to her, I lost 6 kg, and my muscles and joints are now more flexible and stronger. Thank you, Manisha ma'am, for inspiring us to take care of ourselves.",
                    author: "Rakhi",
                    image: "https://res.cloudinary.com/doqozlqzp/image/upload/v1681668273/samples/testi2_ua89nh.jpg"
                }
            ]
        };
    },
    methods: {
        setCurrentIndex(index) {
            this.currentIndex = index;
        }
    }
};
</script>
  
<style>
.testimonial-slider {
    height: 200px;
}

.testimonial {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.testimonial-slider img {
    object-fit: cover;
}

.testimonial-content {
    text-align: center;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.testimonial-nav-item {
    transition: background-color 0.2s;
}

@media (min-width: 640px) {
    .testimonial-nav {
        bottom: 1rem;
    }
}

@media (min-width: 768px) {
    .testimonial-nav {
        bottom: 2rem;
    }
}

@media (min-width: 1024px) {
    .testimonial-nav {
        bottom: 3rem;
    }
}

@media (min-width: 1280px) {
    .testimonial-nav {
        bottom: 4rem;
    }
}
</style>
  