<template>
    <div>
        <div class="bg-transparent max-w-screen-lg mx-auto px-4 md:px-8 py-12 transition-all duration-500 ease-linear">
            <div class="relative">
                <div class="slides-container h-96 flex snap-x snap-mandatory overflow-hidden overflow-x-auto space-x-2 rounded scroll-smooth before:w-[45vw] before:shrink-0 after:w-[45vw] after:shrink-0 md:before:w-0 md:after:w-0">
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewiseyoga1.jpg" alt="mountain_image">
                    </div>
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diesasewise2.jpeg" alt="mountain_image">
                    </div>
                     <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewise3.jpeg" alt="mountain_image">
                    </div>
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewise4.jpeg" alt="mountain_image">
                    </div>
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewise5.jpeg" alt="mountain_image">
                    </div>
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewise6.jpeg" alt="mountain_image">
                    </div>
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewise8.jpeg" alt="mountain_image">
                    </div>
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewise3.jpeg" alt="mountain_image">
                    </div>
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewise11.jpeg" alt="mountain_image">
                    </div>
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewise7.jpeg" alt="mountain_image">
                    </div> 
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewise10.jpeg" alt="mountain_image">
                    </div> 
                    <div class="slide aspect-square h-full flex-shrink-0 snap-center rounded-lg overflow-hidden">
                        <img class="w-full shadow-md h-full object-cover" src="@/assets/images/diseasewiseyoga2.jpg" alt="mountain_image">
                    </div> 
    
                </div>
    
                <div class="absolute top-0 -left-4 h-full items-center hidden md:flex">
                    <button @click="scrollLeft" role="button" class="prev shadow-md px-2 py-2 rounded-full bg-main-bg text-main-text" aria-label="prev"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 group-active:-translate-x-2 transition-all duration-200 ease-linear">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
    
                    </button>
                </div>
                <div class="absolute top-0 -right-4 h-full items-center hidden md:flex">
                    <button @click="scrollRight" role="button" class="next shadow-md px-2 py-2 rounded-full bg-main-bg text-main-text" aria-label="next"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 group-active:translate-x-2 transition-all duration-200 ease-linear">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ImageSlider",
    methods:{
        scrollLeft() {
      const slidesContainer = document.querySelector(".slides-container");
      const slideWidth = slidesContainer.querySelector(".slide").clientWidth;
      slidesContainer.scrollLeft -= slideWidth;
    },
    scrollRight() {
      const slidesContainer = document.querySelector(".slides-container");
      const slideWidth = slidesContainer.querySelector(".slide").clientWidth;
      slidesContainer.scrollLeft += slideWidth;
    },
  },

}
</script>

<style scoped>
.slides-container {
	-ms-overflow-style: none; /* Internet Explorer 10+ */
	scrollbar-width: none; /* Firefox */
}
.slides-container::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
}

</style>