<template>
    <div>
        <header>
            <nav class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-gray-800">
                <div class="flex flex-wrap justify-between items-center">
                    <div class="flex justify-start items-center">
                        <!-- get admin name -->
                        <div class="ml-4 mt-4 text-4xl text-gray-500 dark:text-gray-400">
                            Welcome, <span class="font-semibold"> Dr. Manisha! </span>
                        </div>
                      </div>
                    
                </div>
            </nav>
          </header>
    </div>
</template>

<script>
export default{
    name: 'AdminNavbar',
    
    
}
</script>