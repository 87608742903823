import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

function updateLocalStorage(cart) {
  localStorage.setItem("cart", JSON.stringify(cart));
}

const store = new Vuex.Store({
  state: {
    products: { data: {} },
    cart: [],
    user: null,
    sampleBlogCards:[
      {
        blogTitle: 'BlogCard1',
        blogDescription: 'BlogCard1 Description',
        blogCoverPhoto:''
      },
      {
        blogTitle: 'BlogCard2',
        blogDescription: 'BlogCard2 Description',
        blogCoverPhoto:''
      },
      {
        blogTitle: 'BlogCard3',
        blogDescription: 'BlogCard3 Description',
        blogCoverPhoto:''
      },
    ]
  },
  getters: {
    cartTotalPrice: (state) => {
      let total = 0;

      state.cart.forEach((item) => {
        total +=
          (item.product.price -
            (item.product.price * item.product.discountPercentage) / 100) *
          item.quantity;
      });

      return total.toFixed(2);
    },
    currentUser: (state) => state.user,
  },
  actions: {
    getProducts({ commit }) {
      return axios(
        "https://yogaproducts-16590-default-rtdb.firebaseio.com/products.json"
      ).then(({ data }) => {
        commit("getProducts", data);
        console.log(data);
        return data;
      });
    },
    addProductToCart({ commit }, { product, quantity }) {
      commit("addToCart", { product, quantity });
    },
    removeProductFromCart({ commit }, { product, quantity }) {
      commit("removeFromCart", { product, quantity });
    },
    clearCartItems({ commit }) {
      commit("clearCart");
    },
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    clearUser({ commit }) {
      commit("clearUser");
    },
  },
  mutations: {
    getProducts: (state, productsData) => {
      state.products.data = productsData.products;
    },
    addToCart: (state, { product }) => {
      let productInCart = state.cart.find((item) => {
        return item.product.id === product.id;
      });

      if (productInCart) {
        productInCart.quantity++;
      } else {
        state.cart.push({
          product,
          quantity: 1,
        });
      }

      updateLocalStorage(state.cart);
    },
    removeFromCart: (state, { product }) => {
      let productInCart = state.cart.find((item) => {
        return item.product.id === product.id;
      });

      if (productInCart) {
        if (productInCart.quantity > 1) {
          productInCart.quantity--;
        } else {
          state.cart = state.cart.filter(
            (item) => item.product.id !== product.id
          );
        }
      }

      updateLocalStorage(state.cart);
    },
    updateCartFromLocalStorage(state) {
      const cart = localStorage.getItem("cart");
      if (cart) {
        state.cart = JSON.parse(cart);
      }
    },
    clearCart(state) {
      state.cart = [];
      updateLocalStorage(state.cart);
    },
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
  },
  modules: {},
});

export default store;
