<template>
    <div>
        <Layout>
            <template v-slot:default >
                <h2 class="text-2xl mt-32 w-1/2 font-semibold mb-4">Sign up</h2>
                <span class="text-sm text-gray-600">Sign in to create an account</span>
                <form @submit.prevent="signup" method="POST" class="flex flex-col">
                    <label for="name" class="mb-1 font-medium text-gray-700">Name:</label>
                    <input v-model="username" type="text" name="username" id="name" required
                        class="border border-gray-300 p-2 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-main-orange focus:border-transparent">

                    <label for="email" class="mb-1 font-medium text-gray-700">Email:</label>
                    <input v-model="email" type="email" name="email" id="email" required
                        class="border border-gray-300 p-2 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-main-orange focus:border-transparent">

                    <label for="password" class="mb-1 font-medium text-gray-700">Password:</label>
                    <input v-model="password" type="password" name="password" id="password" required
                        class="border border-gray-300 p-2 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-main-orange focus:border-transparent">

                    <button type="submit"
                        class="bg-main-orange hover:bg-main-orange hover:bg-opacity-70 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                        Sign up
                    </button>

                    <p class="mt-4 text-sm text-gray-600">Already have an account? <a @click.prevent="showLoginForm = true"
                            class="text-main-orange font-medium">Log in</a></p>
                </form>
            </template>
        </Layout>
    </div>
</template>

<script>
import Layout from '../Layout/Layout.vue';
import {API_URL} from '@/config'
export default {
    name: 'UserSignin',
    components: {
        Layout,
    },
    data() {
        return {
            username: '',
            email: '',
            password: '',
        }
    },
    methods: {
        async signup(e) {
            e.preventDefault();
            //API Call
            try {
                const res = await fetch(`${API_URL}/api/auth/user/signup`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        username: this.username,
                        password: this.password,
                        email: this.email,
                    }),
                });

                const json = await res.json();
                console.log(json);
                if (json.authtoken) {
                    sessionStorage.setItem('token', json.authtoken);
                    
                    this.$router.push("/products")
                }
                else {
                    if (json.errors) {
                        json.errors.forEach(error => {
                            alert(error.msg)
                        })
                    } else {
                        alert(json.msg)
                    }
                }
            } catch (err) {
                console.error(err.message);
            }
        },
    }
}    
</script>