<template>
  <aside class="fixed top-0 left-0 w-64 h-full" aria-label="Sidenav">
    <div class="overflow-y-auto py-5 px-3 h-full bg-white border-r border-gray-200 dark:bg-gray-800 dark:border-gray-700">
      <a href="#" class="flex mr-4">
        <img src="@/assets/images/logo.png" class="h-24 mr-3 sm:h-24" alt="Dr. Manisha's
            Yoga Institute" />
      </a>
      <span class="self-center text-2xl font-semibold whitespace-wrap dark:text-white">Dr. Manisha's Yoga Institute</span>
      <ul class="md:flex-col md:min-w-full flex flex-col list-none">

      </ul>

      <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
        <li class="items-center">
          <router-link to="/admin/dashboard">
            <a
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M5 21q-.825 0-1.413-.588T3 19V5q0-.825.588-1.413T5 3h6v18H5Zm8 0v-9h8v7q0 .825-.588 1.413T19 21h-6Zm0-11V3h6q.825 0 1.413.588T21 5v5h-8Z"/></svg>
              <span class="ml-3">Dashboard</span>
            </a>
          </router-link>
        </li>

        <li>
          <router-link to="/admin/all-users">
          <a 
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">

            <!-- <i class="pi pi-shopping-cart flex-shrink-0 w-6 h-6 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05c1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/></svg>


            <span class="ml-3">All Users</span>
          </a>
        </router-link>
        </li>
        <li>
          <router-link to="/admin/all-orders">
          <a 
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">

            <!-- <i class="pi pi-shopping-cart flex-shrink-0 w-6 h-6 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"></i> -->
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32"  viewBox="0 0 24 24"><path fill="currentColor" d="M7 22q-.825 0-1.413-.588T5 20q0-.825.588-1.413T7 18q.825 0 1.413.588T9 20q0 .825-.588 1.413T7 22Zm10 0q-.825 0-1.413-.588T15 20q0-.825.588-1.413T17 18q.825 0 1.413.588T19 20q0 .825-.588 1.413T17 22ZM6.15 6l2.4 5h7l2.75-5H6.15ZM7 17q-1.125 0-1.7-.988t-.05-1.962L6.6 11.6L3 4H1.975q-.425 0-.7-.288T1 3q0-.425.288-.713T2 2h1.625q.275 0 .525.15t.375.425L5.2 4h14.75q.675 0 .925.5t-.025 1.05l-3.55 6.4q-.275.5-.725.775T15.55 13H8.1L7 15h11.025q.425 0 .7.288T19 16q0 .425-.288.713T18 17H7Zm1.55-6h7h-7Z"/></svg>


            <span class="ml-3">All Orders</span>
          </a>
        </router-link>
        </li>
      </ul>
      <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
        <p>Enrollments</p>
        <li>
          <router-link to="/admin/enrollments">
          <a 
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11.374 23.977c-4.183-.21-8.006-2.626-9.959-6.347c-2.097-3.858-1.871-8.864.732-12.454C4.748 1.338 9.497-.698 14.281.23c4.583.857 8.351 4.494 9.358 8.911c1.122 4.344-.423 9.173-3.925 12.04c-2.289 1.953-5.295 2.956-8.34 2.797zm7.705-8.05a588.737 588.737 0 0 0-3.171-1.887c-.903 1.483-2.885 2.248-4.57 1.665c-2.024-.639-3.394-2.987-2.488-5.134c.801-2.009 2.79-2.707 4.357-2.464a4.19 4.19 0 0 1 2.623 1.669c1.077-.631 2.128-1.218 3.173-1.855c-2.03-3.118-6.151-4.294-9.656-2.754c-3.13 1.423-4.89 4.68-4.388 7.919c.54 3.598 3.73 6.486 7.716 6.404a7.664 7.664 0 0 0 6.404-3.563z"/></svg>
            <span class="ml-3">New Enrollments</span>
          </a>
        </router-link>
        </li>
      </ul>
      <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
        <p>Free Demo</p>
        <li>
          <router-link to="/admin/freedemo-registrations">
          <a 
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11.374 23.977c-4.183-.21-8.006-2.626-9.959-6.347c-2.097-3.858-1.871-8.864.732-12.454C4.748 1.338 9.497-.698 14.281.23c4.583.857 8.351 4.494 9.358 8.911c1.122 4.344-.423 9.173-3.925 12.04c-2.289 1.953-5.295 2.956-8.34 2.797zm7.705-8.05a588.737 588.737 0 0 0-3.171-1.887c-.903 1.483-2.885 2.248-4.57 1.665c-2.024-.639-3.394-2.987-2.488-5.134c.801-2.009 2.79-2.707 4.357-2.464a4.19 4.19 0 0 1 2.623 1.669c1.077-.631 2.128-1.218 3.173-1.855c-2.03-3.118-6.151-4.294-9.656-2.754c-3.13 1.423-4.89 4.68-4.388 7.919c.54 3.598 3.73 6.486 7.716 6.404a7.664 7.664 0 0 0 6.404-3.563z"/></svg>
            <span class="ml-3">Free Demo Registrations</span>
          </a>
        </router-link>
        </li>
      </ul>
      <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
        <p>Courses</p>
        <li>
          <router-link to="/admin/pregnancy-course-registrations">
          <a 
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11.374 23.977c-4.183-.21-8.006-2.626-9.959-6.347c-2.097-3.858-1.871-8.864.732-12.454C4.748 1.338 9.497-.698 14.281.23c4.583.857 8.351 4.494 9.358 8.911c1.122 4.344-.423 9.173-3.925 12.04c-2.289 1.953-5.295 2.956-8.34 2.797zm7.705-8.05a588.737 588.737 0 0 0-3.171-1.887c-.903 1.483-2.885 2.248-4.57 1.665c-2.024-.639-3.394-2.987-2.488-5.134c.801-2.009 2.79-2.707 4.357-2.464a4.19 4.19 0 0 1 2.623 1.669c1.077-.631 2.128-1.218 3.173-1.855c-2.03-3.118-6.151-4.294-9.656-2.754c-3.13 1.423-4.89 4.68-4.388 7.919c.54 3.598 3.73 6.486 7.716 6.404a7.664 7.664 0 0 0 6.404-3.563z"/></svg>
            <span class="ml-3">Pregnancy Yoga Course Registrations</span>
          </a>
        </router-link>
        </li>
        <li>
          <router-link to="/admin/yoga-instructor-course-registrations">

          <a 
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11.374 23.977c-4.183-.21-8.006-2.626-9.959-6.347c-2.097-3.858-1.871-8.864.732-12.454C4.748 1.338 9.497-.698 14.281.23c4.583.857 8.351 4.494 9.358 8.911c1.122 4.344-.423 9.173-3.925 12.04c-2.289 1.953-5.295 2.956-8.34 2.797zm7.705-8.05a588.737 588.737 0 0 0-3.171-1.887c-.903 1.483-2.885 2.248-4.57 1.665c-2.024-.639-3.394-2.987-2.488-5.134c.801-2.009 2.79-2.707 4.357-2.464a4.19 4.19 0 0 1 2.623 1.669c1.077-.631 2.128-1.218 3.173-1.855c-2.03-3.118-6.151-4.294-9.656-2.754c-3.13 1.423-4.89 4.68-4.388 7.919c.54 3.598 3.73 6.486 7.716 6.404a7.664 7.664 0 0 0 6.404-3.563z"/></svg>
            <span class="ml-3">Yoga Instructor Course Registrations</span>
          </a>
        </router-link>
        </li>
        <li>
        <router-link to="/admin/pranayama-certification-course">

        <a 
          class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11.374 23.977c-4.183-.21-8.006-2.626-9.959-6.347c-2.097-3.858-1.871-8.864.732-12.454C4.748 1.338 9.497-.698 14.281.23c4.583.857 8.351 4.494 9.358 8.911c1.122 4.344-.423 9.173-3.925 12.04c-2.289 1.953-5.295 2.956-8.34 2.797zm7.705-8.05a588.737 588.737 0 0 0-3.171-1.887c-.903 1.483-2.885 2.248-4.57 1.665c-2.024-.639-3.394-2.987-2.488-5.134c.801-2.009 2.79-2.707 4.357-2.464a4.19 4.19 0 0 1 2.623 1.669c1.077-.631 2.128-1.218 3.173-1.855c-2.03-3.118-6.151-4.294-9.656-2.754c-3.13 1.423-4.89 4.68-4.388 7.919c.54 3.598 3.73 6.486 7.716 6.404a7.664 7.664 0 0 0 6.404-3.563z"/></svg>
          <span class="ml-3">Pranayama Certification Course Registrations</span>
        </a>
      </router-link>
      </li>
        <li>
        <router-link to="/admin/meditation-certification-course">

        <a 
          class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24"><path fill="currentColor" d="M11.374 23.977c-4.183-.21-8.006-2.626-9.959-6.347c-2.097-3.858-1.871-8.864.732-12.454C4.748 1.338 9.497-.698 14.281.23c4.583.857 8.351 4.494 9.358 8.911c1.122 4.344-.423 9.173-3.925 12.04c-2.289 1.953-5.295 2.956-8.34 2.797zm7.705-8.05a588.737 588.737 0 0 0-3.171-1.887c-.903 1.483-2.885 2.248-4.57 1.665c-2.024-.639-3.394-2.987-2.488-5.134c.801-2.009 2.79-2.707 4.357-2.464a4.19 4.19 0 0 1 2.623 1.669c1.077-.631 2.128-1.218 3.173-1.855c-2.03-3.118-6.151-4.294-9.656-2.754c-3.13 1.423-4.89 4.68-4.388 7.919c.54 3.598 3.73 6.486 7.716 6.404a7.664 7.664 0 0 0 6.404-3.563z"/></svg>
          <span class="ml-3">Meditation Certification Course Registrations</span>
        </a>
      </router-link>
      </li>
      </ul>
      
      <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
        <li>
          <router-link to="/admin/blogs">
          <a 
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 512 512"><path fill="currentColor" d="M459.94 53.25a16.06 16.06 0 0 0-23.22-.56L424.35 65a8 8 0 0 0 0 11.31l11.34 11.32a8 8 0 0 0 11.34 0l12.06-12c6.1-6.09 6.67-16.01.85-22.38ZM399.34 90L218.82 270.2a9 9 0 0 0-2.31 3.93L208.16 299a3.91 3.91 0 0 0 4.86 4.86l24.85-8.35a9 9 0 0 0 3.93-2.31L422 112.66a9 9 0 0 0 0-12.66l-9.95-10a9 9 0 0 0-12.71 0Z"/><path fill="currentColor" d="M386.34 193.66L264.45 315.79A41.08 41.08 0 0 1 247.58 326l-25.9 8.67a35.92 35.92 0 0 1-44.33-44.33l8.67-25.9a41.08 41.08 0 0 1 10.19-16.87l122.13-121.91a8 8 0 0 0-5.65-13.66H104a56 56 0 0 0-56 56v240a56 56 0 0 0 56 56h240a56 56 0 0 0 56-56V199.31a8 8 0 0 0-13.66-5.65Z"/></svg>
            <span class="ml-3">Create Blog</span>
          </a>
        </router-link>
        </li>
        <li>
          <router-link to="/admin/recent-blogs">

          <a 
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M4 24h10v2H4zm0-6h10v2H4zm22-4H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2zM6 6v6h20V6zm20 22h-6a2 2 0 0 1-2-2v-6a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2zm-6-8v6h6v-6z"/></svg>
            <span class="ml-3">Recent Blogs</span>
          </a>
        </router-link>
        </li>
      </ul>
      <ul class="pt-5 mt-5 space-y-2 border-t border-gray-200 dark:border-gray-700">
        <li>
          <router-link to="/">
          <a 
            class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white group">

            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M4 21V9l8-6l8 6v12h-6v-7h-4v7H4Z"/></svg>

            <span class="ml-3">Back To Home Page</span>
          </a>
        </router-link>
        </li>
        <li class="items-center">
          <button @click.prevent="logout">
            <a
              class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-main-red hover:text-white dark:hover:bg-gray-700 group">
              <Icon icon="bx:log-out"
                class="w-6 h-6 text-gray-400 transition duration-75 dark:text-gray-400 group-hover:text-gray-900  dark:group-hover:text-white" />
              <span class="ml-3">Logout</span>
            </a>
          </button>
        </li>


      </ul>
    </div>

  </aside>
</template>


<script>
import { Icon } from '@iconify/vue2';
export default {
  name: 'AdminSidebar',
  components: {
    Icon,
  },
  methods: {
    logout(e) {
      e.preventDefault();
      localStorage.removeItem('token');
      this.$router.push('/admin/login');
    }

  }
};
</script>
