<template>
    <div>
        <section class="mt-20 bg-white dark:bg-gray-900">
            <div class="items-center justify-center text-center">
                <h2 class="text-6xl font-semibold text-main-text mb-5">Contact Us</h2>
                <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />
            </div>
            <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-xl flex flex-col lg:flex-row">
                <div class="lg:w-1/2 flex flex-col justify-center items-center lg:items-start mb-16 lg:mb-0">
                    <p
                        class="mb-8 lg:mb-16 font-light text-center lg:text-left text-gray-500 dark:text-gray-400 sm:text-xl">
                        If you have any questions or comments about our yoga classes or workshops, please don't hesitate to
                        get in touch using the form on this page.
                    </p>
                    <!-- Add here contact number -->
                    <p
                        class=" lg:mb-16 font-light text-center lg:text-left  text-gray-500 dark:text-gray-400 sm:text-xl"
                    >
                        <a href="tel:+91 8308315494"
                            class="inline-flex items-center justify-center h-12 px-6 mr-6 font-medium tracking-wide transition duration-200 focus:shadow-outline focus:outline-none">
                            <span class="mr-2">Call Us</span>
                            <span class="text-xl hover:text-main-orange">+91 8308315494</span>
                        </a>
                    </p>
                    <div class="mb-8">
                        <div class="h-64 w-full">
                            <iframe class="rounded-2xl" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.6067201172873!2d73.7990679!3d18.5466661!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf1f6a483e9b%3A0xc5e19ffd77536add!2sDr.Manisha%E2%80%99s%20Yoga%20Institute!5e0!3m2!1sen!2sin!4v1678886564419!5m2!1sen!2sin" width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <div class="lg:w-1/2 mt-36 sm:mt-16">
                    <form @submit.prevent="handleSubmit" method="POST" class="space-y-8">
                        <div>
                            <div class="relative">
                                <input v-model="name" type="text" name="name" id="name"
                                    class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-main-text appearance-none dark:text-white dark:border-main-text dark:focus:border-main-text focus:outline-none focus:ring-0 focus:border-main-text peer"
                                    placeholder=" " required />
                                <label for="name"
                                    class="absolute text-sm text-main-text dark:text-main-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter
                                    Name</label>
                                <span v-if="errors.name" class="text-sm text-red-500">{{ errors.name }}</span>
                            </div>
                        </div>
                        <div>
                            <div class="relative">
                                <input v-model="email" type="email" name="email" id="email"
                                    class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-main-text appearance-none dark:text-white dark:border-main-text dark:focus:border-main-text focus:outline-none focus:ring-0 focus:border-main-text peer"
                                    placeholder=" " required />
                                <label for="email"
                                    class="absolute text-sm text-main-text dark:text-main-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter
                                    Email</label>
                                <span v-if="errors.email" class="text-sm text-red-500">{{ errors.email }}</span>
                            </div>
                        </div>
                        <div>
                            <div class="relative">
                                <input v-model="contact" type="tel" name="contact" id="contact"
                                    class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-main-text appearance-none dark:text-white dark:border-main-text dark:focus:border-main-text focus:outline-none focus:ring-0 focus:border-main-text peer"
                                    placeholder=" " required />
                                <label for="contact"
                                    class="absolute text-sm text-main-text dark:text-main-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter
                                    Contact Number</label>
                                <span v-if="errors.contact" class="text-sm text-red-500">{{ errors.contact }}</span>
                            </div>
                        </div>
                        <div class="sm:col-span-2">
                            <div class="relative">
                                <textarea v-model="message" id="message" name="message"
                                    aria-describedby="outlined_success_help" rows="6"
                                    class="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-main-text appearance-none dark:text-white dark:border-main-text dark:focus:border-main-text focus:outline-none focus:ring-0 focus:border-main-text peer"
                                    placeholder=" " required>
                            </textarea>
                                <label for="message"
                                    class="absolute text-sm text-main-text dark:text-main-text duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1">Enter
                                    Message</label>
                                <span v-if="errors.message" class="text-sm text-red-500">{{ errors.message }}</span>
                            </div>
                        </div>

                        <div class="flex ">
                            <button type="submit" v-on:click="handleSubmit"
                                class="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-main-orange sm:w-fit hover:bg-main-orange hover:opacity-75 focus:ring-4 focus:outline-none">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>


                    <!-- <div v-show="messageSent" class="lg:w-1/2 mt-36 sm:mt-16">
                        <div id="toast-simple" class="flex items-center w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
                            <svg aria-hidden="true" class="w-5 h-5 text-main-text dark:text-main-text" focusable="false" data-prefix="fas" data-icon="paper-plane" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"></path></svg>
                            <div class="pl-6 text-sm font-normal">Your Form is Successfully Submitted.</div>
                        </div>
                    </div> -->




                </div>
        </section>

    </div>
</template>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    name: 'ContactForm',
    data() {
        return {
            name: '',
            email: '',
            message: '',
            contact: '',
            errors: {}
        };
    },
    methods: {
        async submitForm() {
            try {
                await axios.post('https://formspree.io/f/xgejwwpy', {
                    name: this.name,
                    email: this.email,
                    contact: this.contact,
                    message: this.message
                });
                this.formSubmitted = true;
                await Swal.fire({
                    title: 'Success!',
                    text: `Hey ${this.name}, Our team will reach out to you very soon`,
                    icon: 'success',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.name = '';
                        this.email = '';
                        this.message = '';
                        this.contact = '';
                    }
                })
            } catch (error) {
                console.log(error);
            }
            
        },
        handleSubmit() {
            this.errors = {};
            if (this.name === '') {
                this.errors.name = 'Name is required';
            }
            if (this.email === '') {
                this.errors.email = 'Email is required';
            }
            if (this.message === '') {
                this.errors.message = 'Message is required';
            }
            if (Object.keys(this.errors).length === 0) {
                this.submitForm();
                this.messageSent = true;
            }
        }
    }
}
</script>