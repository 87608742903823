import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import router from "./router";
import Vuex from "vuex";
import store from "./components/store";

import "primeicons/primeicons.css";
import AOS from "aos";
import "aos/dist/aos.css";

import "@/assets/css/main.css";

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueMeta,{
  refreshOnceOnNavigation: true,
  refreshOnWindowFocus: true,

  metaTemplate: (data) => {
    const meta = data.metaTags.filter(tag => tag.tag !== 'meta' || tag.vmID === undefined || tag.vmID === null)
    const metaVmid = data.metaTags.filter(tag => tag.tag === 'meta' && tag.vmID !== undefined && tag.vmID !== null)
    const isIncluded = tag => tag.vmID !== 'metaInfo' || tag.tag !== 'meta';
    return { metaVmid: metaVmid.concat(meta).map(tag => tag.string).join('\n'), isIncluded: data.metaTags.filter(isIncluded)}
 
  }
});
Vue.use(Vuex);

const vueRouter = new VueRouter({
  routes: router,
  mode: "history",
  linkExactActiveClass: "active",
});

// global routing guards
vueRouter.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

// authenticate admin login route using auth token
vueRouter.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("token") == null) {
      next({
        path: "/admin/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      if (to.matched.some((record) => record.meta.is_admin)) {
        if (user.is_admin == 1) {
          next();
        } else {
          next({ path: "/admin/dashboard" });
        }
      } else {
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (localStorage.getItem("token") == null) {
      next();
    } else {
      next({ path: "/admin/dashboard" });
    }
  } else {
    next();
  }
});

new Vue({
  router: vueRouter,
  store,
  render: (h) => h(App),
  mounted() {
    AOS.init();
    const urlParams = new URLSearchParams(window.location.search);
    if (!urlParams.has("ref")) {
      const referrer = document.referrer || "direct";
      const referrerUrl = new URL(referrer);
      const referrerHost = referrerUrl.hostname.replace(/^www\./i, "");
      if (referrerHost !== window.location.hostname.replace(/^www\./i, "")) {
        const referrerDomain = referrerHost.split(".").slice(-2).join(".");
        urlParams.append("ref", `${referrerDomain}`);
        const newUrl = `${window.location.origin}${
          window.location.pathname
        }?${urlParams.toString()}`;
        window.history.replaceState({}, "", newUrl);
      }
    }
  },
}).$mount("#app");
