<template>
    <div class="flex mt-32 flex-wrap">
        <div class="w-full  xl:w-8/12 mb-12 xl:mb-0 px-4">
            hi there
        </div>
        <div class="w-full xl:w-4/12 px-4">
            How are you
        </div>

    </div>
</template>
<script>
export default {
    name: "dashboard-page",
    components: {
    },
};
</script>
  