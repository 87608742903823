<template>
    <div>
        
          <div class="bg-gray-100 py-28">
            <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
              <h2 class="text-6xl font-medium text-center mb-8">Our Courses</h2>
              <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />
              
              <div class="grid grid-cols-1 md:grid-cols-2 mt-24 gap-24">
                <div class="bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md">
                  <div class="bg-main-orange px-6 py-3">
                    <h3 class="text-lg font-bold text-white mb-2">Government Affiliated Courses</h3>
                  </div>
                  <div class="px-6 py-4">
                    <h4 class="text-lg font-bold mb-2">Yoga Instructor Course</h4>
                    <p class="text-gray-700 text-base mb-2">Government-Affiliated Yoga Instructor Training for Holistic Wellness and Accreditation.</p>
                    <router-link to="/courses/gov/yoga-instructor-certification-course" class="text-main-orange font-bold text-base">Learn more →</router-link>
                  </div>
                </div>
                
                <div class="bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md">
                  <div class="bg-main-orange px-6 py-3">
                    <h3 class="text-lg font-bold text-white mb-2">Government Affiliated Courses</h3>
                  </div>
                  <div class="px-6 py-4">
                    <h4 class="text-lg font-bold mb-2">Pregnancy Yoga Certification Course</h4>
                    <p class="text-gray-700 text-base mb-2">Certify as a Pregnancy Yoga Instructor through Government Affiliated Course.</p>
                    <router-link to="/courses/gov/pregnancy-yoga-certification-course" class="text-main-orange font-bold text-base">Learn more →</router-link>
                  </div>
                </div>
                <div class="bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md">
                  <div class="bg-main-orange bg-opacity-95 px-6 py-3">
                    <h3 class="text-lg font-bold text-white mb-2">Personal Certification Courses</h3>
                  </div>
                  <div class="px-6 py-4">
                    <h4 class="text-lg font-bold mb-2">Meditation Certification Course</h4>
                    <p class="text-gray-700 text-base mb-2">Train to teach meditation with our Personal Certification Course.</p>
                    <router-link to="/courses/personal/meditation-certification-course" class="text-main-orange font-bold text-base">Learn more →</router-link>
                  </div>
                </div>
                <div class="bg-white border border-gray-200 rounded-lg overflow-hidden shadow-md">
                  <div class="bg-main-orange bg-opacity-95 px-6 py-3">
                    <h3 class="text-lg font-bold text-white mb-2">Personal Certification Courses</h3>
                  </div>
                  <div class="px-6 py-4">
                    <h4 class="text-lg font-bold mb-2">Pranayama Certification Course</h4>
                    <p class="text-gray-700 text-base mb-2">Learn the art of breathwork with Pranayama certification course.</p>
                    <router-link to="/courses/personal/pranayama-certification-course" class="text-main-orange font-bold text-base">Learn more →</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          
    </div>
</template>

<script>
    export default {
        name: "MainCourses",
    }
</script>