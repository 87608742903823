<template>
    <div>
        <div class="flex mt-32 flex-wrap">
            <h1 class="text-6xl font-medium text-gray-700 dark:text-gray-200 mb-4 ml-4">All Orders</h1>

            <!-- Show data in tablular form -->
            <div class="relative w-full overflow-x-auto shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <!-- <th scope="col" class="px-6 py-3">User ID</th> -->
                            <th scope="col" class="px-6 py-3">Order Date</th>
                            <th scope="col" class="px-6 py-3">User Name</th>
                            <th scope="col" class="px-6 py-3">User Email</th>
                            <th scope="col" class="px-6 py-3">Product Name</th>
                            <th scope="col" class="px-6 py-3">Product Quantity</th>
                            <th scope="col" class="px-6 py-3">Total Price</th>
                            <th scope="col" class="px-6 py-3 hidden">Order ID</th>
                            <!-- <th scope="col" class="px-6 py-3">Actions</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="order in orders" :key="order._id"
                            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td class="px-6 py-4">
                                {{ new Date(order.date).toLocaleDateString('en-US', {
                                    day: 'numeric', month: 'numeric',
                                    year: 'numeric'
                                }) }}
                            </td>
                            <td class="px-6 py-4">
                                {{ order.username }}
                            </td>
                            <td class="px-6 py-4">
                                {{ order.email }}
                            </td>
                            <td class="px-6 py-4">
                                <ul>
                                    <li v-for="product in order.items" :key="product.product.id">
                                        {{ product.product.title }}
                                    </li>
                                </ul>
                            </td>
                            <td class="px-6 py-4">
                                <ul>
                                    <li v-for="product in order.items" :key="product.product.id">
                                        {{ product.quantity }}
                                    </li>
                                </ul>
                            </td>
                            <td class="px-6 py-4 font-bold">
                                {{ order.totalPrice }}
                            </td>

                            <th scope="row"
                                class="px-6 py-4 hidden font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                {{ order.id }}
                            </th>
                            <!-- <td class="px-6 py-4 text-right">
                                <button class="inline-flex justify-center items-center w-6 h-6 mr-3 text-red-500 transition-colors duration-150 bg-white rounded-full focus:outline-none hover:bg-red-100" @click="deleteOrder(order._id)">
                                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M3 5a2 2 0 012-2h10a2 2 0 012 2v1H3V5zm12 2v10a2 2 0 01-2 2H7a2 2 0 01-2-2V7h10zm-9 2a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1H7z" clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                                <button class="inline-flex justify-center items-center w-6 h-6 text-blue-500 transition-colors duration-150 bg-white rounded-full focus:outline-none hover:bg-blue-100">
                                    <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                                        <path fill-rule="evenodd" d="M10 12a2 2 0 100-4 2 2 0 000 4zm0 2a4 4 0 100-8 4 4 0 000 8zm0 2a6 6 0 100-12 6 6 0 000 12zm0 2a8 8 0 100-16 8 8 0 000 16zm0 2a10 10 0 100-20 10 10 0 000 20zm0 2a12 12 0 100-24 12 12 0 000 24zm0 2a14 14 0 100-28 14 14 0 000 28zm0 2a16 16 0 100-32 16 16 0 000 32zm0 2a18 18 0 100-36 18 18 0 000 36zm0 2a20 20 0 100-40 20 20 0 000 40z" clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                            </td> -->
                        </tr>



                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { API_URL } from '@/config';
export default {
    name: 'AllOrders',
    data() {
        return {
            orders: [],
        }
    },
    async created() {
        try {
            const response = await axios.get(`${API_URL}/api/orders/all-orders`);
            this.orders = response.data;
            // console.log(this.orders);
        }
        catch (err) {
            console.log(err);
        }
    },
}
</script>