<template>
    <div>
        <Layout>
            <template v-slot:default>
                <div class="mt-32">
                    <div class="items-center justify-center text-center">
                        <h2 class="text-6xl font-semibold text-main-text mb-5">Ayurvedic Products</h2>
                        <hr class="w-20 h-1 mx-auto my-4 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700" />
                    </div>
                </div>
                <div class="flex flex-col md:flex-row py-14 px-5">
                    <div class="flex-1 md:pr-10">
                        <div class="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-2 items-stretch gap-10">
                            <ProductCard v-for="product in products" :key="product.id" :product="product" />
                        </div>
                    </div>
                    <div class="w-full md:w-1/3 mt-10 max-w-screen-md mx-auto md:mt-0">
                        <div class="!px-[42px] mt-5  top-0 right-0">
                            <button v-if="isSignedIn" @click="logout"
                                class="bg-main-red hover:bg-main-red text-white font-bold py-2 px-4 rounded mt-2">
                                Logout
                            </button>
                            <div v-if="showSignInModal"
                                class="w-full max-w-sm p-4 bg-white rounded-lg  sm:p-2 md:p-4 dark:bg-gray-800 dark:border-gray-700">
                                <div class="relative w-full max-w-md mx-auto my-6">
                                    <div class="bg-white rounded-lg shadow-lg">
                                        <div class="p-4">
                                            <h2 class="text-lg font-medium mb-4">{{ formMode ? 'Log in' : 'Sign up' }}</h2>

                                            <form method="POST" @submit.prevent="formMode ? login() : signup()"
                                                class="flex flex-col ">
                                                <label for="name"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Name:</label>
                                                <input v-model="username" type="text" placeholder="Enter Your Name"
                                                    name="username" id="name" required
                                                    class="border border-gray-300 p-2 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-main-orange focus:border-transparent">

                                                <label v-if="!formMode" for="phone"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Phone:</label>
                                                <input v-if="!formMode" v-model="phonenumber"
                                                    placeholder="Enter Your Phone Number" type="tel" name="phone" id="phone"
                                                    required pattern="[0-9]{10}"
                                                    class="border border-gray-300 p-2 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-main-orange focus:border-transparent">
                                                <span v-if="!formMode" class="text-red-500 mb-4 text-sm">* Please enter a
                                                    10-digit phone number</span>


                                                <label v-if="!formMode" for="address"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Address:</label>
                                                <textarea v-if="!formMode" v-model="address"
                                                    placeholder="Enter Your Residential Address" name="address" id="address"
                                                    required
                                                    class="border border-gray-300 p-2 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-main-orange focus:border-transparent"></textarea>
                                                <span v-if="!formMode" class="text-red-500 mb-4 text-sm">*
                                                    Please enter your full address including your city, state and pincode
                                                </span>



                                                <label for="email"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email:</label>
                                                <input v-model="email" placeholder="name@gmail.com" type="email"
                                                    name="email" id="email" required
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                                    class="border border-gray-300 p-2 rounded-md mb-2 focus:outline-none focus:ring-2 focus:ring-main-orange focus:border-transparent">
                                                <span class="text-red-500 mb-4 text-sm">* Make sure you enter a correct
                                                    email ID for further communication</span>

                                                <label for="password"
                                                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password:</label>
                                                <input v-model="password" type="password" placeholder="••••••••"
                                                    name="password" id="password" required
                                                    class="border border-gray-300 p-2 rounded-md mb-4 focus:outline-none focus:ring-2 focus:ring-main-orange focus:border-transparent">

                                                <button type="submit"
                                                    class="bg-main-orange hover:bg-main-orange hover:bg-opacity-70 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                                                    {{ formMode ? 'Log in' : 'Sign up' }}
                                                </button>

                                                <p class="mt-4 text-sm text-gray-600">
                                                    {{ formMode ? "Don't have an account?" : "Already have an account?" }}
                                                    <a @click.prevent="formMode = !formMode"
                                                        class="text-main-orange font-medium cursor-pointer">
                                                        {{ formMode ? 'Sign up' : 'Log in' }}
                                                    </a>
                                                </p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Welcome message -->


                            <h2 v-if="isSignedIn" class="text-[22px]  font-semibold mb-10 mt-4">Welcome, {{ user_name }}!
                            </h2>

                            <h2 class="text-[22px]  font-semibold mb-10">Your Shopping Cart</h2>
                            <table class="
                                                                                        w-full
                                                                                        divide-y divide-gray-200
                                                                                        text-gray-600
                                                                                        font-medium
                                                                                        p-16
                                                                                        table-auto bg-white border border-gray-200 rounded-lg shadow  text-sm    ml-0
                                                                                ">
                                <thead class="
                                                                                        border-b border-gray-200
                                                                                        text-gray-400
                                                                                        text-left
                                                                                        text-sm
                                                                                        font-semibold
                                                                                        uppercase
                                                                                        tracking-wider
                                                                                        bg-gray-50
                                                                                        p-5
                                                                                    ">
                                    <tr>
                                        <th class="hidden md:table-cell p-3">Product</th>
                                        <th class="hidden md:table-cell p-3">Name</th>
                                        <th class="hidden md:table-cell p-3">QTY</th>
                                        <th class="hidden md:table-cell p-3">Price</th>
                                        <th class="hidden md:table-cell p-3">Sub total</th>
                                    </tr>
                                </thead>
                                <tbody class="divide-y divide-gray-200
                                                                                        text-gray-600
                                                                                        text-sm
                                                                                        font-medium
                                                                                        p-5
                                                                                        ">
                                    <tr v-for="item in carts" :key="item.id">
                                        <td class="hidden md:table-cell p-3"><img class="rounded-lg w-full"
                                                :src="item.product.thumbnail" alt="product image" /></td>
                                        <td class="hidden md:table-cell p-3">{{ item.product.title }}</td>
                                        <td class="hidden md:table-cell p-3">{{ item.quantity }}</td>
                                        <td class="hidden md:table-cell p-3">{{ item.product.price }}</td>
                                        <td class="hidden md:table-cell p-3">{{ item.quantity * item.product.price }}</td>
                                        <td class="table-cell md:hidden p-3">{{ item.product.title }} - {{ item.quantity }}
                                            x {{ item.product.price }}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <hr class="my-12 ">

                            <div class="flex justify-between mb-16">
                                <p class="text-main-text text-lg">Total</p>
                                <p class="text-xl font-semibold">₹{{ totalPrice }}</p>
                            </div>

                            <button v-if="carts.length > 0 && isSignedIn"
                                class="w-full bg-main-orange text-white font-semibold py-3 rounded-lg shadow-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-300"
                                @click="$router.push({
                                        path: '/checkout', query: {
                                            items: JSON.stringify(carts), total: totalPrice,
                                            user_name: user_name,
                                            user_email: user_email,
                                        }
                                    })">
                                <i class="pi pi-shopping-cart"></i>
                                Checkout
                            </button>
                            <div v-else-if="isSignedIn">
                                <div
                                    class="bg-red-500 text-center text-white font-semibold py-3 rounded-lg shadow-md cursor-not-allowed">
                                    Please fill your cart.
                                </div>
                            </div>
                            <div v-else>
                                <div
                                    class="bg-red-500 text-center text-white font-semibold py-3 rounded-lg shadow-md cursor-not-allowed">
                                    Please sign in to checkout.
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </template>
        </Layout>
    </div>
</template>
  
<script setup>
import { API_URL } from '@/config'
import { computed, watchEffect, ref } from 'vue'
import Swal from 'sweetalert2';
import store from '../store/index'
import ProductCard from '../components/Products/ProductCard.vue'
import Layout from '../Layout/Layout.vue';
import emailjs from 'emailjs-com';

const products = computed(() => store.state.products.data)
store.dispatch('getProducts')

// const showSignInModal = ref(true);
const isSignedIn = ref(localStorage.getItem('auth-token') !== null);
const username = ref('');
const email = ref('');
const password = ref('');
const phonenumber = ref('');
const address = ref('');
const formMode = ref(false);

const authToken = computed(() => {
    return localStorage.getItem('authToken') && localStorage.getItem('username')
})

const showSignInModal = computed(() => {
    return !isSignedIn.value && !authToken.value

})
async function signup() {
    try {
        // Send user details to the server for sign-up
        const response = await fetch(`${API_URL}/api/auth/user/signup`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username: username.value,
                email: email.value,
                password: password.value,
                phonenumber: phonenumber.value,
                address: address.value,

            })
        });
        const data = await response.json();
        // console.log(data);
        if (data.error) {
            alert(data.error);
            Swal.fire({
                title: 'Oops',
                text: data.error,
                icon: 'error',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            });
        } else {
            const emailParams = {
                username: username.value,
                to_email: email.value,
                from_email: 'drmanishas777@gmail.com',
                // from_name: `Welcome ${username.value} to Dr. Manisha's Yoga Institute`,           
            }
            const emailResponse = await emailjs.send(
                'service_n76hg9n',
                'template_esekqnf',
                emailParams,
                '0Zj2J8AHRXcrBwgLU'
            )
            console.log(emailResponse);
            // If sign-up is successful, set the isSignedIn flag to true
            localStorage.setItem('auth-token', data.authToken);
            localStorage.setItem('username', username.value);
            localStorage.setItem('email', email.value);
            // alert('User signed up successfully');
            await Swal.fire({
                title: 'Congratulations!',
                text: `${username.value}, you have signed up successfully`,
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
            isSignedIn.value = true;
            showSignInModal.value = false;

        }
    } catch (err) {
        alert(err.message);
        console.error(err.message);
    }

}

async function login() {
    try {
        const res = await fetch(`${API_URL}/api/auth/user/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: username.value,
                email: email.value,
                password: password.value,
            }),
        });

        const data = await res.json();

        if (data.authToken) {
            // User has been authenticated
            localStorage.setItem('auth-token', data.authToken);
            localStorage.setItem('username', username.value);
            localStorage.setItem('email', email.value);

            await Swal.fire({
                title: 'Congratulations!',
                text: `${username.value}, you have logged in successfully`,
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Ok'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            });

            isSignedIn.value = true;
            showSignInModal.value = false;
        } else {
            const user = data.user;
            if (user) {
                // Check if entered password matches the hashed password in database
                const checkPasswordRes = await fetch(`${API_URL}/api/auth/user/checkpassword`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        password: password.value,
                        hashedPassword: user.password,
                    }),
                });

                const checkPasswordData = await checkPasswordRes.json();

                if (checkPasswordData.result) {
                    // User has been authenticated
                    localStorage.setItem('auth-token', data.authToken);
                    localStorage.setItem('username', username.value);
                    localStorage.setItem('email', email.value);

                    await Swal.fire({
                        title: 'Congratulations!',
                        text: `${username.value}, you have logged in successfully`,
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload();
                        }
                    });

                    isSignedIn.value = true;
                    showSignInModal.value = false;
                } else {
                    // Incorrect password
                    await Swal.fire({
                        title: 'Oops...',
                        text: 'Invalid username or email or password. Please try again.',
                        icon: 'error',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    });
                }
            } else {
                // Incorrect username or email
                await Swal.fire({
                    title: 'Oops...',
                    text: 'Invalid username or email or password. Please try again.',
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Ok'
                });
            }
        }

    } catch (err) {
        console.error(err.message);
    }
}




//logout
function logout() {
    Swal.fire({
        title: 'Are you sure?',
        text: 'You will be logged out',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, logout'
    }).then((result) => {
        if (result.isConfirmed) {
            localStorage.removeItem('auth-token');
            localStorage.removeItem('username');
            isSignedIn.value = false;
            showSignInModal.value = true;
            Swal.fire(
                'Logged Out!',
                'You have been logged out successfully. Thank you for being with us',
                'success'
            );
        }
    });
}

// Get the username  & email from local storage
const storedUsername = localStorage.getItem('username');
const storedEmail = localStorage.getItem('email');

const userName = ref(storedUsername);
const user_name = ref(userName.value);

const userEmail = ref(storedEmail);
const user_email = ref(userEmail.value);


watchEffect(() => {
    user_name.value = userName.value;
    user_email.value = userEmail.value;
})
const carts = computed(() => store.state.cart)

const totalPrice = computed(() => {
    let total = 0
    carts.value.forEach(item => {
        total += item.quantity * item.product.price
    })
    return total
})

store.commit('updateCartFromLocalStorage')

</script>

<script>
export default {
   
    metaInfo() {
       
        return {
            title: "Our Products",
            titleTemplate: "%s | Dr. Manisha's Yoga Institute",
            meta:[
                {
                    name: "description",
                    content: "Dr. Manisha's Yoga Institute is a leading yoga institute in Mumbai. We offer a range of ayurvedic products that are made from natural ingredients and are free from harmful chemicals."
                },
                {
                    property: "og:title",
                    content: "Our Products | Dr. Manisha's Yoga Institute"
                },
                {
                    property: "og:type",
                    content: "website"
                },
                {
                    property: "og:url",
                    content: "https://drmanishayoga.com/products"
                },
                {
                    property: "og:description",
                    content: "Dr. Manisha's Yoga Institute is a leading yoga institute in Mumbai. We offer a range of ayurvedic products that are made from natural ingredients and are free from harmful chemicals."
                },
                {
                    property: "og:image",
                    content: "@/assets/images/main.png"
                },
                {
                    property: "og:image:secure_url",
                    content: "@/assets/images/main.png"
                },
                {
                    property: "og:image:alt",
                    content: "Our Products | Dr. Manisha's Yoga Institute"
                },
                {
                    property: "og:image:type",
                    content: "image/jpg"
                },
                {
                    property: "og:image:width",
                    content: "1200"
                },
                {
                    property: "og:image:height",
                    content: "630"
                },
                {
                    property: "twitter:card",
                    content: "summary_large_image"
                },
                {
                    property: "twitter:url",
                    // content: "https://drmanishayoga.com/products"
                },
                {
                    property: "twitter:title",
                    content: "Our Products | Dr. Manisha's Yoga Institute"
                },
                {
                    property: "twitter:description",
                    content: "Dr. Manisha's Yoga Institute is a leading yoga institute in Mumbai. We offer a range of ayurvedic products that are made from natural ingredients and are free from harmful chemicals."
                },
                {
                    property: "twitter:image",
                    content: "@/assets/images/main.png",
                }
            ]
        };
    },
    
}
</script>