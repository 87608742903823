<template>
    <div>
        <Layout>
            <template v-slot:default>

                <DemoBooking class="mt-28" />

            </template>
        </Layout>
    </div>
</template>

<script>
import DemoBooking from '../components/DemoBooking/DemoBooking.vue';
import Layout from '../Layout/Layout.vue';

export default {
    name: 'DemoSession',
    components: {
        Layout,
        DemoBooking
    },
    metaInfo(){
        return{
            title: "Free Demo | Dr. Manisha's Yoga Institute",
        }        
    }
}
</script>