<template>
  <div class="">
    <div class="max-w-full mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div class="max-w-6xl  mx-auto">
        <div class="relative h-96 rounded-lg overflow-hidden">
          <!-- <img class="absolute h-96 w-full object-cover opacity-75" src="@/assets/images/traditionalyoga.jpg" alt="Service 1"> -->
          <video class="absolute h-96 w-full object-cover opacity-75" autoplay muted loop preload="none">
            <source src="https://res.cloudinary.com/doqozlqzp/video/upload/v1679151681/traditional_yoga_scljk6.mp4" type="video/mp4">
          </video>
          <div class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30"></div>
          <div class="relative h-full flex items-center justify-center p-8">
            <div class="text-center">
              <h1 class="text-4xl font-bold text-white uppercase mb-4">{{ services.title }}</h1>
              <p class="text-white text-lg mb-8">Traditional Yoga offers a holistic approach to physical and mental well-being, promoting a sense of oneness with ourselves and the world around us.</p>
            </div>
          </div>
        </div>
        
        <div class="relative mt-8 text-justify">
          <article class="format-sm sm:format-base  lg:format-lg format-blue">
          <h2 class="text-5xl font-medium">What is Traditional Yoga?</h2>
          <p class="mt-4 lead leading-10">{{ services.paragraph1 }}</p>
          <p class="mt-6  text-lg leading-8">{{ services.paragraph2 }}</p>
          <div class="mt-8 mb-8 rounded-lg overflow-hidden relative">
            <img :src="services.bannerImage" alt="Dr. Manisha's Yoga Institute" class="w-full h-60 rounded-lg object-cover">
            <h1 class="absolute bottom-0 left-0 text-white font-medium text-4xl sm:text-5xl md:text-6xl   px-4 py-4">Traditional Yoga</h1>
          </div>
          
          <p class="mt-6  text-lg leading-8">{{ services.paragraph3 }}</p>
          <p class="mt-6  text-lg leading-8">{{ services.paragraph4 }}</p>
          <p class="mt-6  text-lg leading-8">{{ services.paragraph5 }}</p>
          
          

          </article>
          <div class="mt-8">
            <h2 class="text-2xl font-bold">Related Services</h2>
            <div class="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              <div v-for="service in relatedServices" :key="service.title" class="bg-white rounded-lg shadow-md">
                <div class="overflow-hidden rounded-t-lg">
                  <img :src="service.image" alt="Dr. Manisha's Yoga Institute" class="w-full h-48 object-cover">
                </div>
                <div class="p-6">
                  <h3 class="text-lg font-bold mb-4">{{ service.title }}</h3>
                  <p class="text-gray-700">{{ service.description }}</p>
                  <router-link :to="service.to" class="text-main-orange font-bold text-base mt-8">Learn more →</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
// import { API_URL } from '@/config';
export default {
  name: 'TraditionalYoga',
  metaInfo() {
    return {
      title: "Traditional Yoga | Dr. Manisha's Yoga Institute",
      meta: [
        { vmid: 'charset', charset: 'utf-8' },
                { vmid: 'http-equiv', 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
                { vmid: 'viewport', name: 'viewport', content: 'width=device-width, initial-scale=1' },
                { vmid: 'robots', name: 'robots', content: 'index, follow' },
                { vmid: 'title', name: 'title', content: "Traditional Yoga | Dr. Manisha's Yoga Institute" },
                
        {
          vmid: 'description',
          name: 'description',
          content: "Traditional Yoga is an ancient practice that has gained popularity around the world for its physical, mental, and spiritual benefits. It is a holistic system that focuses on balancing the body, mind, and spirit through physical postures, breathing techniques, and meditation."
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: "Dr. Manisha's Yoga Institute, Asanas, Pranayama, Meditation, Sanskrit, Kundalini, Hatha yoga, Ashtanga yoga, Raja yoga, Bhakti yoga, Jnana yoga, Yoga philosophy, Yoga sutras, Chakras, Mantras, Guru, Yogi, Niyamas, Yamas, Sattva, Karma yoga, Dhyana, Yoga nidra, Om, Ayurveda, Vinyasa."
        },
      ]
    }
  },
  data() {
    return {
      services: {},
      relatedServices: [
        {
          title: "Meditation & Pranayam",
          image: "https://images.pexels.com/photos/3820380/pexels-photo-3820380.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Meditation and pranayama bring balance to the mind and body, promoting relaxation, focus, and well-being.",
          to: "/services/meditation-pranayam"
        },
        {
          title: "Healing Therapy",
          image: "https://images.pexels.com/photos/7113299/pexels-photo-7113299.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Healing therapy promotes holistic wellness by addressing the root cause of physical and emotional issues, fostering balance and growth.",
          to: "/services/healing-therapy"
        },
        {
          title: "Yoga for allround Fitness",
          image: "https://images.pexels.com/photos/4056723/pexels-photo-4056723.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
          description: "Yoga offers a complete approach to fitness, enhancing strength, flexibility, balance, and mindfulness for a healthier, more vibrant life.",
          to: "/services/yoga-for-allround-fitness"
        }
      ]
    }
  },
  async mounted(){
    const _id = '0';
    const response = await axios.get(`https://yogaservices-cc925-default-rtdb.asia-southeast1.firebasedatabase.app/services/${_id}.json`);
    this.services = response.data;
    // console.log(this.services);
  }

}
</script>
  