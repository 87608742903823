<template>
    <div>
        <Layout>
            <template v-slot:default>

                <EnrollNow class="mt-28" />

            </template>
        </Layout>
    </div>
</template>

<script>
import Layout from '../Layout/Layout.vue';
import EnrollNow from '../components/EnrollNow/EnrollNow.vue';

export default {
    name: 'EnrollNowForm',
    components: {
        Layout,
        EnrollNow
    },
    metaInfo(){
        return{
            title: "Enroll Now | Dr. Manisha's Yoga Institute",
        }        
    }
}
</script>