<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="ml-auto mr-auto max-w-7xl pl-4 pr-4 md:pl-8 md:pr-8 sm:pl-10 sm:pr-10">
      <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
        <div class="grid grid-cols-2 gap-4 mt-8" >
          <!-- data-aos="slide-right" data-aos-duration="1500" data-aos-delay="500" -->
          <img class="w-full rounded-lg" src="@/assets/images/why-exercise1.jpg" alt="Yoga Exercise">
          <img class="mt-4 w-full lg:mt-10 rounded-lg" src="@/assets/images/why-exercise2.jpg" alt="Yoga Exercise">
        </div>

        <div class="w-full">
          <h4 class="text-6xl font-medium text-main-orange mb-5">Why Choose Us</h4>
          <hr class="w-28 h-1 -mt-3 bg-main-orange border-0 rounded md:my-6 dark:bg-gray-700">

          <h2 class="font-semibold text-4xl leading-10   kk wm qb">
            We Make Our customers happy by giving Best services.
          </h2>
          <p class="w-11/12 mt-9 text-justify text-gray-800">
            Transform your mind, body, and spirit with our expert yoga instruction. Our personalized approach and state-of-the-art facilities provide the best yoga experience possible. Choose us to improve your well-being today.
          </p>

          <button @click="showModal = true" class="inline-flex items-center gap-7 mt-10">
            <span
              class="flex items-center justify-center w-11 h-11 rounded-full relative bg-main-orange bg-opacity-75 z-[1]">
              <span
                class="animate-ping absolute inline-flex h-full w-full rounded-full bg-main-orange opacity-75 z-[-1]"></span>
              <img src="@/assets/images/icon-play.png" alt="Play" />
            </span>
            <span class="text-gray-800">SEE HOW WE WORK</span>
          </button>

          <div v-if="showModal" class="fixed z-50  inset-0 overflow-y-auto">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div class="fixed inset-0 w-full transition-opacity" aria-hidden="true">
                <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

              <div class="inline-block w-full align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">

                <div class="mb-3 ml-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button @click="showModal = false" type="button"
                    class="inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-main-orange text-base font-medium text-white hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-main-orange sm:ml-3 sm:w-auto sm:text-sm">
                    X
                  </button>
                </div>
        
                <div class="bg-white rounded-lg w-full h-full pb-4">
                  <div class="flex items-start">
                    <div class="text-left w-full">
                      <div class="text-center mt-0 sm:text-left">
                        <div class="">
                          <div class="aspect-w-16 aspect-h-9">
                            <video class="rounded-lg object-cover w-full h-full" controls>
                              <source src="https://res.cloudinary.com/doqozlqzp/video/upload/v1679151690/Dr.Manisha_sgaimt.mp4" type="video/mp4">
                              Your browser does not support the video tag.
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
        
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "WhyChoose",
  data() {
    return {
      showModal: false,
    };
  },
}

</script>

